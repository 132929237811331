import * as React from 'react';
import {
    dataSetDashboardDispatchSelectedLoadDate,
    dataSetDashboardDispatchSelectedDispatchInstructionId,
    dataSetDashboardDispatchInstructionLines,
    dataSetStocks,
} from '../../store/data/Actions';
import { DispatchCall, RootAction, IRootState, IAuthState } from '../../@types/redux';
import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { generalShowErrorSnackbar, generalShowSuccessSnackbar, generalShowWarningSnackbar } from '../../store/general/Functions';
import { getState } from '../../store/Index';
import { mapDashboardDispatchLines, dataSetTrip, dataSetDispatchInstruction, dataSetAllDispatchDashboardViewRelatedData, dataSetDispatchView, dataSetDispatchSummaryRelatedData, dataSetStock } from '../../store/data/Functions';
import { formatDateTimeToDateOnly, booleanToYesNo, formatDateTime, addObjectAttribute, removeObjectAttribute, formatMomentToDatePicker, compareDate, compareNumber, getCSVStockLineSummary, addArrayElement, removeArrayElement } from '../../services/appFunctionsService';
import { Typography, Divider, Icon, IconButton, List, Button, TextField, CircularProgress } from '@mui/material';
import { DISPATCH_INSTRUCTION_STATUSSES, DATEPICKER_FORMAT_DEFAULT, VALID_DISPATCH_STATUS_DESTINATIONS, DISPATCH_INSTRUCTION_CAN_DOWNLOAD_PO_FILE_STATUSSES, DISPATCH_CSV_HEADINGS, DISPATCH_CSV_DATE_FORMAT, DATE_FORMAT_DEFAULT_NO_TIME, ADMIN_OVERRIDE_STATUSSES } from '../../appConstants';
import { Draggable, Droppable, DragDropContext, DropResult, DroppableStateSnapshot, DraggableProvided, DraggableStateSnapshot } from '@react-forked/dnd';
import posed from 'react-pose';
import CustomSelect from '../../components/input/CustomSelect';
import { Form } from 'informed';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import jQuery from 'jquery';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import moment from 'moment';
import ConfirmationPrompt from '../../components/dialog/ConfirmationPrompt';
import randomColor from 'randomcolor';
import lodash, { flatten, uniq } from 'lodash';
import CustomTable, { ICustomTableColumn } from '../../components/table/CustomTable';
import { IDashboardDispatchInstructionLine } from '../../@types/model/dispatch/dashboardDispatchInstructionLine';
import { IStock } from '../../@types/model/stock/stock';
import { IDispatchInstruction } from '../../@types/model/dispatch/dispatchInstruction';
import { ITrip, Trip } from '../../@types/model/dispatch/trip';
import { ICarrier } from '../../@types/model/masterData/carrier/carrier';
import { IDispatchInstructionLine } from '../../@types/model/dispatch/dispatchInstructionLine';
import { ISetDispatchStatus } from '../../@types/model/dispatch/setDispatchStatus';
import DispatchHttpService from '../../services/http/dispatch/dispatchHttpService';
import TripHttpService from '../../services/http/trip/tripHttpService';
import FTPHttpService from '../../services/http/integration/ftp/ftpHttpService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWeight, faPallet } from '@fortawesome/free-solid-svg-icons';
import PackmanDialog from '../../components/dialog/PackmanDialog';
import FloatingActionButton from '../../components/input/FloatingActionButton';
import { IStockLine } from '../../@types/model/stock/stockLine';
import { ISubstituteStock } from '../../@types/model/dispatch/substituteStock';
import { CustomChangeEvent, IOptionType } from '../../@types/helper';
import { createSelector } from 'reselect';
import { Formik, FormikActions } from 'formik';
import { ITripFormValues, TripFormValues } from '../../@types/model/dispatch/tripFormValues';
import TripForm from './trip/form/TripForm';
import { IGoogleCloudStorage } from '../../@types/model/googleCloudStorage/googleCloudStorage';
import GoogleCloudStorageHttpService from '../../services/googleCloudStorageService';
import PillButton from '../../components/input/PillButton';
import Screen from '../../components/Screen';
import FileSelector from '../../components/input/FileSelector';
import { ISite } from '../../@types/model/masterData/site/site';
import PopupOptionButton from '../../components/button/PopupOptionButton';
import { ITemperatureUnit } from '../../@types/model/dispatch/temperatureUnit';
import DispatchInstructionEdit from './DispatchInstructionEdit';
import { ListChildComponentProps, FixedSizeList as WindowList } from 'react-window';
import DispatchInstructionInfoPopup from './DispatchInstructionInfoPopup';
import { IContactInfo } from '../../@types/model/masterData/contactInfo/contactInfo';
import { VERSION } from '../../../version';
import { EmailFormValues, IEmailFormValues } from '../../@types/model/email/emailFormValues';
import EmailForm from '../../components/email/EmailForm';
import { IDispatchView } from '../../@types/model/dispatch/dispatchView';
import { ITruckType } from '../../@types/model/masterData/truckType/truckType';
import { RouteComponentProps } from 'react-router';
import { ICompliance } from '../../@types/model/compliance/compliance';
import { IComplianceLine } from '../../@types/model/compliance/complianceLine';
import { IPOFileUpload } from '../../@types/model/dispatch/poFileUpload';
import { IPOFileEmail } from '../../@types/model/dispatch/poFileEmail';
import EmailHttpService from '../../services/http/integration/email/emailHttpService';
import { Email } from '../../@types/model/email/email';
import TransactionFilter from '../../components/filters/BasicTransactionScreenFilter';
import { PackmanLink } from '../../components/link/packmanLink';
import CustomTooltip from '../../components/tooltip/tooltip';
import { syncMasterData } from '../../services/masterDataSyncService';
import { BooleanFlag } from '../../components/label/BooleanFlag';
import materialTheme, { getThemeMode } from '../../styles/materialTheme';
import DeleteConfirmationDialog from '../../components/dialog/DeleteConfirmationDialog';
import { getIconLocation } from '../../services/iconHelperService';
import { IFarm } from '../../@types/model/masterData/farm/farm';

const themeMode = getThemeMode(); // needed to apply hover effect to dispatch

interface IDispatchInstructionDashboardProps extends RouteComponentProps {
    dataSetDashboardDispatchSelectedLoadDate : DispatchCall<string>;
    dataSetDashboardDispatchSelectedDispatchInstructionId : DispatchCall<number | undefined>;
    dataSetDashboardDispatchInstructionLines : DispatchCall<Array<IDashboardDispatchInstructionLine>>;
    dataSetStocks : DispatchCall<Array<IStock>>;
    dashboardDispatchInstructionLines : Array<IDashboardDispatchInstructionLine>;
    selectedLoadDate : string;
    auth : IAuthState;
    selectedDispatchIndex ?: number;
    showOnlyMyDispatches : boolean;
    selectedOrganizationIds : Array<number>;
    selectedSiteIds : Array<number>;
    stocks : Array<IStock>;
    sites : Array<ISite>;
    farms : Array<IFarm>;
    contactInfos : Array<IContactInfo>;
    trips : Array<ITrip>;
    carriers : Array<ICarrier>;
    dispatches : Array<IDispatchInstruction>;

    dispatchViews : Array<IDispatchView>;
    truckTypes : Array<ITruckType>;
    compliances : Array<ICompliance>;
}

interface IDispatchInstructionDashboardState {
    isLoading : boolean;
    dataFetched : boolean;
    rows : Array<IDispatchInstruction>;
    columns : Array<ICustomTableColumn>;
    collapseStatusColumn : { [key : string] : boolean };
    tripLoadDate : string;
    newTripDate : string;
    tripDriver ?: string;
    showDispatchDeleteConfirmationPrompt : boolean;
    showTripDeleteConfirmationPrompt : boolean;
    deletingDispatchInstruction ?: IDispatchView;
    editingTrip ?: ITrip;
    deletingTrip ?: ITrip;
    isAddingTrip : boolean;
    tripStatus : string;
    tripDescription ?: string;
    tripRegNr ?: string;
    tripContainer ?: string;
    tripContainerTareWeight ?: number;
    tripCarrierId ?: number;
    selectedFromDate : moment.Moment;
    selectedToDate : moment.Moment;
    editingDispatchLine ?: IDashboardDispatchInstructionLine;
    selectableStockRows : Array<IStock>;
    newRequestedStatus ?: string;
    dispatchRequestedForMove ?: IDispatchView;
    expanded : { [key : number] : boolean};
    overrideExport : boolean;
    requestSiteConfirmation ?: DropResult;
    filtersExpanded : boolean;

    selectedStockRows : Array<IStock>;
    editingDispatch ?: IDispatchView;
    substituteDispatchLine : boolean;
    substituteLine : boolean;
    selectedStock ?: IStock;
    selectedDispatchLineId ?: number;
    dispatchLineToSubstitute ?: IDispatchInstructionLine;

    isFileUploadDownloadDialogOpen : boolean;
    selectedDispatch ?: IDispatchInstruction;
    selectedDispatchView ?: IDispatchView;

    selectedOrganizationOption ?: IOptionType;

    selectedFiles : Array<File>;
    openManualUploadDialog : boolean;

    onYesClick : () => void;
    onNoClick : () => void;
    message : string;
    showPrompt : boolean;

    isEmailFormOpen : boolean;

    filterOpen : boolean;
    showRangePicker : boolean;
    barcodeSearchValue : string;
    dispatchesFilteredByBarcode : Array<IDispatchView>;

    draggableWidth ?: number;
    showInfoDispatch ?: IDispatchView;
    isDispatchInfoPopupOpen : boolean;

    selectedTripToChange ?: number;

    isIncomingDispatchPanelExpanded : boolean;


    isPOFileOrchardConfirmDialogOpen : boolean;
    poFileAction : string;
    includeOrchardsOnPOFile : boolean;
}

interface IDateRange {
    selection : {
        startDate : Date;
        endDate : Date;
    };
}
interface IDraggableContentProps {
    index : number;
    filteredDispatches : Array<IDispatchView>;
    dragProvided : DraggableProvided;
    dragSnapshot : DraggableStateSnapshot;
    isClone : boolean;
}

const ITEM_SIZE_CAN_DOWNLOAD = 205;
const ITEM_SIZE_CAN_NOT_DOWNLOAD = ITEM_SIZE_CAN_DOWNLOAD - 25;

const StatusColumnDiv = posed.div(
    {
        expanded : { maxWidth: window.innerWidth },
        collapsed : { maxWidth: 40 },
    },
);

const getPalletBaseTypeCode = (id ?: number) => {
    const state = getState();
    return state.masterData.palletBaseTypes.find(x => x.id === id)?.code;
};

const getCommodityCode = (id : number) => {
    const state = getState();
    return state.masterData.commodities.find(x => x.id === id)?.code;
};

const getVarietyCode = (id : number) => {
    const state = getState();
    return state.masterData.varieties.find(x => x.id === id)?.code;
};

const getPackCode = (id : number) => {
    const state = getState();
    return state.masterData.packs.find(x => x.id === id)?.code;
};

const getSizeCode = (id : number) => {
    const state = getState();
    return state.masterData.sizes.find(x => x.id === id)?.code;
};

const getColourCode = (id ?: number) => {
    const state = getState();
    return state.masterData.colours.find(x => x.id === id)?.code;
};

const getGradeCode = (id : number) => {
    const state = getState();
    return state.masterData.grades.find(x => x.id === id)?.code;
};

const getSiteShortDescription = (id ?: number) => {
    const state = getState();
    return state.masterData.sites.find(x => x.id === id)?.shortDescription;
};

const getFarmCode = (id ?: number) => {
    const state = getState();
    return state.masterData.farms.find(x => x.id === id)?.code;
};

export const getDispatchCSVFileName = (dispatchInstruction : IDispatchInstruction) => {
    return 'zzFresh' + moment(moment.now()).local().format(DATEPICKER_FORMAT_DEFAULT) + dispatchInstruction.dispatchCode + '.csv';
};

export const exportDispatchCSV = (exportedDispatch : IDispatchInstruction) => {
    const state = getState();
    const stockData = state.data.stocks;
    const returnData : Array<Array<string>> = [];
    returnData.push(DISPATCH_CSV_HEADINGS);

    if (!exportedDispatch || !exportedDispatch.isActive) {
        return [];
    }

    exportedDispatch.dispatchLines.filter(x => x.isActive).forEach((x) => {
        const stock = stockData?.find(y => y.id === x.currentStockId && x.isActive);
        if (!stock) {
            return null;
        }

        let sequence = 1;
        getCSVStockLineSummary(stock.stockLines.filter(y => y.isActive)).forEach((y) => {
            returnData.push([
                exportedDispatch.dispatchCode ? exportedDispatch.dispatchCode : '', // 'Dispatch ID',
                getSiteShortDescription(exportedDispatch.sourceSiteId) ?? '', // 'From Depot',
                getSiteShortDescription(exportedDispatch.destinationSiteId) ?? '', // 'To Depot',
                moment(exportedDispatch.updatedOn).format(DISPATCH_CSV_DATE_FORMAT), // 'Date' dd/mm/yyyy hh:mm:ss AA
                stock.barcode ? stock.barcode : '', // 'Barcode',
                y.cartons ? ((!y.totalInners || y.totalInners === 0) ? y.cartons : y.totalInners).toString() : '', // 'No Cartons',
                getCommodityCode(y.commodityId) ?? '', // 'Commodity Code',
                getVarietyCode(y.varietyId) ?? '', // 'Variety Code',
                getPackCode(y.packId) ?? '', // 'Pack Code',
                getGradeCode(y.gradeId) ?? '', // 'Grade Code',
                getSizeCode(y.sizeId) ?? '', // 'Count Code',
                getColourCode(y.colourId) ?? '', // 'Inventory Code',
                stock.grossWeight ? stock.grossWeight.toFixed(0).toString() : '', // 'Weight',
                sequence.toString(), // 'Sequence Number',
                getFarmCode(y.farmId) ?? '', // 'Farm Number',
                getPalletBaseTypeCode(stock.palletBaseTypeId) ?? '', // 'Pallet Base Type',
            ]);
            sequence += 1;
        });
    });
    return returnData;
};

class DispatchInstructionDashboard extends React.PureComponent<IDispatchInstructionDashboardProps, IDispatchInstructionDashboardState> {
    private anchorEl : any;

    constructor(props : IDispatchInstructionDashboardProps) {
        super(props);

        this.state = {
            isLoading: false,
            dataFetched: false,
            showDispatchDeleteConfirmationPrompt: false,
            showTripDeleteConfirmationPrompt: false,
            selectedFromDate: moment().local().startOf('day'),
            selectedToDate: moment().local().endOf('day'),
            rows: [],
            columns: this.getColumns(),
            collapseStatusColumn: { },
            tripLoadDate: formatMomentToDatePicker(moment().utc().startOf('day')),
            newTripDate: formatMomentToDatePicker(moment().utc().startOf('day')),
            tripDriver: '',
            tripDescription: '',
            tripRegNr: '',
            tripCarrierId: undefined,
            tripStatus: '',
            isAddingTrip: false,
            selectableStockRows: [],
            expanded: {},
            overrideExport: false,
            filtersExpanded: true,

            selectedStockRows: [],
            substituteDispatchLine: false,
            substituteLine: false,

            isFileUploadDownloadDialogOpen: false,
            isEmailFormOpen: false,

            openManualUploadDialog: false,
            selectedFiles: [],

            onYesClick: () => null,
            onNoClick: () => null,
            message: '',
            showPrompt: false,

            filterOpen: false,
            showRangePicker: false,
            dispatchesFilteredByBarcode: [],
            barcodeSearchValue: '',

            isDispatchInfoPopupOpen: false,
            isIncomingDispatchPanelExpanded: false,

            isPOFileOrchardConfirmDialogOpen: false,
            poFileAction: '',
            includeOrchardsOnPOFile: false,
        };
    }

    public componentDidMount = async () => {
        if (!!this.props.selectedSiteIds && this.props.selectedSiteIds.length > 0) {
            this.setLoading(true);
            // checks if indexedDB is available.
            const isIndexedDBAvailable = !!self.indexedDB ? true : false;

            if (isIndexedDBAvailable) {
                await syncMasterData(false);
            }

            try {
                const res = await DispatchHttpService.getDispatchDashboardViewData(this.getDate('from'), this.getDate('to'), undefined, undefined, this.props.selectedSiteIds, !isIndexedDBAvailable);

                dataSetAllDispatchDashboardViewRelatedData(res.data);
                this.setState({ dataFetched: true, collapseStatusColumn: this.getEmptyColumns(this.props) }, () => this.setLoading(false));
            } catch (e) {
                generalShowErrorSnackbar('An error occurred retrieving dispatch dashboard data.');
                this.setLoading(false);
            }
        }
    };

    public componentDidUpdate = async (prevProps : IDispatchInstructionDashboardProps) => {
        const nextProps = this.props;
        if (nextProps.selectedSiteIds !== prevProps.selectedSiteIds) {
            this.setLoading(true);
            // checks if indexedDB is available.
            const isIndexedDBAvailable = !!self.indexedDB ? true : false;

            try {
                const res = await DispatchHttpService.getDispatchDashboardViewData(this.getDate('from'), this.getDate('to'), undefined, undefined, nextProps.selectedSiteIds, !isIndexedDBAvailable);

                dataSetAllDispatchDashboardViewRelatedData(res.data);
                this.setState({ dataFetched: true, collapseStatusColumn: this.getEmptyColumns(this.props) }, () => this.setLoading(false));
            } catch (e) {
                generalShowErrorSnackbar('An error occurred retrieving dispatch dashboard data.');
                this.setLoading(false);
            }
        }
    };

    private getRights = (props : IDispatchInstructionDashboardProps) => props.auth?.session?.user?.rights || [];

    private hasTripEditingRight = createSelector(
        [this.getRights],
        rights => rights.some(x => x.isActive && x.code.endsWith('_TRIP_TRANSACTIONS_EDIT')));

    private hasUploadTeraokaRight = createSelector(
        [this.getRights],
        rights => rights.some(x => x.isActive && x.code === 'DISPATCH_UPLOAD_TERAOKA'));

    private hasTripSuperEditingRight = createSelector(
        [this.getRights],
        rights => rights.some(x => x.isActive && x.code.endsWith('_TRIP_TRANSACTIONS_SUPER_EDIT')));

    private hasDispatchCorrectionRight = createSelector(
        [this.getRights],
        rights => rights.some(x => x.isActive && x.code === 'DISPATCH_CORRECTION_ADMIN'));

    private hasDispatchDispatchedRight = createSelector(
        [this.getRights],
        rights => rights.some(x => x.isActive && x.code === 'DISPATCH_DASHBOARD_MOVE_TO_DISPATCHED'));

    private getDispatchInstructions = (props : IDispatchInstructionDashboardProps) => props.dispatchViews ?? [];
    private getSelectedSiteIds = (props : IDispatchInstructionDashboardProps) => props.selectedSiteIds;

    private getEmptyColumns =  createSelector([this.getDispatchInstructions], (dispatchInstructions : Array<IDispatchView>) => {
        const usedStatusses = uniq(dispatchInstructions.filter(x => x.isActive).map(x => x.status));
        const statusses = DISPATCH_INSTRUCTION_STATUSSES.filter(x => !usedStatusses.some(y => y === x) || (x === 'Received'));
        const returnObject : { [key : string] : boolean} = {};
        statusses.forEach(x => returnObject[x] = true);
        return returnObject;
    });

    private getCurrentlyCollapsed = (props : IDispatchInstructionDashboardProps, state : IDispatchInstructionDashboardState) => state.collapseStatusColumn;

    private expandNonEmptyColumns = createSelector([this.getDispatchInstructions, this.getCurrentlyCollapsed],
        (dispatchInstructions : Array<IDispatchView>, currentCollapsed : {[key : string] : boolean}) => {
            const nonEmpty = DISPATCH_INSTRUCTION_STATUSSES.filter(x => dispatchInstructions.some(y => y.status === x && y.isActive));
            const returnObject : { [key : string] : boolean} = {};
            lodash(currentCollapsed).forEach((x, key) => {
                if (!nonEmpty?.some(y => y === key && x)) {
                    returnObject[key] = true;
                }
            });
            return returnObject;
        });

    private getDispatchStockCount = (dispatchLines : Array<IDispatchInstructionLine>) => dispatchLines.length;

    private getColumns = () => {
        const columns : Array<ICustomTableColumn> = [
            { title: 'Id', field: 'id' },
            { title: 'Source Site', field: 'sourceSiteId', formatFunction: getSiteShortDescription },
            { title: 'Destination Site', field: 'destinationSiteId', formatFunction: getSiteShortDescription },
            { title: 'Load Date', field: 'loadDate', formatFunction: formatDateTimeToDateOnly, sortFunction: compareDate },
            { title: 'Pallets', field: 'dispatchLines', formatFunction: this.getDispatchStockCount },
            { title: 'Status', field: 'status' },
            { title: 'Transport', field: 'transport' },
            { title: 'Driver', field: 'driver' },
            { title: 'Trip', field: 'tripId' },
            { title: 'Is Printed?', field: 'isPrinted', formatFunction: booleanToYesNo, type: 'boolean' },
            { title: 'Created By', field: 'createdByName' },
            { title: 'Created On', field: 'createdOn', formatFunction: formatDateTime, sortFunction: compareDate },
            { title: 'Updated By', field: 'updatedByName' },
            { title: 'Updated On', field: 'updatedOn', formatFunction: formatDateTime, sortFunction: compareDate },
            { title: 'Active?', field: 'isActive', formatFunction: booleanToYesNo, type: 'boolean' },
        ];
        return columns;
    };

    private getTripDispatchLines = (tripId : number) => {
        const totalPallets : Array<number> = [];
        this.props.dispatchViews.forEach((x) => {
            if (x.tripId === tripId) {
                totalPallets.push(x.pallets ?? 0);
            }
        });
        return totalPallets;
    };

    private getDate = (type : 'from' | 'to' | 'trip') => {
        switch (type) {
            case 'from':
                return this.state.selectedFromDate.utc(true).startOf('day').unix() * 1000;
            case 'to':
                return this.state.selectedToDate.utc(true).endOf('day').unix() * 1000;
            case 'trip':
                return moment(this.state.tripLoadDate, DATEPICKER_FORMAT_DEFAULT).utc().unix() * 1000;
        }
    };

    private getTripPallets = (tripId : number) => this.getTripDispatchLines(tripId).length;

    private getTripWeight = (tripId : number) => {
        let palletsTotalWeight : number = 0;
        this.props.dispatchViews.forEach((x) => {
            if (x.tripId === tripId) {
                palletsTotalWeight += x?.palletsTotalWeight ?? 0;
            }
        });
        return palletsTotalWeight;
    };

    private setLoading = (isLoading : boolean = false) => {
        this.setState({ isLoading });
    };

    private refreshData = async (noAnnounce ?: boolean) => {
        this.setLoading(true);
        // checks if indexedDB is available.
        const isIndexedDBAvailable = !!self.indexedDB ? true : false;
        try {
            const res = await DispatchHttpService.getDispatchDashboardViewData(this.getDate('from'), this.getDate('to'), undefined, undefined, this.props.selectedSiteIds, !isIndexedDBAvailable);

            dataSetAllDispatchDashboardViewRelatedData(res.data);
            this.setState({ collapseStatusColumn: this.expandNonEmptyColumns(this.props, this.state) });

            this.setLoading(false);
            if (!noAnnounce) {
                generalShowSuccessSnackbar('Data Refreshed');
            }
        } catch (e) {
            generalShowErrorSnackbar('An error occurred retrieving dispatch dashboard data.');
            this.setLoading(false);
        }
    };

    private onDragEnd = async (dropResult : DropResult) => {
        const dispatchId = dropResult.draggableId && Number(dropResult.draggableId);
        const newStatus = dropResult.destination && dropResult.destination.droppableId;
        const dispatchView = this.props.dispatchViews.find(x => x.id === dispatchId);

        if (newStatus !== dispatchView?.status) {
            let dispatchInstruction : IDispatchInstruction | undefined;
            try {
                this.setLoading(true);

                const res = await DispatchHttpService.getDispatch(dispatchView?.id);
                if (res && res.data) {
                    dispatchInstruction = res.data;
                }
            } catch (ex) {
                generalShowErrorSnackbar('Failed to load dispatch data for compliance validation checks');
            } finally {
                this.setLoading(false);
            }
            if (!!dispatchInstruction && newStatus && newStatus !== dispatchInstruction?.status) {
                if (newStatus === 'Dispatched' && !this.hasDispatchDispatchedRight(this.props)) {
                    generalShowErrorSnackbar('This dispatch cannot be moved to Dispatched.');
                    return;
                }
                if (newStatus !== 'Draft' && (!dispatchInstruction.sourceSiteId || !dispatchInstruction.destinationSiteId)) {
                    generalShowErrorSnackbar('This dispatch cannot be moved out of draft phase since a source or destination site is not supplied.');
                    return;
                }
                if (!this.isDroppable(dispatchInstruction, true, newStatus)) {
                    if (this.canAdminOverride(dispatchInstruction, newStatus)) {
                        this.setState({ newRequestedStatus: newStatus, dispatchRequestedForMove: dispatchInstruction });
                    } else {
                        generalShowErrorSnackbar(`This dispatch cannot be moved to ${newStatus.toLowerCase()}.`);
                    }
                    return;
                }
                if (newStatus === 'Confirmed' || newStatus === 'Instructed') {
                    if (!this.validateExportStatus(dispatchInstruction)) {
                        const site = this.props.sites.find(x => x.id === dispatchInstruction?.sourceSiteId);
                        if (site && !site.handlesExport) {
                            if (this.state.overrideExport) {
                                this.setState({ overrideExport: false });
                            } else {
                                this.setState({ requestSiteConfirmation: dropResult });
                                return;
                            }
                        } else {
                            generalShowErrorSnackbar('This dispatch cannot be confirmed until all the pallets on the dispatch have been inspected and approved.');
                            return;
                        }
                    }
                }
                const oldDispatch = { ...dispatchInstruction };
                const newDispatch = { ...dispatchInstruction };
                newDispatch.status = newStatus;
                dataSetDispatchView(newDispatch);
                this.setLoading(true);

                const data : ISetDispatchStatus = {
                    dispatchId: oldDispatch.id,
                    status: newStatus,
                    webStatusChange: true,
                };

                if (oldDispatch.status.toLowerCase() === 'Instructed'.toLowerCase() && newStatus.toLowerCase() === 'Dispatched'.toLowerCase()) {
                    data.manualOverride = true;
                }

                try {
                    const res = await DispatchHttpService.setDispatchStatus(data);

                    if (res && res.data) {
                        dataSetDispatchView(res.data);
                        generalShowSuccessSnackbar(`Dispatch status successfully changed to ${newStatus.toLowerCase()}.`);
                    } else {
                        generalShowErrorSnackbar('An error occurred updating the dispatch status.');
                        dataSetDispatchView(oldDispatch);
                    }
                } catch (e) {
                    generalShowErrorSnackbar(e?.data?.Message ? e?.data?.Message : 'An error occurred updating the dispatch status.');
                    dataSetDispatchView(oldDispatch);
                } finally {
                    this.setLoading(false);
                }
            } else {
                generalShowErrorSnackbar('Dispatch could not be  found.');
            }

        }
    };

    private complianceMoveConfirmed = () => {
        const dropResult = this.state.requestSiteConfirmation;
        this.setState({ overrideExport: true, requestSiteConfirmation: undefined }, () => {
            if (dropResult) {
                this.onDragEnd(dropResult);
            }
        });
    };

    private complianceMoveDeclined = () => this.setState({ requestSiteConfirmation: undefined });

    private validateExportStatus = async (dispatchInstruction : IDispatchInstruction) => {
        const dispatchLineStockIds : Array<number> = dispatchInstruction.dispatchLines?.filter(x => x.isActive).map(x => x.currentStockId);
        let complianceLines : Array<IComplianceLine> = [];

        this.props.compliances.forEach((compliance) => {
            if (!!compliance.isActive) {
                compliance.complianceLines.forEach((line) => {
                    if (line.isActive) {
                        const index = complianceLines.findIndex(x => x.id === line.id);
                        if (index === -1) {
                            complianceLines = addArrayElement(complianceLines, line);
                        }
                    }
                });
            }
        });

        return !dispatchLineStockIds?.some((stockId) => {
            const stock = this.props.stocks.find(x => x.id === stockId);
            const complianceLine = complianceLines.find(x => x.stockId === stockId);
            if (!stock) {
                return true;
            }
            if (stock.channel === 'E') {
                if (!!complianceLine) {
                    if (!complianceLine?.isInspected || complianceLine.isRejected) {
                        return true;
                    } else {
                        return false;
                    }
                } else {
                    return true;
                }
            }
            return false;
        });
    };

    private onStatusOverriden = async (approved : boolean) => {
        if (this.state.dispatchRequestedForMove) {
            const oldDispatch : IDispatchView = { ...this.state.dispatchRequestedForMove };
            const newDispatch : IDispatchView = { ...this.state.dispatchRequestedForMove };
            const newStatus = this.state.newRequestedStatus;
            this.setState({ dispatchRequestedForMove: undefined, newRequestedStatus: undefined });
            if (approved && oldDispatch && newStatus) {
                this.setLoading(true);
                newDispatch.status = newStatus;
                dataSetDispatchView(newDispatch);
                this.setLoading(true);

                const data : ISetDispatchStatus = {
                    dispatchId: oldDispatch.id,
                    status: newStatus,
                    webStatusChange: true,
                };

                if (oldDispatch.status.toLowerCase() === 'Instructed'.toLowerCase() && newStatus.toLowerCase() === 'Dispatched'.toLowerCase()) {
                    data.manualOverride = true;
                }

                try {
                    const res = await DispatchHttpService.setDispatchStatus(data);

                    if (res && res.data) {
                        dataSetDispatchView(res.data);
                        generalShowSuccessSnackbar(`Dispatch status successfully changed to ${newStatus.toLowerCase()}.`);
                        this.setLoading(false);
                    } else {
                        generalShowErrorSnackbar('An error occurred updating the dispatch status.');
                        dataSetDispatchView(oldDispatch);
                        this.setLoading(false);
                    }
                } catch (e) {
                    generalShowErrorSnackbar(e?.data?.Message ? e?.data?.Message : 'An error occurred updating the dispatch status.');
                    dataSetDispatchView(oldDispatch);
                    this.setLoading(false);
                }
            }
        }
    };

    private deleteDispatch = (deletingDispatchInstruction : IDispatchView) => this.setState({ showDispatchDeleteConfirmationPrompt: true, deletingDispatchInstruction });

    private dispatchDeleteConfirmed = async (deleteReason : string) => {

        if (this.state.deletingDispatchInstruction) {
            this.setLoading(true);

            if (deleteReason !== '' && deleteReason.length >= 200) {
                try {
                    const res = await DispatchHttpService.dispatchDelete(this.state.deletingDispatchInstruction.id, deleteReason);

                    if (res && res.data) {
                        dataSetDispatchView(res.data);
                        generalShowSuccessSnackbar('Dispatch successfully deleted.');
                        this.setLoading(false);
                    } else {
                        generalShowErrorSnackbar('An error occurred deleting the dispatch instruction.');
                        this.setLoading(false);
                    }
                } catch (e) {
                    generalShowErrorSnackbar('An error occurred deleting the dispatch instruction.');
                    this.setLoading(false);
                }
            } else {
                generalShowErrorSnackbar('Reason for deleting this dispatch must be at least 200 characters.');
            }
        }
        this.closeDeleteConfirmationPopup();
    };

    private getCarrierName = (carrierId ?: number) => {
        const carrier = this.props.carriers.find(x => x.id === carrierId);
        return carrier?.name;
    };

    private getDevices = (tempUnits : Array<ITemperatureUnit>) => tempUnits.filter(x => x.isActive).map(x => x.deviceNumber).toString().replace(/,/g, ', ');

    private closeDeleteConfirmationPopup = () => this.setState({ deletingDispatchInstruction: undefined, showDispatchDeleteConfirmationPrompt: false });

    private editTrip = (editingTrip : ITrip) => {
        this.setState({
            editingTrip,
            tripDescription: editingTrip.description,
            tripDriver: editingTrip.driver,
            tripContainer: editingTrip.container,
            tripContainerTareWeight: editingTrip.containerTareWeight,
            tripRegNr: editingTrip.registrationNumber,
            tripCarrierId: editingTrip.carrierId,
        }, async () => {
            try {
                this.setLoading(true);
                const res = await DispatchHttpService.getDispatchesLinkedToTrip(editingTrip.id);
                if (res && res.data) {
                    res.data.dispatches.forEach((x) => {
                        dataSetDispatchInstruction(x);
                    });
                    res.data.stocks.forEach((x) => {
                        dataSetStock(x);
                    });
                }
            } catch (e) {
                generalShowErrorSnackbar('Failed to load dispatches data linked to the selected trip');
            } finally {
                this.setLoading(false);
            }
        });
    };

    private deleteTrip = (deletingTrip : ITrip) => this.setState({ showTripDeleteConfirmationPrompt: true, deletingTrip });

    private tripDeleteConfirmed = async (deleteReason : string) => {

        if (this.state.deletingTrip) {

            if (deleteReason !== '' && deleteReason.length >= 200) {
                const oldTrip = { ...this.state.deletingTrip };
                const newTrip = { ...this.state.deletingTrip };
                newTrip.isActive = false;
                dataSetTrip(newTrip);
                newTrip.updatedOn = undefined;
                this.setLoading(true);
                try {
                    const res = await TripHttpService.tripDelete(newTrip.id, deleteReason);

                    if (res && res.data) {
                        dataSetTrip(res.data);
                        if (!res.data.isActive) {
                            this.props.dispatchViews.filter(x => x.tripId === res.data.id).forEach((x) => {
                                const newDispatch = { ...x };
                                newDispatch.tripId = undefined;
                                newDispatch.registrationNumber = undefined;
                                newDispatch.driver = undefined;
                                newDispatch.updatedByName = res.data.updatedByName;
                                newDispatch.updatedOn = res.data.updatedOn;
                                dataSetDispatchView(newDispatch);
                            });
                        }
                        generalShowSuccessSnackbar('Trip successfully deleted.');
                        this.setLoading(false);
                    } else {
                        generalShowErrorSnackbar('An error occurred deleting the trip.');
                        dataSetTrip(oldTrip);
                        this.setLoading(false);
                    }
                } catch (e) {
                    generalShowErrorSnackbar('An error occurred deleting the trip.');
                    dataSetTrip(oldTrip);
                    this.setLoading(false);
                }
            } else {
                generalShowErrorSnackbar('Reason for deleting this trip must be at least 200 characters.');
            }
        }
        this.closeTripDeleteConfirmationPopup();
    };

    private addNewTrip = () => {
        this.setState({ isAddingTrip: true });
    };

    private onTripSubmit = async (value : ITripFormValues) => {
        const successMessage = this.state.editingTrip ? 'updated' : 'added';
        const failMessage = this.state.editingTrip ? 'updating' : 'adding';

        this.setLoading(true);
        try {
            const res = await TripHttpService.addOrUpdateTrip(new Trip(value));

            if (res && res.data) {
                dataSetTrip(res.data);

                this.props.dispatchViews.filter(x => x.tripId === res.data.id).forEach((x) => {
                    const newDispatch = { ...x };
                    newDispatch.tripId = res.data.isActive ? res.data.id : undefined;
                    newDispatch.registrationNumber = res.data.isActive ? res.data.registrationNumber : undefined;
                    newDispatch.driver = res.data.isActive ? res.data.driver : undefined;
                    newDispatch.updatedByName = res.data.updatedByName;
                    newDispatch.updatedOn = res.data.updatedOn;
                    dataSetDispatchView(newDispatch);
                });

                this.setState({ tripLoadDate: moment(res.data.loadDate, DATEPICKER_FORMAT_DEFAULT).format(DATEPICKER_FORMAT_DEFAULT).toString() });
                generalShowSuccessSnackbar(`Trip successfully ${successMessage}.`);
                this.setLoading(false);
                this.tripDialogClosed();
            } else {
                generalShowErrorSnackbar(`An error occurred ${failMessage} the trip.`);
                this.setLoading(false);
            }
        } catch (e) {
            generalShowErrorSnackbar(`An error occurred ${failMessage} the trip.`);
            this.setLoading(false);
        }
    };

    private tripDialogClosed = () => {
        this.setState({ isAddingTrip: false, editingTrip: undefined, tripDriver: '', tripStatus: '', tripDescription: '', tripRegNr: '' });
    };

    private getDroppableStyle = (targetStatus : string, snapshot : DroppableStateSnapshot) => {
        const dispatchId = snapshot.draggingOverWith;
        const dispatchInstruction = this.props.dispatchViews.find(x => x.id === (dispatchId && Number(dispatchId)));
        if (dispatchInstruction && snapshot.isDraggingOver && !this.state.collapseStatusColumn[targetStatus]) {
            const currentStatus = dispatchInstruction.status;
            if (((!dispatchInstruction.sourceSiteId || !dispatchInstruction.destinationSiteId) && targetStatus !== 'Draft')
                || ((currentStatus !== targetStatus) && !VALID_DISPATCH_STATUS_DESTINATIONS[currentStatus].some(x => x === targetStatus))) {

                if (ADMIN_OVERRIDE_STATUSSES[currentStatus].some(x => x === targetStatus)
                    || ((currentStatus === 'Received') && (targetStatus === 'Dispatched') && (dispatchInstruction?.webStatusChange))) {
                    return { backgroundColor: 'darkorange' };
                }
                return { backgroundColor: '#ff6666', cursor: 'no-drop' };
            }
            return { backgroundColor: '#E6E6E6' };
        }
        return undefined;
    };

    private isDroppable = (dispatchInstruction : IDispatchView, isDragging : boolean, targetStatus ?: string) => {
        if (isDragging) {
            const currentStatus = dispatchInstruction.status;
            return !(
                ((!dispatchInstruction.sourceSiteId || !dispatchInstruction.destinationSiteId) && targetStatus !== 'Draft')
                || ((currentStatus !== targetStatus) && !VALID_DISPATCH_STATUS_DESTINATIONS[currentStatus].some(x => x === targetStatus))
            );
        }
        return true;
    };

    private canAdminOverride = (dispatchInstruction : IDispatchView, newStatus : string) => {
        const currentStatus = dispatchInstruction.status;
        return this.hasDispatchCorrectionRight(this.props) &&
            (ADMIN_OVERRIDE_STATUSSES[currentStatus].some(x => x === newStatus)
                || ((currentStatus === 'Received') && (newStatus === 'Dispatched') && (dispatchInstruction?.webStatusChange)));
    };

    private collapseColumn = (status : string) => {
        this.setState({ collapseStatusColumn: addObjectAttribute(this.state.collapseStatusColumn, status, true) });
    };

    private expandColumn = (status : string) => {
        this.setState({ collapseStatusColumn: removeObjectAttribute(this.state.collapseStatusColumn, status) });
    };

    private getTrips = (props : IDispatchInstructionDashboardProps) => props.trips;
    private getSelectedTrip = (props : IDispatchInstructionDashboardProps, state : IDispatchInstructionDashboardState) => state.editingTrip;
    private getCarriers = (props : IDispatchInstructionDashboardProps) => props.carriers;
    private getTruckTypes = (props : IDispatchInstructionDashboardProps) => props.truckTypes;
    private getTripLoadDate = (props : IDispatchInstructionDashboardProps, state : IDispatchInstructionDashboardState) => state.tripLoadDate;

    private tripOptions = (loadDate : string) => {
        if (!this.props.trips || !this.props.sites) {
            return [];
        }
        let returnValue : Array<IOptionType> = [];

        returnValue = this.props.trips.filter(x => x.isActive && moment(x.loadDate, DATEPICKER_FORMAT_DEFAULT).format(DATEPICKER_FORMAT_DEFAULT).toString() ===
        moment(loadDate, DATEPICKER_FORMAT_DEFAULT).format(DATEPICKER_FORMAT_DEFAULT).toString()).map((x) => {
            return { label: '(' + x.id + ') ' + '(' + (x.fleetNumber ? x.fleetNumber : 'No Fleet Nr') + ') ' + x.description, value: x.id };
        });

        returnValue.push({ value: 0, label: 'No Trip' });
        return returnValue;
    };

    private setTripId = async (dispatchInstruction : IDispatchView, tripId : number) => {
        this.setLoading(true);
        const tripData = {
            tripId,
            dispatchId: dispatchInstruction.id,
        };

        try {
            const res = await DispatchHttpService.setDispatchTrip(tripData);
            try {

                dataSetDispatchView(res.data);
                this.setState({ dataFetched: true, collapseStatusColumn: this.getEmptyColumns(this.props) }, () => this.setLoading(false));
                if (res.data.showTripError) {
                    generalShowWarningSnackbar('Total pallet weight exceeds the truck\'s max payload');
                }
                generalShowSuccessSnackbar('Dispatch trip successfully changed.');
                this.setLoading(false);
            } catch (e) {
                generalShowErrorSnackbar('An error occurred updating the dispatch trip.');
                this.setLoading(false);
            }
        } catch (e) {
            generalShowErrorSnackbar('An error occurred updating the dispatch trip.');
            this.setLoading(false);
        }
    };

    private columnScrolled = (e : React.UIEvent<HTMLDivElement>, status : string) => {
        const scrollArea = document.getElementById(`${status}_scrollArea`);
        const scrolledValue = scrollArea && scrollArea.scrollTop;
        const fixedElements = jQuery('div[id*=dropdown]');
        fixedElements.toArray().forEach((x) => {
            x.style.marginTop = scrolledValue ? (0 - scrolledValue).toString() + 'px' : '';
        });
    };

    private handleDateRangeChange = (start : moment.Moment, end : moment.Moment, changedBy ?: 'start' | 'end') => {
        const selectedFromDate = changedBy === 'start' ? start.local(true) : end < start ? end.local(true) : start.local(true);
        const selectedToDate = changedBy === 'end' ? end.local(true) : start > end ? start.local(true) : end.local(true);
        this.setState({ selectedFromDate, selectedToDate });
    };

    private onFilterCancelClick = () => {
        this.setState({
            filterOpen: false,
        });
    };

    private closeFilter = () => {
        this.setState({ filterOpen: false });
    };

    private toggleFilter = () => {
        this.setState({ filterOpen: !this.state.filterOpen });
    };

    private toggleShowPicker = () => {
        this.setState({ showRangePicker: !this.state.showRangePicker });
    };

    private handleRangeChange = (ranges : IDateRange) => {
        const start = moment({ y: ranges.selection.startDate.getFullYear(), M: ranges.selection.startDate.getMonth(), d: ranges.selection.startDate.getDate() });
        const end = moment({ y: ranges.selection.endDate.getFullYear(), M: ranges.selection.endDate.getMonth(), d: ranges.selection.endDate.getDate() });
        this.handleDateRangeChange(start, end);
    };

    private barcodeSearchValueChanged = (event : CustomChangeEvent) => {
        if (event.target.value && event.target.value !== '') {
            this.setState({ barcodeSearchValue: event.target.value });
        } else {
            this.setState({ barcodeSearchValue: event.target.value, dispatchesFilteredByBarcode: [] });
        }
    };

    private getDispatchByBarcode = async () => {
        if (this.state.barcodeSearchValue && this.state.barcodeSearchValue !== '') {
            this.setLoading(true);
            try {
                const res = await DispatchHttpService.getDispatchByBarcode(this.state.barcodeSearchValue);

                if (res && res.data) {
                    const trips = res.data?.map(x => x.trip);
                    trips.forEach(x => x ? dataSetTrip(x) : null);
                    this.setState({ dispatchesFilteredByBarcode: res.data });
                }
            } catch (e) {
                generalShowErrorSnackbar('Error occured while searching for barcode');
            } finally {
                this.setLoading(false);
            }
        } else {
            generalShowErrorSnackbar('No barcode was provided');
        }
    };

    private getStockBarcode = (stockId : number) => {
        const stock = this.props.stocks.find(x => x.id === stockId);
        return stock?.barcode || '';
    };

    private getMainStockLine = (stock : IStock) => {
        if (stock.stockLines.length > 0) {
            let mainStockLine = { ...stock.stockLines[0] };
            stock.stockLines.forEach((x) => {
                if ((mainStockLine.cartons < x.cartons) || ((mainStockLine.cartons === x.cartons) && (mainStockLine.id > x.id))) {
                    mainStockLine = x;
                }
            });

            return mainStockLine;
        }
    };

    private getSelectableStock = (stockId : number) => {
        const stockData = this.props.stocks;
        const selectedStock = this.props.stocks.find(x => x.id === stockId);
        if (selectedStock) {
            const selectedMainStockLine = this.getMainStockLine(selectedStock);
            const validStock = stockData.filter(x => x.isActive
                && selectedStock?.currentSiteId === x.currentSiteId
                && x.status === 'In Stock'
                && x.currentOrganizationId === selectedStock.currentOrganizationId
                && x.marketId === selectedStock.marketId).filter((x) => {
                const mainStockLine = this.getMainStockLine(x);
                if (mainStockLine) {
                    return (
                        mainStockLine.commodityId === selectedMainStockLine?.commodityId
                            && mainStockLine.varietyId === selectedMainStockLine?.varietyId
                            && mainStockLine.packId === selectedMainStockLine?.packId
                            && mainStockLine.sizeId === selectedMainStockLine?.sizeId
                            && mainStockLine.gradeId === selectedMainStockLine?.gradeId
                    );
                }
            });
            return validStock;
        }
        return [];
    };

    private submitDispatchCSVUpload = async (id : number) => {
        const oldDispatchView = this.props.dispatchViews.find(x => x.id === id);
        try {
            const res = await FTPHttpService.uploadDispatchCSV({ dispatchId: id });

            if (res && res.data) {
                if (oldDispatchView) {
                    const updatedDispatchView : IDispatchView = {
                        ...oldDispatchView,
                        teraokaUploaded: true,
                    };
                    dataSetDispatchView(updatedDispatchView);
                }

                const oldDispatch = this.state.selectedDispatch;
                if (oldDispatch) {
                    const updatedDispatch : IDispatchInstruction = {
                        ...oldDispatch,
                        teraokaUploaded: true,
                    };
                    this.setState({ selectedDispatch: updatedDispatch });
                }
                generalShowSuccessSnackbar('CSV successfully uploaded.');
            } else {
                generalShowErrorSnackbar('CSV upload failed!');
            }
        } catch (e) {
            generalShowErrorSnackbar('CSV upload failed!');
        }
    };

    private onApplyClick = async () => {
        await this.refreshData(true);
        this.closeFilter();
        this.setState({ collapseStatusColumn: this.expandNonEmptyColumns(this.props, this.state) });
    };

    private editDispatch = async (row : IDispatchView) => {
        this.setState({ editingDispatch: row });
    };

    private editDispatchCancelled = () => {
        this.setState({ editingDispatch: undefined });
    };

    private isChecked = (id : number) => this.state.selectedStockRows?.some(x => x.id === id);

    private getCartonsFromStockLines = (stockLines : Array<IStockLine>) => getCSVStockLineSummary(stockLines.filter(x => x.isActive && x.cartons > 0))
        .map(x => x.cartons).toString().replace(/,/g, ', ');

    private getTotalInnersFromStockLines = (stockLines : Array<IStockLine>) => getCSVStockLineSummary(stockLines.filter(x => x.isActive && x.totalInners && x.totalInners > 0))
        .map(x => x.totalInners).toString().replace(/,/g, ', ');

    private getCommoditiesFromStockLines = (stockLines : Array<IStockLine>) => lodash.uniq(stockLines.filter(x => x.isActive && x.cartons > 0)
        .map(x => getCommodityCode(x.commodityId))).toString().replace(/,/g, ', ');

    private getVarietiesFromStockLines = (stockLines : Array<IStockLine>) => lodash.uniq(stockLines.filter(x => x.isActive && x.cartons > 0)
        .map(x => getVarietyCode(x.varietyId))).toString().replace(/,/g, ', ');

    private getColoursFromStockLines = (stockLines : Array<IStockLine>) => lodash.uniq(stockLines.filter(x => x.isActive && x.cartons > 0)
        .map(x => getColourCode(x.colourId))).toString().replace(/,/g, ', ');

    private getGradesFromStockLines = (stockLines : Array<IStockLine>) => lodash.uniq(stockLines.filter(x => x.isActive && x.cartons > 0)
        .map(x => getGradeCode(x.gradeId))).toString().replace(/,/g, ', ');

    private getPacksFromStockLines = (stockLines : Array<IStockLine>) => lodash.uniq(stockLines.filter(x => x.isActive && x.cartons > 0)
        .map(x => getPackCode(x.packId))).toString().replace(/,/g, ', ');

    private getSizesFromStockLines = (stockLines : Array<IStockLine>) => lodash.uniq(stockLines.filter(x => x.isActive && x.cartons > 0)
        .map(x => getSizeCode(x.sizeId))).toString().replace(/,/g, ', ');

    private onSubstituteClick = (row : IStock) => {
        this.setState({ substituteLine: true, selectedStock: row });
    };

    private onPromptYesClick = async () => {
        const dispatchLineId = this.state.selectedDispatchLineId;
        const stockId = this.state.selectedStock?.id;

        if (dispatchLineId && stockId) {
            const data : ISubstituteStock = {
                dispatchLineId,
                stockId,
            };

            try {
                this.setLoading(true);
                const res = await DispatchHttpService.substituteStock(data);

                if (res && res.data) {
                    dataSetDispatchView(res.data);
                    this.editDispatchLineCancelled();
                    generalShowSuccessSnackbar('Dispatch line successfully substituted.');
                    this.setLoading(false);
                }
            } catch (e) {
                generalShowErrorSnackbar('An error occurred substituting the dispatch line.');
                this.setLoading(false);
            }
        }
    };

    private onSubstitutePromptCancel = () => {
        this.setState({ substituteLine: false, selectedStock: undefined });
    };

    private getSelectableStockRows = (props : IDispatchInstructionDashboardProps, state : IDispatchInstructionDashboardState) => state.selectableStockRows;

    private getStocks = (props : IDispatchInstructionDashboardProps) => props.stocks;

    private getSubstitutableStockTableRows = createSelector([this.getSelectableStockRows], selectableStockRows => selectableStockRows);

    private getSubstitutableStockTablecolumns = () => [
        { title: 'Select', field: 'id', formatFunction: this.isChecked, width: 100,
            containerComponent: (row : IStock) => {
                return (
                    <IconButton
                        onClick={() => this.onSubstituteClick(row)}
                    >
                        <Icon className={'cpd'}>swap_horizontal_circle</Icon>
                    </IconButton>
                );
            },
        },
        { title: 'Pack Date', field: 'packDate', formatFunction: formatDateTimeToDateOnly, sortFunction: compareDate, enableSorting: true },
        { title: 'Barcode', field: 'barcode', enableSorting: true },
        { title: 'Age(Days)', field: 'createdOn', formatFunction: this.getAgeInDays, enableSorting: true },
        { title: 'Status', field: 'status', enableSorting: true },
        { title: 'Cartons', field: 'stockLines', formatFunction: this.getCartonsFromStockLines, enableSorting: true },
        { title: 'Inners', field: 'stockLines', width: 120, formatFunction: this.getTotalInnersFromStockLines, enableSorting: true },
        { title: 'Gross Weight (kg)', field: 'grossWeight', enableSorting: true },
        { title: 'Channel', field: 'channel', enableSorting: true },
        { title: 'Commodities', field: 'stockLines', formatFunction: this.getCommoditiesFromStockLines, enableSorting: true },
        { title: 'Varieties', field: 'stockLines', formatFunction: this.getVarietiesFromStockLines, enableSorting: true },
        { title: 'Colours', field: 'stockLines', formatFunction: this.getColoursFromStockLines, enableSorting: true },
        { title: 'Grades', field: 'stockLines', formatFunction: this.getGradesFromStockLines, enableSorting: true },
        { title: 'Packs', field: 'stockLines', formatFunction: this.getPacksFromStockLines, enableSorting: true },
        { title: 'Sizes', field: 'stockLines', formatFunction: this.getSizesFromStockLines, enableSorting: true },
    ];

    private editDispatchLine = async (dispatchLine : IDispatchInstructionLine) => {
        const selectableStockRows = this.getSelectableStock(dispatchLine.currentStockId);
        this.setState({ substituteDispatchLine: true, selectedDispatchLineId: dispatchLine.id, dispatchLineToSubstitute: dispatchLine, selectableStockRows });
    };

    private editDispatchLineCancelled = () => {
        this.setState({ substituteLine: false, substituteDispatchLine: false, selectedStock: undefined, selectableStockRows: [] });
    };

    private getAgeInDays = (createdOn ?: string) => createdOn ? moment({ hours: 0 }).diff(createdOn, 'days') : -1;

    private onFileUploadDownloadOpen = async (dispatch : IDispatchView) => {
        this.setState({ isFileUploadDownloadDialogOpen: true, selectedDispatchView: dispatch });
        this.setLoading(true);
        // checks if indexedDB is available.
        const isIndexedDBAvailable = !!self.indexedDB ? true : false;
        try {
            const res = await DispatchHttpService.getDispatchSummaryData(dispatch.id, !isIndexedDBAvailable);

            if (res) {
                this.setState({ selectedDispatch: res.data.dispatch });
                dataSetDispatchSummaryRelatedData(res.data);
                this.setLoading(false);
            }
        } catch (e) {
            generalShowErrorSnackbar('Failed to load dispatch data');
        }
    };

    private onFileUploadDownloadClose = () => {
        this.setState({ isFileUploadDownloadDialogOpen: false, selectedDispatch: undefined });
    };

    private showEmailForm = () => {
        this.setState({ isEmailFormOpen: true });
    };

    private closeEmailForm = () => {
        this.setState({ isEmailFormOpen: false, includeOrchardsOnPOFile: false });
    };

    private showConfirmationPrompt = (onYesClick : () => void, onNoClick : () => void, message : string) => {
        this.setState({ onYesClick, onNoClick, message }, () => {
            this.setState({ showPrompt: true });
        });
    };

    private closeConfirmationPrompt = () => {
        this.setState({ onYesClick: () => null, onNoClick: () => null, message: '', showPrompt: false });
    };

    private onPOFileOrchardConfirmClick = async (action : 'Upload' | 'Download' | 'Email') => {
        this.setState({ isPOFileOrchardConfirmDialogOpen: true, poFileAction: action });
    };

    private closePOFileOrchardConfirmDialog = async () => {
        this.setState({ isPOFileOrchardConfirmDialogOpen: false, poFileAction: '' });
    };

    private onPOFileOrchardConfirmYesClick = async () => {
        const poFileActionType = this.state.poFileAction;

        if (poFileActionType !== '') {
            if (poFileActionType === 'Upload') {
                this.onUploadPOFileYesClick(true);
            } else if (poFileActionType === 'Email') {
                this.setState({ includeOrchardsOnPOFile: true });
                this.closePOFileOrchardConfirmDialog();
                this.showEmailForm();
            }

            this.closePOFileOrchardConfirmDialog();
        }
    };

    private onPOFileOrchardConfirmNoClick = async () => {
        const poFileActionType = this.state.poFileAction;

        if (poFileActionType !== '') {
            if (poFileActionType === 'Upload') {
                this.onUploadPOFileYesClick(false);
            } else if (poFileActionType === 'Email') {
                this.setState({ includeOrchardsOnPOFile: false });
                this.closePOFileOrchardConfirmDialog();
                this.showEmailForm();
            }

            this.closePOFileOrchardConfirmDialog();
        }
    };

    private onUploadPOFileYesClick = async (includeOrchards : boolean) => {
        const selectedDispatchView = this.state.selectedDispatchView;
        const selectedDispatchInstruction = this.state.selectedDispatch;
        // const dispatchLineStockIds : Array<number> = selectedDispatchInstruction?.dispatchLines?.filter(x => x.isActive).map(x => x.currentStockId) ?? [];
        // const dispatchStocks  = this.props.stocks.filter(x => x.isActive && dispatchLineStockIds.some(y => y === x.id));

        // if ((this.props.farms.find(y => uniq(flatten(dispatchStocks?.map(z => z?.stockLines?.map(a => a.farmId)))).some(z => z === y.id))?.ftpDetailIds?.length ?? 0) < 1) {
        //     generalShowErrorSnackbar('No farms on this dispatch have ftp details');
        //     return;
        // }
        if (!!selectedDispatchInstruction && !!selectedDispatchView) {
            this.setLoading(true);

            try {
                const data : IPOFileUpload = {
                    dispatchId: selectedDispatchView.id,
                    versionNumber: VERSION.version,
                    includeOrchards,
                };
                const res = await FTPHttpService.uploadPOFile(data);

                if (res && res.data) {
                    const updatedDispatchView : IDispatchView = {
                        ...selectedDispatchView,
                        poUploaded: true,
                    };
                    dataSetDispatchView(updatedDispatchView);

                    const updatedDispatchInstruction : IDispatchInstruction = {
                        ...selectedDispatchInstruction,
                        poUploaded: true,
                    };
                    dataSetDispatchInstruction(updatedDispatchInstruction);

                    generalShowSuccessSnackbar('PO file successfully uploaded');
                }
            } catch (ex) {
                generalShowErrorSnackbar('Failed to upload PO file');
            } finally {
                this.setLoading(false);
            }
        }
    };

    private onUploadTeraokaCSVYesClick = async () => {
        this.setLoading(true);
        await this.submitDispatchCSVUpload(this.state.selectedDispatchView?.id ?? 0);
        this.setLoading(false);
        this.closeConfirmationPrompt();
    };

    private getSiteCodeAndGuid = (id ?: number) => {
        const sites = this.props.sites;
        const site = sites.find(x => x.id === id);
        if (site) {
            return site.code + '_' + site.guid;
        }
    };

    private onDispatchNoteUpload = async () => {
        const files = this.state.selectedFiles;
        const selectedDispatch = this.state.selectedDispatch;
        const data : Array<IGoogleCloudStorage> = [];
        if (files.length > 0 && selectedDispatch) {
            this.setLoading(true);
            files.forEach((file) => {
                const reader = new FileReader();
                reader.onload = async () => {
                    const fileStr = reader.result;
                    if (typeof fileStr === 'string') {
                        const fileString = fileStr.substr(5);
                        const contentType = fileString.split(';')[0];
                        const startFromPos = fileString.split(',')[0].length;
                        const fileBase64 = fileString.substr((startFromPos + 1));

                        const fileToBeUploaded : IGoogleCloudStorage = {
                            fileName: file?.name,
                            destinationPath: `DispatchNotes/${this.getSiteCodeAndGuid(selectedDispatch?.sourceSiteId)}/${selectedDispatch.dispatchCode}/`,
                            contentType,
                            fileBase64,
                        };

                        data.push(fileToBeUploaded);

                        if (data.length === files.length) {
                            try {
                                const res = await GoogleCloudStorageHttpService.uploadFile(data);
                                if (res) {
                                    this.closeDispatchNoteUploadDialog();
                                    generalShowSuccessSnackbar('File uploaded successfully.');
                                    this.setLoading(false);
                                }
                            } catch (e) {
                                this.closeDispatchNoteUploadDialog();
                                generalShowErrorSnackbar('Error while uploading file.');
                                this.setLoading(false);
                            }
                        }
                    }
                };
                reader.readAsDataURL(file);
            });
        }
    };

    private onFileRemove = (file : File) => {
        const index = this.state.selectedFiles?.findIndex(x => x.name === file.name);
        if (index > -1) {
            this.setState({ selectedFiles: removeArrayElement(this.state.selectedFiles, index) });
        }
    };

    private handleFileInputChange = (files : Array<File>) => {
        files.forEach(x => this.setState({ selectedFiles: addArrayElement(this.state.selectedFiles, x, 'end') }));
    };

    private openDispatchNoteUploadDialog = () => {
        this.setState({ openManualUploadDialog: true });
    };

    private closeDispatchNoteUploadDialog = () => {
        this.setState({ openManualUploadDialog: false, selectedFiles: [] });
    };

    public onResetTrip = async (formValues : ITripFormValues, formikActions : FormikActions<ITripFormValues>) => {
        formikActions.resetForm();
        this.tripDialogClosed();
    };

    public onEmailReset = async (formValues : IEmailFormValues, formikActions : FormikActions<IEmailFormValues>) => {
        formikActions.resetForm();
        this.closeEmailForm();
    };

    private onEmailSubmit = async (value : IEmailFormValues) => {
        if (this.state.selectedDispatch) {
            try {
                const data : IPOFileEmail = {
                    dispatchId: this.state.selectedDispatch.id,
                    versionNumber: VERSION.version,
                    emailData: new Email(value, []),
                    includeOrchards: this.state.includeOrchardsOnPOFile,
                };

                const res = await EmailHttpService.emailPOFile(data);

                if (res) {
                    generalShowSuccessSnackbar('PO file successfully emailed');
                }

                this.closeEmailForm();
                const dispatch = { ...this.state.selectedDispatch };
                dispatch.poEmailed = true;
                this.setState({ selectedDispatch: dispatch });
                dataSetDispatchInstruction(dispatch);
                this.closeEmailForm();
            } catch (e) {
                generalShowErrorSnackbar('An error occurred while emailing the PO file.');
            } finally {
                this.setLoading(false);
            }
        }
    };

    private getInitialFormValues = createSelector(
        [this.getSelectedTrip, this.getCarriers, this.getTruckTypes, this.getTripLoadDate, this.getDispatchInstructions, this.getStocks],
        (trip, carriers, truckTypes, tripLoadDate, dispatches, stocks) => {
            const tripDispatches = dispatches.filter(x => x.tripId === trip?.id);
            return new TripFormValues(trip, carriers, truckTypes, tripLoadDate, tripDispatches, undefined, stocks);
        },
    );

    private getTripsFiltered = createSelector(
        [this.getTrips, this.getTripLoadDate],
        (trips, tripLoadDate) => {
            if (!trips) return;

            return lodash.filter(trips, x => x.isActive && moment(x.loadDate, DATEPICKER_FORMAT_DEFAULT).format(DATEPICKER_FORMAT_DEFAULT).toString() ===
            moment(tripLoadDate, DATEPICKER_FORMAT_DEFAULT).format(DATEPICKER_FORMAT_DEFAULT).toString());
        },
    );

    private getSelectedFromDate = (state : IDispatchInstructionDashboardState) => state.selectedFromDate;
    private getSelectedToDate = (state : IDispatchInstructionDashboardState) => state.selectedToDate;

    private getSelectionRange = createSelector([this.getSelectedFromDate, this.getSelectedToDate], (from, to) => {
        return {
            startDate: from.toDate(),
            endDate: to.toDate(),
            key: 'selection',
        };
    });

    private getDateFilterValue = createSelector([this.getSelectedFromDate, this.getSelectedToDate], (from, to) => {
        return from.local(true).format(DATE_FORMAT_DEFAULT_NO_TIME) + ' - ' + to.local(true).format(DATE_FORMAT_DEFAULT_NO_TIME);
    });

    private closeTripDeleteConfirmationPopup = () => this.setState({ showTripDeleteConfirmationPrompt: false, deletingTrip: undefined });

    private renderListElement = React.memo(({ data, index, style } : React.PropsWithChildren<ListChildComponentProps>) => {
        const dispatchInstruction : IDispatchInstruction = data[index];
        if (!dispatchInstruction) return <div />;
        const status = dispatchInstruction.status;
        return (<Draggable index={index} draggableId={dispatchInstruction.id.toString()} key={`dispatch_${status}${dispatchInstruction.id.toString()}`}>
            {(dragProvided, dragSnapshot) => {
                const draggableContentProps : IDraggableContentProps = {
                    index,
                    dragProvided,
                    dragSnapshot,
                    filteredDispatches: data,
                    isClone: false,
                };
                return <div style={style}>{this.renderDraggableContent(draggableContentProps)}</div>;
            }}
        </Draggable>);
    });

    private getDraggableContentProps = (props : IDraggableContentProps) => props;

    private renderDraggableContent = createSelector([this.getDraggableContentProps], ({ index, filteredDispatches, dragProvided, dragSnapshot, isClone } : IDraggableContentProps) => {
        const dispatchInstruction : IDispatchView = filteredDispatches[index];
        const status = dispatchInstruction.status;
        const canDownload = DISPATCH_INSTRUCTION_CAN_DOWNLOAD_PO_FILE_STATUSSES.some(x => x === dispatchInstruction.status) || dispatchInstruction.status === 'Staged';
        if (!dispatchInstruction) return <div />;

        let width : number | undefined;

        if (dragSnapshot.isDragging) {
            const scrollArea = document.getElementById(`${status}_scrollArea`);
            width = (scrollArea?.getBoundingClientRect().width ?? 0) - 10;
        }
        return <div id={`dispatch_${status}${dispatchInstruction.id.toString()}${isClone ? '_clone' : ''}`}
            ref={dragProvided.innerRef}
            {...dragProvided.draggableProps}
            {...dragProvided.dragHandleProps}
        >
            <div
                className={`m5 PaperBorder mnh130 ${canDownload ? 'h200' : 'h175'} mxh200 zi1000 DispatchListItem${themeMode} ${dragSnapshot.isDragging ? 'posf' : 'fdc'} ${this.isDroppable(dispatchInstruction, dragSnapshot.isDragging, dragSnapshot.draggingOver) ? '' : 'curnd'}`}
                style={dragSnapshot.isDragging ? { width } : undefined}
            >
                <div className={'fdr aic p5'}
                    style={dispatchInstruction.tripId ? { backgroundColor: randomColor({
                        hue: 'random',
                        format: 'rgba',
                        alpha: materialTheme.custom.randomColor.alphaValue,
                        seed: dispatchInstruction.tripId * 1000000000 }) } : { backgroundColor: materialTheme.custom.panel.card.header }}>
                    <Typography variant={'subtitle2'} className={'fdc wfill'} >
                        <div className={'fdr'}>
                            <div className={'fdc'}>
                                <PackmanLink
                                    type={'transactions'}
                                    targetPage={'dispatch'}
                                    id={Number(dispatchInstruction.id)}
                                    text={`#${dispatchInstruction.id}`} />
                                <div className={'fs12 aic'}>{formatDateTimeToDateOnly(dispatchInstruction.loadDate)}</div>
                            </div>
                            <div className={'flx1'}/>
                            <div className={'fdc aic'}>
                                <div className={'fs12 aic'}>
                                    <PackmanLink
                                        type={'transactions'}
                                        targetPage={'dispatch'}
                                        id={Number(dispatchInstruction.id)}
                                        text={dispatchInstruction.dispatchCode} />
                                </div>
                                <div className={'aic'}>{getSiteShortDescription(dispatchInstruction.sourceSiteId) + ' - ' + getSiteShortDescription(dispatchInstruction.destinationSiteId)}</div>
                            </div>
                            <div className={'flx1'}/>
                        </div>
                    </Typography>
                    <IconButton className={`p0 h30 w30 ${(dispatchInstruction.status !== 'Dispatched' && dispatchInstruction.status !== 'Received') ? '' : 'dn'}`} onClick={() => this.editDispatch(dispatchInstruction)}>
                        <Icon fontSize={'small'}>edit</Icon>
                    </IconButton>
                    <CustomTooltip title={'Close'}>
                        <IconButton className={`p0 h30 w30 ${dispatchInstruction.status !== 'Received' ? '' : 'dn'}`}
                            onClick={() => this.deleteDispatch(dispatchInstruction)}
                            disabled={((dispatchInstruction.status === 'Dispatched') || (dispatchInstruction.status === 'Received'))
                                                && !this.hasDispatchCorrectionRight(this.props)}
                            onMouseDownCapture={(e : React.MouseEvent<HTMLButtonElement>) => e.stopPropagation()} >
                            <Icon fontSize={'small'}>delete</Icon>
                        </IconButton>
                    </CustomTooltip>
                </div>
                <Divider className={'wfill'} />
                <div className={'fdr aic flx1'}>
                    <div className={'fs12'}><FontAwesomeIcon icon={faPallet} className={'ml10 mr10 mb1 cgray3'} size={'lg'}/>{dispatchInstruction.pallets ? dispatchInstruction.pallets : 0}</div>
                    <div className={'flx1'}/>
                    <div className={'fs12'}><FontAwesomeIcon icon={faWeight} className={'mr10 mb1 cgray3'} size={'lg'}/>{`${dispatchInstruction?.palletsTotalWeight ? dispatchInstruction.palletsTotalWeight.toFixed(2) : '0'} kg`}</div>
                    <IconButton className={'ml10 p0 h30 w30'} onClick={() => this.infoDispatchOpened(dispatchInstruction)}>
                        <Icon fontSize={'small'} color={'primary'}>{'info'}</Icon>
                    </IconButton>
                </div>
                <Divider className={'wfill'} />
                {!!dispatchInstruction &&
                            <Form className={'p5'}>
                                <CustomSelect
                                    field={'tripId'}
                                    id={`trip_${dispatchInstruction.id}`}
                                    label={'Trip'}
                                    className={'curd wfill'}
                                    placeholder={'Select a Trip'}
                                    value={dispatchInstruction.tripId ? dispatchInstruction.tripId : 0}
                                    initialValue={dispatchInstruction.tripId ? dispatchInstruction.tripId : 0}
                                    options={this.tripOptions(dispatchInstruction.loadDate)}
                                    onValueChange={(tripId : number) => this.setTripId(dispatchInstruction, tripId)}
                                />
                            </Form>
                }
                <div className={`${canDownload ? 'fdr h30' : 'dn'}`}>
                    <div className={`${dispatchInstruction.isPrinted ? 'ml5' : 'dn'}`}>
                        <img height={30} src={`${getIconLocation()}/is_printed.svg`} />
                    </div>
                    <div className={'flx1'}/>
                    <div className={`fdr jcfe mr5 ${canDownload ? '' : 'dn'}`}>
                        {
                            canDownload &&
                                    <div>
                                        <IconButton className={'p0 h30 w30'} onClick={() => this.onFileUploadDownloadOpen(dispatchInstruction)}>
                                            <img height={20} src={`${getIconLocation()}/swap_arrow_vertical.svg`} />
                                        </IconButton>
                                    </div>
                        }
                    </div>
                </div>
            </div>
        </div>;
    });

    private getDispatch = (props : IDispatchInstructionDashboardProps, state : IDispatchInstructionDashboardState) => state.selectedDispatch;
    private getUserEmail = (props : IDispatchInstructionDashboardProps) => props.auth.session?.user?.email;
    private getSites = (props : IDispatchInstructionDashboardProps) => props.sites;
    private getFarms = (props : IDispatchInstructionDashboardProps) => props.farms;
    private getContactInfos = (props : IDispatchInstructionDashboardProps) => props.contactInfos;

    private generateEmail = createSelector([this.getDispatch, this.getUserEmail, this.getSites, this.getContactInfos, this.getFarms, this.getStocks], (dispatch, userEmail, sites, contactInfos, farms, stocks) => {
        const toAddresses = contactInfos.filter(x =>
            farms.find(y => uniq(flatten(stocks.map(z => z.stockLines.map(a => a.farmId)))).some(z => z === y.id))?.contactInfoIds?.some(y => y === x.id) && !!x.email && x.isActive)?.map(x => x.email ?? '');

        const ccAddresses : Array<string> = !!userEmail ? [userEmail] : [];

        const sourceSite = sites.find(x => x.id === dispatch?.sourceSiteId);
        const destinationSite = sites.find(x => x.id === dispatch?.destinationSiteId);

        const subject = `Packman v${VERSION.version} - PO File - ${dispatch?.dispatchCode} (${sourceSite?.shortDescription} - ${destinationSite?.shortDescription})`;

        const body = `Please find attached the PO File for dispatch ${dispatch?.dispatchCode}`;

        const files : Array<string> = ['PO File'];

        return new EmailFormValues(subject, body, toAddresses, ccAddresses, files);
    });

    private getDispatchesFilteredByBarcode = (props : IDispatchInstructionDashboardProps, state : IDispatchInstructionDashboardState) => state.dispatchesFilteredByBarcode;

    private getFilteredDispatches = createSelector(
        [this.getDispatchInstructions, this.getDispatchesFilteredByBarcode, this.getSelectedSiteIds],
        (dispatchViews : Array<IDispatchView>, dispatchesFilteredByBarcode : Array<IDispatchView>, selectedSiteIds) => {

            const dispatches = dispatchesFilteredByBarcode.length < 1 ? dispatchViews : dispatchesFilteredByBarcode;

            return dispatches.filter(x => x.isActive
                    && !!x.destinationSiteId
                    && !!x.sourceSiteId
                    && (selectedSiteIds?.some(y => y === x.sourceSiteId)));
        },
    );

    private getFilteredIncomingDispatches = createSelector(
        [this.getDispatchInstructions, this.getDispatchesFilteredByBarcode, this.getSelectedSiteIds],
        (dispatchViews : Array<IDispatchView>, dispatchesFilteredByBarcode : Array<IDispatchView>, selectedSiteIds) => {

            const dispatches = dispatchesFilteredByBarcode.length < 1 ? dispatchViews : dispatchesFilteredByBarcode;

            return dispatches.filter(x => x.isActive
                    && !!x.destinationSiteId
                    && !!x.sourceSiteId
                    && (selectedSiteIds?.some(y => y === x.destinationSiteId)))
                .sort((a, b) => compareNumber(b.id, a.id));
        },
    );

    private infoDispatchClosed = () => this.setState({ showInfoDispatch: undefined, isDispatchInfoPopupOpen: false });
    private infoDispatchOpened = async (selectedDispatch : IDispatchView) => {
        this.setState({ isDispatchInfoPopupOpen: true, showInfoDispatch: selectedDispatch });
    };

    private getSelectedDispatchView = (props : IDispatchInstructionDashboardProps, state : IDispatchInstructionDashboardState) => state.selectedDispatchView;

    private getFlags = createSelector(
        [this.getSelectedDispatchView],
        (selectedDispatchView) => {
            return (
                <div className={'fdc aic w200 pl10 pr10 mb20'}>
                    <div className={'fdr wfill jcsb mb5'}>
                        <Typography className={'fs14'}>PO Uploaded?</Typography>
                        <BooleanFlag value={selectedDispatchView?.poUploaded ?? false}/>
                    </div>
                    <div className={'fdr wfill jcsb mb5'}>
                        <Typography className={'fs14'}>PO Emailed?</Typography>
                        <BooleanFlag value={selectedDispatchView?.poEmailed ?? false}/>
                    </div>
                    <div className={'fdr wfill jcsb mb5'}>
                        <Typography className={'fs14'}>Teraoka Uploaded?</Typography>
                        <BooleanFlag value={selectedDispatchView?.teraokaUploaded ?? false}/>
                    </div>
                </div>
            );
        },
    );

    private disableDay = (day : any) => {
        const today = formatMomentToDatePicker(moment().utc().startOf('day'));
        const dates = this.props.trips.filter(x => x.isActive && moment(x.loadDate).format(DATEPICKER_FORMAT_DEFAULT) === moment(day).format(DATEPICKER_FORMAT_DEFAULT));
        if (this.props.trips.length > 0) {
            if (dates.length > 0) {
                return false;
            } else {
                return true;
            }
        } else if (moment(day).format(DATEPICKER_FORMAT_DEFAULT) === today) {
            return false;
        } else {
            return true;
        }
    };

    private isTripEditEnabled = (trip : ITrip, hasTripEditingRight : boolean, hasTripSuperEditingRight : boolean) => {
        const currentUser = this.props.auth.session?.user;

        if (!!currentUser && !!hasTripEditingRight && !hasTripSuperEditingRight && trip.createdBy === currentUser.id) {
            return true;
        } else if (!!hasTripEditingRight && !!hasTripSuperEditingRight) {
            return true;
        } else {
            return false;
        }

    };

    private getIsIncomingDispatchPanelExpanded = (props : IDispatchInstructionDashboardProps, state : IDispatchInstructionDashboardState) => state.isIncomingDispatchPanelExpanded;

    private expandIncomingDispatchPanel = () => {
        this.setState({ isIncomingDispatchPanelExpanded: true });
    };

    private collapseIncomingDispatchPanel = () => {
        this.setState({ isIncomingDispatchPanelExpanded: false });
    };

    private incomingDispatches = createSelector(
        [this.getFilteredIncomingDispatches, this.getIsIncomingDispatchPanelExpanded],
        (dispatches, isIncomingDispatchPanelExpanded) => {
            return (
                <StatusColumnDiv style={{ backgroundColor: materialTheme.custom.panel.background }} pose={!isIncomingDispatchPanelExpanded ? 'collapsed' : 'expanded'} className={'PaperBorder fdc flx1 hfill m5'} key={'Incoming'}>
                    <IconButton style={{ height: 40, width: 40, paddingTop: 8 }} onClick={this.expandIncomingDispatchPanel} className={!isIncomingDispatchPanelExpanded ? '' : 'dn'}><Icon>chevron_right</Icon></IconButton>
                    <div className={`${!isIncomingDispatchPanelExpanded && dispatches.length > 0 ? 'h30 w30 brh jcc aic mt10 ml5 fw500 cw bcp' : 'dn'}`}>{dispatches.length}</div>
                    <div className={!isIncomingDispatchPanelExpanded ? 'flx1' : 'dn'}/>
                    <Typography className={`pl10 pr10 aic fdr ${!isIncomingDispatchPanelExpanded ? 'VerticalText' : 'bcp cw'}`} variant={'subtitle1'}>
                        {'Incoming'}
                        <div className={'flx1'}/>
                        <IconButton className={!isIncomingDispatchPanelExpanded ? 'dn' : 'cw'} onClick={this.collapseIncomingDispatchPanel}><Icon>chevron_left</Icon></IconButton>
                    </Typography>
                    <div className={!isIncomingDispatchPanelExpanded ? 'flx1' : 'dn'}/>
                    {!!isIncomingDispatchPanelExpanded  &&
                        <WindowList
                            itemCount={dispatches.length}
                            width={'100%'}
                            height={1000}
                            itemSize={ITEM_SIZE_CAN_NOT_DOWNLOAD}
                            itemData={dispatches}
                            className={'hidescroll'}
                        >
                            {this.renderIncomingDispatchListElement}
                        </WindowList>
                    }
                </StatusColumnDiv>
            );
        },
    );

    private renderIncomingDispatchListElement = React.memo(({ data, index, style } : React.PropsWithChildren<ListChildComponentProps>) => {
        const dispatchInstruction : IDispatchView = data[index];
        const status = dispatchInstruction.status;
        if (!dispatchInstruction) return <div />;
        return (
            <div style={style} id={`dispatch_${status}${dispatchInstruction.id.toString()}`}>
                <div className={`m5 PaperBorder mnh130 flx1 zi1000 DispatchListItem${themeMode} fdc curnd`}>
                    <div className={'fdr aic p5'}
                        style={dispatchInstruction.tripId ? { backgroundColor: randomColor({
                            hue: 'random',
                            format: 'rgba',
                            alpha: materialTheme.custom.randomColor.alphaValue,
                            seed: dispatchInstruction.tripId * 1000000000 }) } : { backgroundColor: materialTheme.custom.panel.card.header }}>
                        <Typography variant={'subtitle2'} className={'fdc wfill'} >
                            <div className={'fdr'}>
                                <div className={'fdc'}>
                                    <div className={'cpd fs12 aic'}>#{dispatchInstruction.id}</div>
                                    <div className={'cpd fs12 aic'}>{formatDateTimeToDateOnly(dispatchInstruction.loadDate)}</div>
                                </div>
                                <div className={'flx1'}/>
                                <div className={'fdc aic'}>
                                    <div className={'cpd fs12 aic'}>{dispatchInstruction.dispatchCode}</div>
                                    <div className={'cpd aic'}>{getSiteShortDescription(dispatchInstruction.sourceSiteId) + ' - ' + getSiteShortDescription(dispatchInstruction.destinationSiteId)}</div>
                                </div>
                                <div className={'flx1'}/>
                            </div>
                        </Typography>
                    </div>
                    <Divider className={'wfill mb5'} />
                    <div className={'fdr aic'}>
                        <div className={'fs12'}><FontAwesomeIcon icon={faPallet} className={'ml10 mr10 mb1 cgray3'} size={'lg'}/>{dispatchInstruction.pallets ? dispatchInstruction.pallets : 0}</div>
                        <div className={'flx1'}/>
                        <div className={'fs12'}><FontAwesomeIcon icon={faWeight} className={'mr10 mb1 cgray3'} size={'lg'}/>{`${dispatchInstruction?.palletsTotalWeight ? dispatchInstruction.palletsTotalWeight.toFixed(2) : '0'} kg`}</div>
                        <IconButton className={'ml10 p0 h30 w30'} onClick={() => this.infoDispatchOpened(dispatchInstruction)}>
                            <Icon fontSize={'small'} color={'primary'}>{'info'}</Icon>
                        </IconButton>
                    </div>
                    <Divider className={'wfill mt5 mb5'} />
                    <Form className={'p5'}>
                        <CustomSelect
                            field={'tripId'}
                            id={`trip_${dispatchInstruction.id}`}
                            label={'Trip'}
                            className={'curd wfill'}
                            placeholder={'Select a Trip'}
                            value={dispatchInstruction.tripId ? dispatchInstruction.tripId : 0}
                            initialValue={dispatchInstruction.tripId ? dispatchInstruction.tripId : 0}
                            options={this.tripOptions(dispatchInstruction.loadDate)}
                            onValueChange={(tripId : number) => this.setTripId(dispatchInstruction, tripId)}
                            disabled={true}
                        />
                    </Form>
                </div>
            </div>
        );
    });

    public render() {
        const hasTripEditingRight = this.hasTripEditingRight(this.props);
        const hasTripSuperEditingRight = this.hasTripSuperEditingRight(this.props);
        const initialValues = this.getInitialFormValues(this.props, this.state);
        const trips = this.getTripsFiltered(this.props, this.state);
        const dispatches = this.getFilteredDispatches(this.props, this.state);

        const { collapseStatusColumn, isPOFileOrchardConfirmDialogOpen } = this.state;

        return (
            <Screen isPadded={false} isScrollable={false} isLoading={this.state.isLoading}>
                <div className={'fdc hfill p10'}>
                    <div className={'fdr jcfe mt10 mb10'}>
                        <div className={'flx1'}/>
                        <TextField fullWidth className={'w250'} variant='standard' label={'Filter By Barcode'} value={this.state.barcodeSearchValue} onChange={this.barcodeSearchValueChanged}/>
                        <CustomTooltip title={'Search remote for barcode'}>
                            <IconButton className={'mt20 mr10 h20 w20'} onClick={this.getDispatchByBarcode}><Icon>search</Icon></IconButton>
                        </CustomTooltip>
                        <div className={'w10'}/>
                        <TransactionFilter className={'pr15'} selectedFromDate={this.state.selectedFromDate} selectedToDate={this.state.selectedToDate} handleDateRangeChange={this.handleDateRangeChange} onApplyClick={this.onApplyClick} />
                    </div>
                    <div className={'fdr'}>
                        <div className={'fdr flx1 h80vh'}>
                            {this.incomingDispatches(this.props, this.state)}
                            <DragDropContext onDragEnd={this.onDragEnd}>
                                { DISPATCH_INSTRUCTION_STATUSSES.map((status) => {
                                    const canDownload = DISPATCH_INSTRUCTION_CAN_DOWNLOAD_PO_FILE_STATUSSES.some(x => x === status) || status === 'Staged';
                                    const filteredDispatches = dispatches.filter(x => x.status === status);
                                    return <StatusColumnDiv style={{ backgroundColor: materialTheme.custom.panel.background }} pose={collapseStatusColumn[status] ? 'collapsed' : 'expanded'} className={'PaperBorder fdc flx1 hfill m5'} key={status}>
                                        <IconButton onClick={() => this.expandColumn(status)} className={collapseStatusColumn[status] ? 'h40 w40 pt8' : 'dn'}><Icon>chevron_right</Icon></IconButton>
                                        <div className={`${collapseStatusColumn[status] && filteredDispatches.length > 0 ? 'h30 w30 brh jcc aic mt10 ml5 fw500 cw bcp' : 'dn'}`}>{filteredDispatches.length}</div>
                                        <div className={collapseStatusColumn[status] ? 'flx1' : 'dn'}/>
                                        <Typography className={`pl10 pr10 aic fdr ${collapseStatusColumn[status] ? 'VerticalText' : 'bcp cw'}`} variant={'subtitle1'}>
                                            {status}
                                            <div className={'flx1'}/>
                                            <IconButton className={collapseStatusColumn[status] ? 'dn' : 'cw'} onClick={() => this.collapseColumn(status)}><Icon className={collapseStatusColumn[status] ? 'dn' : 'cw'}>chevron_left</Icon></IconButton>
                                        </Typography>
                                        <div className={collapseStatusColumn[status] ? 'flx1' : 'dn'}/>
                                        {!collapseStatusColumn[status]  && <Droppable droppableId={status}
                                            mode={'virtual'}
                                            renderClone={(dragProvided, dragSnapshot, rubric) => {
                                                const index = rubric.source.index;
                                                const draggableContentProps : IDraggableContentProps = {
                                                    index,
                                                    dragProvided,
                                                    dragSnapshot,
                                                    filteredDispatches,
                                                    isClone: true,
                                                };
                                                return this.renderDraggableContent(draggableContentProps) ?? <div/>;
                                            }}>
                                            {(dropProvided, dropSnapshot) =>
                                                <div id={`${status}_scrollArea`} onScroll={e => this.columnScrolled(e, status)} className={`fdc flx1 oya oxh hidescroll ${collapseStatusColumn[status] ? 'dn' : ''}`} style={this.getDroppableStyle(status, dropSnapshot)} ref={dropProvided.innerRef} {...dropProvided.droppableProps}>
                                                    { filteredDispatches.length > 0 &&
                                                        <WindowList
                                                            itemCount={filteredDispatches.length}
                                                            width={'100%'}
                                                            height={1000}
                                                            itemSize={canDownload ? ITEM_SIZE_CAN_DOWNLOAD : ITEM_SIZE_CAN_NOT_DOWNLOAD}
                                                            itemData={filteredDispatches}
                                                        >
                                                            {this.renderListElement}
                                                        </WindowList>
                                                    }
                                                </div>}
                                        </Droppable>}
                                    </StatusColumnDiv>;
                                })}
                            </DragDropContext>
                        </div>
                        <div style={{ height: 'calc(80vh)' }}>
                            {/* Trips Section */}
                            <div style={{ backgroundColor: materialTheme.custom.panel.background }} className={'PaperBorder w250 fdc hfill mr15 mt5 ml5 mb5'} key={'Trips'}>
                                <div className={'flx1'}/>
                                <Typography className={'mnh48 pl10 pr10 aic fdr bcp cw'} variant={'subtitle1'}>
                                    {'Trips'}
                                    <div className={'flx1'}/>
                                </Typography>
                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                    <DatePicker
                                        label={'Load Date'}
                                        format={DATEPICKER_FORMAT_DEFAULT}
                                        className={'m10'}
                                        value={moment(this.state.tripLoadDate)}
                                        shouldDisableDate={this.disableDay}
                                        onChange={(e : any) => {
                                            this.setState({
                                                tripLoadDate: moment(e, DATEPICKER_FORMAT_DEFAULT).format(DATEPICKER_FORMAT_DEFAULT).toString(),
                                                newTripDate: moment(e, DATEPICKER_FORMAT_DEFAULT).format(DATEPICKER_FORMAT_DEFAULT).toString(),
                                            });
                                        }}
                                    />
                                </LocalizationProvider>
                                <List className={'oya hfill oxh hidescroll'}>
                                    {trips?.map(trip => <div key={'trip_' + trip.id}
                                        className={'PaperBorder fdc m5'}
                                        style={trip.id ? { backgroundColor: randomColor({
                                            hue: 'random',
                                            format: 'rgba',
                                            alpha: materialTheme.custom.randomColor.alphaValue,
                                            seed: trip.id * 1000000000 }) } : { backgroundColor: materialTheme.custom.panel.card.header }}
                                    >
                                        <div className={'fdr aic'} >
                                            <div className={'fs12 aic pl10'}>#{trip.id}</div>
                                            <div className={'flx1'} />
                                            <IconButton className={!!this.isTripEditEnabled(trip, hasTripEditingRight, hasTripSuperEditingRight) ? 'p0 h30 w30' : 'dn'} onClick={() => this.editTrip(trip)}><Icon fontSize={'small'}>edit</Icon></IconButton>
                                            <IconButton className={'p0 h30 w30'} onClick={() => this.deleteTrip(trip)}><Icon fontSize={'small'}>delete</Icon></IconButton>
                                        </div>
                                        <div className={'pt5'}>
                                            <div className={'fdr aic'}>
                                                <div className={'fs12'}><FontAwesomeIcon icon={faPallet} className={'ml10 mr10 mb1'} size={'lg'}/>{`${this.getTripPallets(trip.id)}`}</div>
                                                <div className={'flx1'}/>
                                                <div className={'fs12 pr10'}><FontAwesomeIcon icon={faWeight} className={'mr10 mb1'} size={'lg'}/>{`${this.getTripWeight(trip.id).toFixed(3)} kg`}</div>
                                            </div>
                                            <Divider className={'wfill mt5 mb5 bcpd'} />
                                            <div className={'fdr'} >
                                                <div className={'pl10 pr10 fs12'}>Description: {trip.description}</div>
                                            </div>
                                            <div className={'fdr'} >
                                                <div className={'pl10 pr10 fs12'}>Driver: {trip.driver}</div>
                                            </div>
                                            <div className={'fdr'} >
                                                <div className={'pl10 pr10 fs12'}>Container: {trip.container}</div>
                                            </div>
                                            <div className={'fdr'} >
                                                <div className={'pl10 pr10 fs12'}>Container Tare Weight: {trip.containerTareWeight}</div>
                                            </div>
                                            <div className={'fdr'} >
                                                <div className={'pl10 pr10 fs12'}>Reg Nr: {trip.registrationNumber}</div>
                                            </div>
                                            <div className={'fdr'} >
                                                <div className={'pl10 pr10 fs12'}>Fleet Nr: {trip.fleetNumber}</div>
                                            </div>
                                            <div className={'fdr'} >
                                                <div className={'pl10 pr10 fs12'}>Carrier: {this.getCarrierName(trip.carrierId)}</div>
                                            </div>
                                            <div className={'fdr mb5'} >
                                                <div className={'pl10 pr10 fs12'}>Devices: {this.getDevices(trip.temperatureUnits)}</div>
                                            </div>
                                        </div>
                                    </div>)}
                                </List>
                                <div className={'fdr p10'}>
                                    <div className={'flx1'} />
                                    <FloatingActionButton
                                        color={'secondary'}
                                        size={'medium'}
                                        onClick={this.addNewTrip}
                                        disabled={this.state.isLoading}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    {this.state.showDispatchDeleteConfirmationPrompt &&
                        <DeleteConfirmationDialog
                            isLoading={this.state.isLoading}
                            onSubmit={this.dispatchDeleteConfirmed}
                            onclose={this.closeDeleteConfirmationPopup}
                            isOpen={this.state.showDispatchDeleteConfirmationPrompt}
                            title={'Dispatch Instruction'}/>
                    }
                    {this.state.showTripDeleteConfirmationPrompt &&
                        <DeleteConfirmationDialog
                            isLoading={this.state.isLoading}
                            onSubmit={this.tripDeleteConfirmed}
                            onclose={this.closeTripDeleteConfirmationPopup}
                            isOpen={this.state.showTripDeleteConfirmationPrompt}
                            title={'Trip'}/>
                    }
                    {/* Trip Edit Dialog */}
                    { (!!this.state.editingTrip || !!this.state.isAddingTrip) &&
                        <PackmanDialog
                            title={'Trip'}
                            isEdit={!!this.state.editingTrip}
                            isLoading={this.state.isLoading}
                            isOpen={!!this.state.isAddingTrip || !!this.state.editingTrip}
                            onClose={this.tripDialogClosed}>
                            <Formik
                                initialValues={initialValues}
                                onSubmit={this.onTripSubmit}
                                onReset={this.onResetTrip}
                                enableReinitialize
                                validationSchema={TripFormValues.formSchema}
                                component={TripForm}/>
                        </PackmanDialog >
                    }
                    {/* Dispatch Edit Dialog */}
                    { !!this.state.editingDispatch &&
                        <DispatchInstructionEdit
                            isLoading={this.state.isLoading}
                            setLoading={this.setLoading}
                            selectedDispatchView={this.state.editingDispatch}
                            editDispatchCancelled={this.editDispatchCancelled}
                            refreshData={() => this.refreshData(true)}
                        />
                    }
                    {/* Substitute Dialog */}
                    { !!this.state.substituteDispatchLine &&
                        <PackmanDialog
                            title={'Substitute Line ' + this.getStockBarcode(this.state.dispatchLineToSubstitute?.currentStockId ?? 0)}
                            isInfo
                            isLoading={this.state.isLoading}
                            fullWidth
                            maxWidth={'lg'}
                            isOpen={!!this.state.substituteDispatchLine}
                            onClose={this.editDispatchLineCancelled}>
                            <div className={'p20 fdc'}>
                                <div className={'wfill'}>
                                    <CustomTable<IStock>
                                        enableSorting
                                        columns={this.getSubstitutableStockTablecolumns()}
                                        rows={this.getSubstitutableStockTableRows(this.props, this.state)}
                                        initialSortOrder={[{ columnName: 'packDate_Pack Date', direction : 'desc' }]}
                                        pageHeight={200}
                                    />
                                </div>
                            </div>
                        </PackmanDialog >
                    }
                    {/* Show Info Dialog */}
                    { !!this.state.isDispatchInfoPopupOpen &&
                        <PackmanDialog
                            title={'Dispatch Info ' + this.state.showInfoDispatch?.dispatchCode}
                            isInfo
                            isLoading={this.state.isLoading}
                            maxWidth={'lg'}
                            isOpen={!!this.state.isDispatchInfoPopupOpen}
                            onClose={this.infoDispatchClosed}>
                            {!!this.state.showInfoDispatch &&
                                <DispatchInstructionInfoPopup
                                    status={this.props.selectedSiteIds.some(x => x === this.state.showInfoDispatch?.destinationSiteId) ? 'Incoming' :  this.state.showInfoDispatch?.status}
                                    editDispatchLine={this.editDispatchLine}
                                    selectedDispatchView={this.state.showInfoDispatch}
                                    setLoading={this.setLoading}
                                />
                            }
                        </PackmanDialog >
                    }
                    { !!this.state.isFileUploadDownloadDialogOpen &&
                        <PackmanDialog
                            title={'SELECT ACTION' + ' - ' + this.state.selectedDispatchView?.dispatchCode + ` (${getSiteShortDescription(this.state.selectedDispatchView?.sourceSiteId) + ' - ' + getSiteShortDescription(this.state.selectedDispatchView?.destinationSiteId)})`}
                            isOpen={this.state.isFileUploadDownloadDialogOpen}
                            isLoading={this.state.isLoading}
                            isInfo
                            onClose={this.onFileUploadDownloadClose}
                            maxWidth={'lg'}
                        >
                            {this.state.isLoading &&
                                <div className={'posa post0 posr0 posb0 posl0 aic jcc zi1000'}>
                                    <div className={'posr aic jcc h50 w50'}>
                                        <div className={'posa post0 posr0 posb0 posl0 aic jcc'}>
                                            <img height={40} src={`${ASSET_BASE}/assets/images/ZZ2_Pallets.png`} />
                                        </div>
                                        <CircularProgress color={'primary'} className={'posa post0 posr0 posb0 posl0'} size={50} />
                                    </div>
                                </div>
                            }
                            <div className={'p20 fdc aic'}>
                                {this.getFlags(this.props, this.state)}
                                <PopupOptionButton
                                    text={'UPLOAD PO FILE'}
                                    icon={<CloudUploadIcon/>}
                                    disabled={this.state.isLoading}
                                    onClick={() => this.showConfirmationPrompt(() => this.onPOFileOrchardConfirmClick('Upload'), this.closeConfirmationPrompt, 'Are you sure you want to upload the PO file?')}
                                />
                                <PopupOptionButton
                                    text={'EMAIL PO FILE'}
                                    icon={<CloudUploadIcon/>}
                                    disabled={this.state.isLoading}
                                    onClick={() => this.onPOFileOrchardConfirmClick('Email')}
                                />
                                { this.hasUploadTeraokaRight(this.props) &&
                                    <PopupOptionButton
                                        text={'UPLOAD TERAOKA CSV'}
                                        icon={<CloudUploadIcon/>}
                                        disabled={this.state.isLoading}
                                        onClick={() => this.showConfirmationPrompt(this.state.selectedDispatch?.teraokaUploaded
                                            ? () => this.showConfirmationPrompt(this.onUploadTeraokaCSVYesClick, this.closeConfirmationPrompt, 'The CSV file is already uploaded to Teraoka. Are you sure you want to upload it again?')
                                            : this.onUploadTeraokaCSVYesClick, this.closeConfirmationPrompt, 'Are you sure you want to upload the CSV file to Teraoka?')}
                                    />
                                }
                                <PopupOptionButton
                                    text={'UPLOAD DISPATCH FILES'}
                                    icon={<CloudUploadIcon/>}
                                    disabled={this.state.isLoading}
                                    onClick={this.openDispatchNoteUploadDialog}
                                />
                            </div>
                        </PackmanDialog>
                    }
                    {!!this.state.isEmailFormOpen &&
                        <PackmanDialog
                            title='Email PO File'
                            isEdit={true}
                            isLoading={this.state.isLoading}
                            isOpen={this.state.isEmailFormOpen}
                            onClose={this.closeEmailForm}>
                            <Formik
                                initialValues={this.generateEmail(this.props, this.state)}
                                isInitialValid={EmailFormValues.formSchema.isValidSync(this.generateEmail(this.props, this.state))}
                                onSubmit={this.onEmailSubmit}
                                onReset={this.onEmailReset}
                                enableReinitialize
                                validationSchema={EmailFormValues.formSchema}
                                component={EmailForm} />
                        </PackmanDialog >}
                    {/* Dispatch Files Upload Dialog */}
                    { !!this.state.openManualUploadDialog &&
                        <PackmanDialog
                            title={'Upload Dispatch files'}
                            isInfo
                            isLoading={this.state.isLoading}
                            isOpen={this.state.openManualUploadDialog}
                            onClose={this.closeDispatchNoteUploadDialog}>
                            <Screen isPadded={false} isScrollable={false}>
                                <div style={{ height: 500 }} className={'w400 aic fdc p20'}>
                                    <div style={{ height: 400 }} className={'wfill'}>
                                        <FileSelector
                                            accept={'image/*,application/pdf'}
                                            files={this.state.selectedFiles ? this.state.selectedFiles : []}
                                            disabled={this.state.isLoading}
                                            onFileSelected={this.handleFileInputChange}
                                            onFileRemoved={this.onFileRemove}
                                        />
                                    </div>
                                    <div className={'flx1'}/>
                                    <div className={'fdr pt10 pb10'}>
                                        <Button
                                            className={'fwb h35'}
                                            variant='text'
                                            color='primary'
                                            onClick={this.closeDispatchNoteUploadDialog}>
                                                Cancel
                                        </Button>
                                        <PillButton
                                            disabled={this.state.selectedFiles.length === 0}
                                            className={'ml15 pl20 pr20 h35'}
                                            text={'Upload File'}
                                            color={'secondary'}
                                            onClick={this.onDispatchNoteUpload}
                                        />
                                    </div>
                                </div>
                            </Screen>
                        </PackmanDialog >
                    }
                    {isPOFileOrchardConfirmDialogOpen &&
                        <PackmanDialog
                            title={'PO File Orchard Confirm'}
                            isInfo
                            maxWidth={'lg'}
                            isLoading={this.state.isLoading}
                            isOpen={this.state.isPOFileOrchardConfirmDialogOpen}
                            onClose={this.closePOFileOrchardConfirmDialog}>
                            <div className={'w500 aic fdc p20 oya'}>
                                <Typography className={'mb10'}>Should orchards be included?</Typography>
                                <div className={'fdc wfill jcfs'}>
                                    <Typography>Note: This is not recommended when dispatching to CORE.</Typography>
                                </div>
                                <div className={'fdr aife jcfe wfill '}>
                                    <Button className={'fwb h35'} variant='text' color='primary'
                                        onClick={this.onPOFileOrchardConfirmNoClick}>
                                        No
                                    </Button>
                                    <PillButton
                                        color={'secondary'}
                                        className={'ml15 pl20 pr20 h35'}
                                        text={'Yes'}
                                        onClick={this.onPOFileOrchardConfirmYesClick}
                                    />
                                </div>
                            </div>
                        </PackmanDialog >
                    }
                    <ConfirmationPrompt disableYes={this.state.isLoading} disableNo={this.state.isLoading} isLoading={this.state.isLoading} open={this.state.showPrompt} message={this.state.message}
                        onOkClicked={this.state.onYesClick} onCancelClicked={this.state.onNoClick}/>
                    <ConfirmationPrompt open={this.state.substituteLine} message={'Are you sure you want to substitute this dispatch line' }
                        onOkClicked={this.onPromptYesClick} onCancelClicked={this.onSubstitutePromptCancel}/>
                    <ConfirmationPrompt open={!!this.state.requestSiteConfirmation} message={'This export compliance has not been approved yet, but is being dispatched to a non export site. Continue?' }
                        onOkClicked={this.complianceMoveConfirmed} onCancelClicked={this.complianceMoveDeclined}/>
                    <ConfirmationPrompt open={!!this.state.newRequestedStatus && !!this.state.dispatchRequestedForMove} message={`Are you sure you want to move this dispatch to ${this.state.newRequestedStatus}?` }
                        onOkClicked={() => this.onStatusOverriden(true)} onCancelClicked={() => this.onStatusOverriden(false)} textColor={'darkorange'}/>
                </div>
            </Screen>
        );
    }
}

const mapStateToProps = (state : IRootState) => {
    return {
        dispatchViews: state.data.dispatchViews,
        auth: state.auth,
        dashboardDispatchInstructionLines: mapDashboardDispatchLines(state),
        selectedLoadDate: state.data.dashboardDispatchSelectedLoadDate,
        selectedDispatchIndex: state.data.dashboardDispatchSelectedDispatchInstructionId,
        showOnlyMyDispatches: state.data.dashboardShowOnlyMyDispatches,
        selectedSiteIds: state.data.selectedSiteIds,
        stocks: state.data.stocks,
        sites: state.masterData.sites,
        farms: state.masterData.farms,
        contactInfos: state.masterData.contactInfos,
        dispatches: state.data.dispatchInstructions,
        carriers: state.masterData.carriers,
        trips: state.data.trips,
        truckTypes: state.masterData.truckTypes,
        compliances: state.data.compliances,
    };
};

const mapDispatchToProps = (dispatcher : Dispatch<RootAction>) => bindActionCreators(
    {
        dataSetDashboardDispatchSelectedLoadDate,
        dataSetDashboardDispatchSelectedDispatchInstructionId,
        dataSetDashboardDispatchInstructionLines,
        dataSetStocks,
    },
    dispatcher,
);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(DispatchInstructionDashboard);
