import * as React from 'react';
import { IRootState, IAuthState, RootAction } from '../../@types/redux';
import { connect } from 'react-redux';
import { generalShowErrorSnackbar, generalShowInfoSnackbar, generalShowSuccessSnackbar } from '../../store/general/Functions';
import { dataSetAllStockRelatedData, dataSetCompliance, dataSetStock } from '../../store/data/Functions';
import { getCertificateStockLineSummary, addArrayElement, formatDateTimeToDateOnly, removeArrayElement, upsertArrayElement, compareNumber, booleanToYesNo } from '../../services/appFunctionsService';
import { Select, MenuItem, Dialog, Button, TextField, Typography, Icon, IconButton, CircularProgress, Tooltip } from '@mui/material';
import { COMPLIANCE_STATUSSES, DATE_FORMAT_CSV, TIME_FORMAT_CSV, DATE_FORMAT_DEFAULT, DATETIME_SECONDS_PALTRACK_FILE, PACKMAN_API_URL, PPECB_API_URL, TUM_API_URL } from '../../appConstants';
import { Droppable, Draggable, DragDropContext, DropResult, DroppableStateSnapshot, DraggableProvided, DraggableStateSnapshot } from '@react-forked/dnd';
import jQuery from 'jquery';
import moment from 'moment';
import CSVReader from '../../components/csv/CsvReader';
import { Document, Page, Text, View, StyleSheet, Font, Image } from '@react-pdf/renderer';
import Code39 from '../../fonts/code39.ttf';
import ComplianceManualInspection from './ComplianceManualInspection';
import ComplianceStockReplacement from './ComplianceStockReplacement';
import { ICompliance } from '../../@types/model/compliance/compliance';
import { ISelectedDispatchStock } from '../../@types/model/dispatch/selectedDispatchStock';
import ComplianceHorizontalAccordion, { colours } from './ComplianceHorizontalAccordion';
import SettingsIcon from '@mui/icons-material/Settings';
import { IComplianceLine } from '../../@types/model/compliance/complianceLine';
import ComplianceHttpService from '../../services/http/compliance/complianceHttpService';
import { buildPDF, exportFindingSheet, mapComplianceToConsignment, mapConsignmentsToELot } from '../../services/compliance/documentService';
import PageviewIcon from '@mui/icons-material/Pageview';
import { createSelector } from 'reselect';
import TransactionFilter from '../../components/filters/BasicTransactionScreenFilter';
import { deleteConsignment, getAgreementCode, getConsignmentResult, getConsignmentToken, getTURToken, updateConsignment, uploadConsignment, upsertELot, upsertTUMV3TradeUnit, validateConsignment } from '../../services/inspectionService';
import { IConsignmentStorage } from '../../@types/model/compliance/titan-TUR/consignmentStorage';
import Screen from '../../components/Screen';
import { ISite } from '../../@types/model/masterData/site/site';
import { IStock } from '../../@types/model/stock/stock';
import { IOrganization } from '../../@types/model/masterData/organization/organization';
import { IInventory } from '../../@types/model/masterData/inventory/inventory';
import { ISize } from '../../@types/model/masterData/size/size';
import { IFarm } from '../../@types/model/masterData/farm/farm';
import { ICommodity } from '../../@types/model/masterData/commodity/commodity';
import { IVariety } from '../../@types/model/masterData/variety/variety';
import { IPack } from '../../@types/model/masterData/pack/pack';
import { IMark } from '../../@types/model/masterData/mark/mark';
import { IGrade } from '../../@types/model/masterData/grade/grade';
import { IOrchard } from '../../@types/model/masterData/orchard/orchard';
import { IRegion } from '../../@types/model/masterData/region/region';
import { IInspectionPoint } from '../../@types/model/masterData/inspectionPoint/inspectionPoint';
import { IMarket } from '../../@types/model/masterData/market/market';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import PackmanDialog from '../../components/dialog/PackmanDialog';
import { ListChildComponentProps, FixedSizeList as WindowList } from 'react-window';
import { faPallet } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PillButton from '../../components/input/PillButton';
import { IGoogleCloudStorage } from '../../@types/model/googleCloudStorage/googleCloudStorage';
import GoogleCloudStorageHttpService from '../../services/googleCloudStorageService';
import FileSelector from '../../components/input/FileSelector';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import GetAppIcon from '@mui/icons-material/GetApp';
import PrintIcon from '@mui/icons-material/Print';
import InfoIcon from '@mui/icons-material/Info';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import SearchIcon from '@mui/icons-material/Search';
import PopupOptionButton from '../../components/button/PopupOptionButton';
import { navPath } from '../../store/nav/Actions';
import ComplianceWizard from './ComplianceWizard';
import { CustomChangeEvent, IOptionType } from '../../@types/helper';
import { IColour } from '../../@types/model/masterData/colour/colour';
import { EmailFormValues, IEmailFormValues } from '../../@types/model/email/emailFormValues';
import EmailForm from '../../components/email/EmailForm';
import { Formik, FormikActions } from 'formik';
import { VERSION } from '../../../version';
import { IContactInfo } from '../../@types/model/masterData/contactInfo/contactInfo';
import { IPalletBaseType, PalletBaseType } from '../../@types/model/masterData/palletBaseType/palletBaseType';
import { RouteComponentProps } from 'react-router';
import { IRight } from '../../@types/model/user/right';
import { IComplianceStatus } from '../../@types/model/compliance/complianceStatus';
import { ICountry } from '../../@types/model/masterData/country/country';
import { IConsignment } from '../../@types/model/compliance/titan-TUR/consignment';
import { IConsignmentResponse } from '../../@types/model/compliance/titan-TUR/consignmentResponse';
import { flatten, isBoolean, uniq } from 'lodash';
import { IPIFileEmail } from '../../@types/model/compliance/piFileEmail';
import { Email } from '../../@types/model/email/email';
import EmailHttpService from '../../services/http/integration/email/emailHttpService';
import { IPIFileUpload } from '../../@types/model/compliance/piFileUpload';
import FTPHttpService from '../../services/http/integration/ftp/ftpHttpService';
import FileSaver from 'file-saver';
import { Dispatch, bindActionCreators } from 'redux';
import SingleToggleButton from '../../components/input/SingleToggleButton';
import { IAgreementCode } from '../../@types/model/masterData/agreementCode/agreementCode';
import AutocompleteSelect from '../../components/input/AutoCompleteSelect';
import { PackmanLink } from '../../components/link/packmanLink';
import CustomTable from '../../components/table/CustomTable';
import ComplianceSummary from './ComplianceSummary';
import CustomTooltip from '../../components/tooltip/tooltip';
import ConsignmentHttpService from '../../services/http/consignment/consignmentHttpService';
import { syncMasterData } from '../../services/masterDataSyncService';
import { BooleanFlag } from '../../components/label/BooleanFlag';
import materialTheme from '../../styles/materialTheme';
import DeleteConfirmationDialog from '../../components/dialog/DeleteConfirmationDialog';
import { getIconLocation } from '../../services/iconHelperService';
import { IStockLineCertificateSummary } from '../../@types/model/stock/stockLineCertificateSummary';

// Create styles
const styles = StyleSheet.create({
    section: {
        margin: 10,
        padding: 10,
        flex: 1,
    },
    page: {
        paddingTop: 25,
        paddingBottom: 65,
        marginBottom: 65,
    },
    pageNumber: {
        fontSize: 11,
        position: 'absolute',
        bottom: 35,
        right: 25,
    },
    pageMargin: {
        marginLeft: 25,
        marginRight: 25,
        flex: 1,
    },
    tableRow: {
        borderBottom: '1pt solid black',
        paddingTop: 10,
        paddingBottom: 10,
        fontSize: 8,
        flexDirection: 'row',
    },
    findingSheetColumn: {
        fontSize: 8,
        paddingLeft: 5,
        paddingRight: 5,
        paddingBottom: 5,
        paddingTop: 5,
        borderRight: '1pt solid black',
        alignItems: 'center',
    },
    fdc: { flexDirection: 'column' },
    fdr: { flexDirection: 'row' },
    verticalText: {
        transform: 'rotate(90deg)',
        width: 80,
        textAlign: 'center',
        justifyContent: 'center',
        alignItems: 'center',
    },
    barcode: {
        fontFamily: 'code39',
        fontSize: 20,
        transform: 'scaleY(2)',
        top: 10,
    },
    barcodeLabel: {
        fontSize: 9,
        letterSpacing: 2.5,
        top: 20,
    },
    fs6: { fontSize: 6 },
    fs8: { fontSize: 8 },
    fs10: { fontSize: 10 },
    fs11: { fontSize: 11 },
    fs12: { fontSize: 12 },
    fs13: { fontSize: 13 },
    fs14: { fontSize: 14 },
    fs15: { fontSize: 15 },
    bold: { fontWeight: 'bold' },
    pt2: { paddingTop: 2 },
    pt5: { paddingTop: 5 },
    pt10: { paddingTop: 10 },
    pt35: { paddingTop: 35 },
    pt50: { paddingTop: 50 },
    pb2: { paddingBottom: 2 },
    pb5: { paddingBottom: 5 },
    pb10: { paddingBottom: 10 },
    pb35: { paddingBottom: 35 },
    pl2: { paddingLeft: 2 },
    pl4: { paddingLeft: 4 },
    pl5: { paddingLeft: 5 },
    pl40: { paddingLeft: 40 },
    pr2: { paddingRight: 2 },
    pr20: { paddingRight: 20 },
    pr80: { paddingRight: 80 },
    pr100: { paddingRight: 100 },
    mt5: { marginTop: 5 },
    mt10: { marginTop: 10 },
    mt20: { marginTop: 20 },
    mt35: { marginTop: 35 },
    mb5: { marginBottom: 5 },
    mb10: { marginBottom: 10 },
    mb35: { marginBottom: 35 },
    flx1: { flex: 1 },
    blw1: { borderLeft: '1pt solid black' },
    brw1: { borderRight: '1pt solid black' },
    btw1: { borderTop: '1pt solid black' },
    bbw1: { borderBottom: '1pt solid black' },
    jcc: { justifyContent: 'center' },
    ail: { alignItems: 'left' },
    aic: { alignItems: 'center' },
    aife: { alignItems: 'flex-end' },
    tac: { textAlign: 'center' },
    posa: { position: 'absolute' },
    w30: { width: 30 },
    w100: { width: 100 },
    w140: { width: 140 },
    w200: { width: 200 },
    w280: { width: 280 },
    h40: { height: 40 },
    h80: { height: 80 },
    h90: { height: 90 },
    t2: { top: 2 },
    t5: { top: 5 },
    certCol1: {
        left: 0,
        width: 35,
        top: 5,
        paddingLeft: 3,
        backgroundColor: 'white',
        zIndex: 1,
        position: 'absolute',
    },
    certCol2: {
        left: 35,
        width: 35,
        top: 5,
        paddingLeft: 3,
        backgroundColor: 'white',
        zIndex: 2,
        position: 'absolute',
    },
    certCol3: {
        left: 70,
        width: 30,
        top: 5,
        paddingLeft: 3,
        backgroundColor: 'white',
        zIndex: 3,
        position: 'absolute',
    },
    certCol4: {
        left: 100,
        width: 25,
        top: 5,
        paddingLeft: 3,
        backgroundColor: 'white',
        zIndex: 4,
        position: 'absolute',
    },
    certCol5: {
        left: 125,
        width: 35,
        top: 5,
        paddingLeft: 3,
        backgroundColor: 'white',
        zIndex: 5,
        position: 'absolute',
    },
    certCol6: {
        left: 160,
        width: 35,
        top: 5,
        paddingLeft: 3,
        backgroundColor: 'white',
        zIndex: 6,
        position: 'absolute',
    },
    certCol7: {
        left: 195,
        width: 35,
        top: 5,
        paddingLeft: 3,
        backgroundColor: 'white',
        zIndex: 7,
        position: 'absolute',
    },
    certCol8: {
        left: 230,
        width: 35,
        top: 5,
        paddingLeft: 3,
        backgroundColor: 'white',
        zIndex: 8,
        position: 'absolute',
    },
    certCol9: {
        left: 265,
        width: 25,
        top: 5,
        paddingLeft: 3,
        backgroundColor: 'white',
        zIndex: 9,
        position: 'absolute',
    },
    certCol10: {
        left: 290,
        width: 30,
        top: 5,
        paddingLeft: 3,
        backgroundColor: 'white',
        zIndex: 10,
        position: 'absolute',
    },
    certCol11: {
        left: 320,
        width: 70,
        top: 5,
        paddingLeft: 3,
        backgroundColor: 'white',
        zIndex: 11,
        position: 'absolute',
    },
    certCol12: {
        left: 390,
        width: 35,
        top: 5,
        paddingLeft: 3,
        backgroundColor: 'white',
        zIndex: 12,
        position: 'absolute',
    },
    certCol13: {
        left: 425,
        width: 85,
        top: 5,
        paddingLeft: 3,
        backgroundColor: 'white',
        zIndex: 13,
        position: 'absolute',
    },
    certCol14: {
        left: 510,
        width: 35,
        top: 5,
        paddingLeft: 3,
        backgroundColor: 'white',
        zIndex: 14,
        position: 'absolute',
    },
    onionCertCols: {
        certCol1: {
            left: 0,
            width: 30,
            top: 5,
            paddingLeft: 3,
            backgroundColor: 'white',
            zIndex: 1,
            position: 'absolute',
        },
        certCol2: {
            left: 30,
            width: 30,
            top: 5,
            paddingLeft: 3,
            backgroundColor: 'white',
            zIndex: 2,
            position: 'absolute',
        },
        certCol3: {
            left: 60,
            width: 25,
            top: 5,
            paddingLeft: 3,
            backgroundColor: 'white',
            zIndex: 3,
            position: 'absolute',
        },
        certCol4: {
            left: 85,
            width: 30,
            top: 5,
            paddingLeft: 3,
            backgroundColor: 'white',
            zIndex: 4,
            position: 'absolute',
        },
        certCol5: {
            left: 115,
            width: 30,
            top: 5,
            paddingLeft: 3,
            backgroundColor: 'white',
            zIndex: 5,
            position: 'absolute',
        },
        certCol6: {
            left: 145,
            width: 35,
            top: 5,
            paddingLeft: 3,
            backgroundColor: 'white',
            zIndex: 6,
            position: 'absolute',
        },
        certCol7: {
            left: 180,
            width: 35,
            top: 5,
            paddingLeft: 3,
            backgroundColor: 'white',
            zIndex: 7,
            position: 'absolute',
        },
        certCol8: {
            left: 215,
            width: 25,
            top: 5,
            paddingLeft: 3,
            backgroundColor: 'white',
            zIndex: 8,
            position: 'absolute',
        },
        certCol9: {
            left: 240,
            width: 30,
            top: 5,
            paddingLeft: 3,
            backgroundColor: 'white',
            zIndex: 9,
            position: 'absolute',
        },
        certCol10: {
            left: 270,
            width: 70,
            top: 5,
            paddingLeft: 3,
            backgroundColor: 'white',
            zIndex: 10,
            position: 'absolute',
        },
        certCol11: {
            left: 345,
            width: 45,
            top: 5,
            paddingLeft: 3,
            backgroundColor: 'white',
            zIndex: 11,
            position: 'absolute',
        },
        certCol12: {
            left: 390,
            width: 35,
            top: 5,
            paddingLeft: 3,
            backgroundColor: 'white',
            zIndex: 12,
            position: 'absolute',
        },
        certCol13: {
            left: 425,
            width: 85,
            top: 5,
            paddingLeft: 3,
            backgroundColor: 'white',
            zIndex: 13,
            position: 'absolute',
        },
        certCol14: {
            left: 510,
            width: 35,
            top: 5,
            paddingLeft: 3,
            backgroundColor: 'white',
            zIndex: 14,
            position: 'absolute',
        },
    },
});

Font.register({
    family: 'code39',
    src: Code39,
});

interface IComplianceDashboardProps extends RouteComponentProps {
    auth : IAuthState;
    selectedSiteIds : Array<number> ;
    stocks : Array<IStock>;
    compliances : Array<ICompliance>;
    organizations : Array<IOrganization>;
    sizes : Array<ISize>;
    inventories : Array<IInventory>;
    farms : Array<IFarm>;
    commodities : Array<ICommodity>;
    varieties : Array<IVariety>;
    packs : Array<IPack>;
    marks : Array<IMark>;
    countries : Array<ICountry>;
    sites : Array<ISite>;
    contactInfos : Array<IContactInfo>;
    grades : Array<IGrade>;
    orchards : Array<IOrchard>;
    regions : Array<IRegion>;
    markets : Array<IMarket>;
    inspectionPoints : Array<IInspectionPoint>;
    colours : Array<IColour>;
    palletBaseTypes : Array<IPalletBaseType>;
    agreementCodes : Array<IAgreementCode>;
}

interface IComplianceDashboardState {
    isLoading : boolean;
    dataFetched : boolean;
    rows : Array<ICompliance>;
    collapseStatusColumn : { [key : string] : boolean };
    deletingCompliance ?: ICompliance;
    isEditing : boolean;
    complianceToEdit ?: ICompliance;
    selectedFromDate : moment.Moment;
    selectedToDate : moment.Moment;
    selectableStockRows : Array<ISelectedDispatchStock>;
    loadingPDF : boolean;
    expanded : { [key : number] : boolean };
    stockReplacingCompliance ?: ICompliance;
    selectedExporter : 'PPECB' | 'AMTA' | unknown;
    requestExportCertificate ?: ICompliance;
    filtersExpanded : boolean;
    turResponse : string;
    ppecbResponse : string;
    statusResponse : string;
    retrievingStatusStatus : 'unstarted' | 'loading' | 'completed' | 'failed';
    obtainingTokensStatus : 'unstarted' | 'loading' | 'completed' | 'failed';
    agreementCodeStatus : 'unstarted' | 'loading' | 'completed' | 'failed';
    submitToTUMStatus : 'unstarted' | 'loading' | 'completed' | 'failed';
    submitToPPECBStatus : 'unstarted' | 'loading' | 'completed' | 'failed';
    isMessageLoading : boolean;
    isMessageOpen : boolean;
    isIntegrationDetailsOpen : boolean;
    isStatusMessageOpen : boolean;
    consignments : Array<IConsignmentStorage>;
    infoPopupCompliance ?: ICompliance;
    isManualInspectingComplianceOpen : boolean;
    downloadPopupCompliance ?: ICompliance;
    actionPopupCompliance ?: ICompliance;
    actionListPopupCompliance ?: ICompliance;
    isDeletePopupOpen : boolean;
    selectedFiles : Array<File>;
    openComplianceFileUploadDialog : boolean;
    barcodeSearchValue ?: string;
    isEmailFormOpen : boolean;
    isTURFallback : boolean;
    selectedAgreementCode ?: IOptionType;
    selectedComplianceWaybill ?: string;
    selectedCompliance ?: ICompliance;
    isShowingComplianceSummary : boolean;

    // Current JSON Requests
    currentAgreementCodeRequest : string;
    currentTURRequest : string;
    currentPPECBRequest : string;
    currentPPECBValidationRequest : string;
    currentStatusRequest : string;

    // Current JSON Responses
    currentAgreementCodeResponse : string;
    currentTURResponse : string;
    currentPPECBResponse : string;
    currentPPECBValidationResponse : string;
    currentStatusResponse : string;
}
interface IDraggableContentProps {
    index : number;
    filteredCompliances : Array<ICompliance>;
    dragProvided : DraggableProvided;
    dragSnapshot : DraggableStateSnapshot;
    isClone : boolean;
}

class ComplianceDashboard extends React.Component<IComplianceDashboardProps, IComplianceDashboardState> {
    constructor(props : IComplianceDashboardProps) {
        super(props);

        this.state = {
            isLoading: false,
            dataFetched: false,
            selectedFromDate: moment().local().startOf('day').subtract(5, 'days'),
            selectedToDate: moment().local().endOf('day'),
            rows: [],
            collapseStatusColumn: { Closed: true },
            selectableStockRows: [],
            loadingPDF: false,
            expanded: {},
            selectedExporter: 'PPECB',
            obtainingTokensStatus : 'unstarted',
            agreementCodeStatus : 'unstarted',
            submitToTUMStatus : 'unstarted',
            submitToPPECBStatus : 'unstarted',
            retrievingStatusStatus : 'unstarted',
            filtersExpanded: true,
            isMessageLoading: false,
            ppecbResponse: '',
            turResponse: '',
            statusResponse: '',
            isMessageOpen : false,
            isStatusMessageOpen : false,
            consignments : [],
            isDeletePopupOpen: false,
            isIntegrationDetailsOpen : false,
            selectedFiles: [],
            openComplianceFileUploadDialog : false,
            isManualInspectingComplianceOpen: false,
            isEditing: false,
            isEmailFormOpen: false,
            isTURFallback: false,
            isShowingComplianceSummary: false,

            // Current JSON Requests
            currentAgreementCodeRequest: '',
            currentTURRequest: '',
            currentPPECBRequest: '',
            currentPPECBValidationRequest: '',
            currentStatusRequest: '',

            // Current JSON Responses
            currentAgreementCodeResponse: '',
            currentTURResponse: '',
            currentPPECBResponse: '',
            currentPPECBValidationResponse: '',
            currentStatusResponse: '',
        };
    }

    public componentDidMount = async () => {
        this.setLoading(true);
        // checks if indexedDB is available.
        const isIndexedDBAvailable = !!self.indexedDB ? true : false;

        if (isIndexedDBAvailable) {
            await syncMasterData(false);
        }

        try {
            if (!this.props.auth.ppecbToken?.token) {
                await getConsignmentToken();
            }
            if (!this.props.auth.turToken?.access_token) {
                await getTURToken();
            }
            const res = await ConsignmentHttpService.getConsignmentData();

            this.setState({ consignments: res?.data || [] }, this.setLoading);
        } catch (e) {
            generalShowErrorSnackbar('An error occurred retrieving consignment data.');
            this.setLoading(false);
        }

        // Used when switching to the page from another page
        if (!this.state.isLoading && this.props.selectedSiteIds?.length > 0) {
            this.refreshData(true);
        }
    };

    public componentDidUpdate = (prevProps : IComplianceDashboardProps) => {
        const nextProps = this.props;
        if (prevProps && nextProps) {
            /* prop changes go here */
            // Used when refreshing the page
            if (!this.state.isLoading && (nextProps.selectedSiteIds && prevProps.selectedSiteIds !== nextProps.selectedSiteIds)) {
                this.refreshData(true);
            }
        }
    };

    private downloadFindingSheet = () => {
        const compliance = this.state.downloadPopupCompliance;
        const sites = this.props.sites;
        if (compliance) {
            const siteCode = sites.find(x => x.id === compliance?.siteId)?.code;
            exportFindingSheet(compliance, siteCode);
        }
    };

    private getCompliance = (props : IComplianceDashboardProps, state : IComplianceDashboardState) => state.downloadPopupCompliance;
    private getUserEmail = (props : IComplianceDashboardProps) => props.auth.session?.user?.email;
    private getFarms = (props : IComplianceDashboardProps) => props.farms;
    private getContactInfos = (props : IComplianceDashboardProps) => props.contactInfos;


    private onPIFileUpload = async () => {
        const compliance = this.state.downloadPopupCompliance;

        // if ((this.props.farms.find(y => uniq(flatten(compliance?.complianceLines?.map(z => z.stock?.stockLines?.map(a => a.farmId)))).some(z => z === y.id))?.ftpDetailIds?.length ?? 0) < 1) {
        //     generalShowErrorSnackbar('No farms on this compliance have ftp details');
        //     return;
        // }

        this.setState({ isLoading: true });
        if (compliance) {
            this.setLoading(true);

            try {
                const data : IPIFileUpload = {
                    complianceId: compliance.id,
                    versionNumber: VERSION.version,
                };
                const res = await FTPHttpService.uploadPIFile(data);

                if (res && res.data) {

                    const updatedCompliance : ICompliance = {
                        ...compliance,
                        piUploaded: true,
                    };
                    dataSetCompliance(updatedCompliance);
                    generalShowSuccessSnackbar('PI file successfully uploaded');
                }
            } catch (ex) {
                generalShowErrorSnackbar('Failed to upload PI file');
            } finally {
                this.setLoading(false);
            }
        }
    };

    public onEmailReset = async (formValues : IEmailFormValues, formikActions : FormikActions<IEmailFormValues>) => {
        formikActions.resetForm();
        this.closeEmailForm();
    };

    private onEmailSubmit = async (value : IEmailFormValues) => {
        if (this.state.downloadPopupCompliance) {
            try {
                const data : IPIFileEmail = {
                    complianceId: this.state.downloadPopupCompliance.id,
                    versionNumber: VERSION.version,
                    emailData: new Email(value, []),
                };

                const res = await EmailHttpService.emailPIFile(data);

                if (res) {
                    generalShowSuccessSnackbar('PI file successfully emailed');
                }
                this.closeEmailForm();
                const compliance = { ...this.state.downloadPopupCompliance };
                compliance.piEmailed = true;
                this.setState({ downloadPopupCompliance: compliance });
                dataSetCompliance(compliance);
                this.closeEmailForm();
            } catch (e) {
                generalShowErrorSnackbar('An error occurred while emailing the PI file.');
            } finally {
                this.setLoading(false);
            }
        }
    };


    private generateEmail = createSelector([this.getCompliance, this.getUserEmail, this.getFarms, this.getContactInfos], (compliance, userEmail, farms, contactInfos) => {
        const toAddresses = contactInfos.filter(x =>
            farms.find(y => uniq(flatten(compliance?.complianceLines?.map(z => z.stock?.stockLines?.map(a => a.farmId)))).some(z => z === y.id))?.contactInfoIds?.some(y => y === x.id) && !!x.email && x.isActive)?.map(x => x.email ?? '');

        const ccAddresses : Array<string> = !!userEmail ? [userEmail] : [];

        const subject = `Packman v${VERSION.version} - PI File - ${compliance?.waybill}`;

        const body = `Please find attached the PI File for compliance ${compliance?.waybill}`;

        const files : Array<string> = ['PI File'];

        return new EmailFormValues(subject, body, toAddresses, ccAddresses, files);
    });

    private showDownloadPopup = (complianceId : number) => {
        const downloadPopupCompliance = this.props.compliances.find(x => x.id === complianceId);
        this.closeActionListPopup();
        this.setState({ downloadPopupCompliance });
    };

    private openActionPopup = (complianceId : number) => {
        const actionPopupCompliance = this.props.compliances.find(x => x.id === complianceId);
        this.closeActionListPopup();
        this.setState({ actionPopupCompliance });
    };

    private openActionListPopup = (event : React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        const complianceId = Number(event.currentTarget.value);
        const actionListPopupCompliance = this.props.compliances.find(x => x.id === complianceId);
        this.setState({ actionListPopupCompliance });
    };

    private handleStockReplace = (complianceId : number) => {
        const stockReplacingCompliance = this.props.compliances.find(x => x.id === complianceId);
        this.closeActionListPopup();
        this.setState({ stockReplacingCompliance });
    };

    private deleteCompliance = (complianceId : number) => {
        const deletingCompliance = this.props.compliances.find(x => x.id === complianceId);
        this.setState({ deletingCompliance });
    };

    private editCompliance = (complianceId : number) => {
        const selectedCompliance = this.props.compliances.find(x => x.id === complianceId);
        this.closeActionListPopup();
        this.setState({
            isEditing: true,
            complianceToEdit: selectedCompliance,
        });
    };

    private showComplianceSummaryDialog = (event : React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        const complianceId = Number(event.currentTarget.value);
        const infoPopupCompliance = this.props.compliances.find(x => x.id === complianceId);
        this.setState({
            isShowingComplianceSummary: true,
            selectedCompliance: infoPopupCompliance,
        });
    };

    public closeEditDialog = () => {
        this.setState({
            isEditing: false,
            complianceToEdit: undefined,
        });
        this.refreshData(true);
    };

    private closeActionPopup = () => this.setState({ actionPopupCompliance: undefined });
    private closeActionListPopup = () => this.setState({ actionListPopupCompliance: undefined });
    private closeComplianceInfo = () => this.setState({ infoPopupCompliance: undefined });
    private closeDownloadPopup = () => this.setState({ downloadPopupCompliance: undefined });

    private onManualInspectionClosed = (printPdf ?: boolean, newCompliance ?: ICompliance) => {
        this.setState({ isManualInspectingComplianceOpen: false });
        if (printPdf && newCompliance) {
            this.requestExportCertificate(newCompliance);
        }
    };

    private onPPECBResubmitClick = async () => {
        await this.submitForInspection(true);
    };

    private onGetStatusClick = () => {
        const { actionPopupCompliance } = this.state;
        this.openStatusMessagePopup();
        if (actionPopupCompliance) {
            this.getStatus(actionPopupCompliance);
        }
    };

    public setSelectedComplianceWaybill = (waybill : string) => {
        this.setState({ selectedComplianceWaybill: waybill });
    };

    public closeComplianceSummaryDialog = () => {
        this.setState({
            isShowingComplianceSummary: false,
            selectedCompliance: undefined,
        }, () => navPath(this.props.location?.pathname?.split('/:')[0]));
    };

    private getStatus = async (compliance : ICompliance) => {

        const consNo = compliance.waybill;
        let token = this.props.auth.ppecbToken?.token;
        this.setRetrievingStatusStatus('loading');

        if (!token) {
            await getConsignmentToken();
            token = this.props.auth?.ppecbToken?.token;
            if (!token) {
                generalShowErrorSnackbar('Could not find PPECB token');
                this.setRetrievingStatusStatus('failed');
                return;
            }
        }

        if (!consNo) {
            generalShowErrorSnackbar('Consignment not found or no waybill No.');
            this.setRetrievingStatusStatus('failed');
            return;
        }

        this.setCurrentStatusRequest(`consignmentNumber: ${consNo}, token: ${token}`);

        await getConsignmentResult(consNo).then(async (result) => {
            this.setCurrentStatusResponse(JSON.stringify(result));
            if (result.status === 200 && result.data !== null) {
                const consignmentResponse : IConsignmentResponse = result.data;

                const complianceStatus : IComplianceStatus = {
                    id: compliance.id,
                    status: consignmentResponse.consignmentLines.some(x => x.result !== 'Pass') ? 'Partially Rejected' : 'Approved',
                    inspectorId: consignmentResponse.inspectorCode,
                    isInspected: true,
                    upn: consignmentResponse.upn,
                    lines: consignmentResponse.consignmentLines.map((x) => {
                        const stock = this.props.stocks.find(y => y.barcode === x.sscc);
                        return {
                            id: compliance.complianceLines.find(y => y.stockId === stock?.id)?.id ?? 0,
                            reason1: x.result === 'Pass' ? undefined : (x.rejectionReasons && x.rejectionReasons[0] ? x.rejectionReasons[0].reason : undefined),
                            reason2: x.result === 'Pass' ? undefined : (x.rejectionReasons && x.rejectionReasons[1] ? x.rejectionReasons[1].reason : undefined),
                            reason3: x.result === 'Pass' ? undefined : (x.rejectionReasons && x.rejectionReasons[2] ? x.rejectionReasons[2].reason : undefined),
                            isRejected : x.result !== 'Pass',
                        };
                    }),
                };
                const oldCompliance = { ...compliance };
                const newCompliance = { ...compliance };
                newCompliance.status = complianceStatus.status;
                newCompliance.inspectorId = complianceStatus.inspectorId?.toString();
                newCompliance.upn = consignmentResponse.upn;

                const oldStock : Array<IStock> = this.props.stocks.filter(x => compliance.complianceLines.some(y => y.stockId === x.id) && consignmentResponse.consignmentLines.some(y => y.sscc === x.barcode));

                const newStock = [...oldStock];

                newStock.forEach((x) => {
                    dataSetStock(x);
                });

                newCompliance.complianceLines.forEach((x) => {
                    const stock = newStock.find(y => y.id === x.stockId);
                    const consignmentResponseLine = consignmentResponse.consignmentLines.find(y => stock?.barcode === y.sscc);
                    x.inspectedOn = moment(moment.now()).format(DATE_FORMAT_DEFAULT);
                    x.isRejected = consignmentResponseLine?.result !== 'Pass';
                    x.rejectReason1 = consignmentResponseLine?.result === 'Pass' ? '' : (consignmentResponseLine?.rejectionReasons && consignmentResponseLine?.rejectionReasons[0] ? consignmentResponseLine?.rejectionReasons[0].reason : '');
                    x.rejectReason2 = consignmentResponseLine?.result === 'Pass' ? '' : (consignmentResponseLine?.rejectionReasons && consignmentResponseLine?.rejectionReasons[0] ? consignmentResponseLine?.rejectionReasons[0].reason : '');
                    x.rejectReason3 = consignmentResponseLine?.result === 'Pass' ? '' : (consignmentResponseLine?.rejectionReasons && consignmentResponseLine?.rejectionReasons[0] ? consignmentResponseLine?.rejectionReasons[0].reason : '');
                    x.isInspected = true;
                });

                dataSetCompliance(newCompliance);

                try {
                    const res = await ComplianceHttpService.setComplianceStatus(complianceStatus);

                    if (res && res.data) {
                        dataSetCompliance(newCompliance);
                        generalShowSuccessSnackbar(`Compliance status successfully changed to ${complianceStatus.status.toLowerCase()}.`);
                        this.setStatusResponse(`Compliance status successfully changed to ${complianceStatus.status.toLowerCase()}.`);
                        this.setRetrievingStatusStatus('completed');
                        this.requestExportCertificate(newCompliance);
                    } else {
                        generalShowErrorSnackbar('An error occurred updating the compliance status.');
                        this.setStatusResponse('An error occurred updating the compliance status.');
                        dataSetCompliance(oldCompliance);
                        oldStock.forEach(x => dataSetStock(x));
                        this.setRetrievingStatusStatus('failed');
                    }
                } catch (e) {
                    generalShowErrorSnackbar('An error occurred updating the compliance status.');
                    this.setStatusResponse('An error occurred updating the compliance status.');
                    dataSetCompliance(oldCompliance);
                    oldStock.forEach(x => dataSetStock(x));
                    this.setRetrievingStatusStatus('failed');
                }
            } else if (result.status === 204 || result.data === null) {
                generalShowInfoSnackbar('This consignment has not been inspected yet.');
                this.setStatusResponse('This consignment has not been inspected yet.');
                this.setRetrievingStatusStatus('failed');
            } else {
                this.setStatusResponse(JSON.stringify(result.data));
                this.setRetrievingStatusStatus('failed');
                this.setStatusResponse('Could not get consignment status.');
                generalShowErrorSnackbar('Could not get consignment status.');
            }
        }).catch(() => {
            this.setRetrievingStatusStatus('failed');
            this.setStatusResponse('Could not get consignment status.');
            generalShowErrorSnackbar('Could not get consignment status.');
        });
    };

    private getComplianceFarmCodes = (compliance : ICompliance) => {
        const farmIds : Array<number> = [];
        const stockIds = compliance.complianceLines.filter(x => x.isActive).map(x => x.stockId);

        const stocks = this.props.stocks.filter(x => x.isActive && stockIds.some(y => y === x.id));

        stocks.forEach(x => x.stockLines.forEach((y) => {
            if (!farmIds.some(z => z === y.farmId)) {
                farmIds.push(y.farmId);
            }
        }));

        return uniq(this.props.farms.filter(x => farmIds.some(y => y === x.id)).map(x => x.code)).toString().replace(/,/g, ', ');
    };

    private getComplianceFarmGGN = (compliance : ICompliance) => {
        const farmIds : Array<number> = [];
        const stockIds = compliance.complianceLines.filter(x => x.isActive).map(x => x.stockId);

        const stocks = this.props.stocks.filter(x => x.isActive && stockIds.some(y => y === x.id));

        stocks.forEach(x => x.stockLines.forEach((y) => {
            if (!farmIds.some(z => z === y.farmId)) {
                farmIds.push(y.farmId);
            }
        }));

        return uniq(this.props.farms.filter(x => farmIds.some(y => y === x.id)).map(x => x.globalGapNumber)).toString().replace(/,/g, ', ');
    };

    private getSiteCodeAndGuid = (id ?: number) => {
        const props = this.props;
        const sites = props.sites;
        const site = sites.find(x => x.id === id);
        if (site) {
            return site.code + '_' + site.guid;
        }
    };

    private showEmailForm = () => {
        this.setState({ isEmailFormOpen: true });
    };

    private closeEmailForm = () => {
        this.setState({ isEmailFormOpen: false });
    };

    private onInspectionCertificateUpload = async () => {
        const files = this.state.selectedFiles;
        const selectedCompliance = this.state.downloadPopupCompliance;
        const data : Array<IGoogleCloudStorage> = [];
        if (selectedCompliance && selectedCompliance.waybill !== undefined) {
            if (files.length > 0 && selectedCompliance) {
                this.setState({ isLoading: true });
                files.forEach((file) => {
                    const reader = new FileReader();
                    reader.onload = async () => {
                        const fileStr = reader.result;
                        if (typeof fileStr === 'string') {
                            const fileString = fileStr.substr(5);
                            const contentType = fileString.split(';')[0];
                            const startFromPos = fileString.split(',')[0].length;
                            const fileBase64 = fileString.substr((startFromPos + 1));

                            const fileToBeUploaded : IGoogleCloudStorage = {
                                fileName: file?.name,
                                destinationPath: `InspectionCertificates/${this.getSiteCodeAndGuid(selectedCompliance.siteId)}/${selectedCompliance.waybill}/`,
                                contentType,
                                fileBase64,
                            };

                            data.push(fileToBeUploaded);

                            if (data.length === files.length) {
                                try {
                                    const res = await GoogleCloudStorageHttpService.uploadFile(data);
                                    if (res) {
                                        this.closeInspectionCertificateUploadDialog();
                                        this.setState({ isLoading: false });
                                        generalShowSuccessSnackbar('File uploaded successfully.');
                                    }
                                } catch (e) {
                                    this.closeInspectionCertificateUploadDialog();
                                    this.setState({ isLoading: false });
                                    generalShowErrorSnackbar('Error while uploading file.');
                                }
                            }
                        }
                    };
                    reader.readAsDataURL(file);
                });
            }
        } else {
            generalShowErrorSnackbar('The selected compliance does not have a waybill number!');
        }
    };

    private onFileRemove = (file : File) => {
        const index = this.state.selectedFiles?.findIndex(x => x.name === file.name);
        if (index > -1) {
            this.setState({ selectedFiles: removeArrayElement(this.state.selectedFiles, index) });
        }
    };

    private onViewOnInspectionClicked = () => navPath('/compliance/inspection');

    private handleFileInputChange = (files : Array<File>) => {
        files.forEach(x => this.setState({ selectedFiles: addArrayElement(this.state.selectedFiles, x, 'end') }));
    };

    private getDate = (type : 'from' | 'to') => {
        switch (type) {
            case 'from':
                return this.state.selectedFromDate.utc(true).startOf('day').unix() * 1000;
            case 'to':
                return this.state.selectedToDate.utc(true).endOf('day').unix() * 1000;
        }
    };

    private complianceCertificate = (compliance : ICompliance) => <Document>
        {compliance.complianceLines.filter(x => x.isActive).some(x => !x.isRejected) && this.complianceCertificatePage(compliance, false)}
        {compliance.complianceLines.filter(x => x.isActive).some(x => x.isRejected) && this.complianceCertificatePage(compliance, true)}
    </Document>;

    private complianceCertificatePage = (compliance : ICompliance, rejected : boolean) => {
        const firstActiveComplianceLine = compliance.complianceLines.filter(x => x.isActive)[0];
        const inspectedOn = firstActiveComplianceLine.inspectedOn;
        // eslint-disable-next-line no-console
        console.log(firstActiveComplianceLine, compliance);

        const complianceStock = this.props.stocks.find(x => x.id === firstActiveComplianceLine.stockId);
        const firstActiveStockLine = complianceStock?.stockLines.filter(x => x.isActive)[0];
        let firstActiveStockLineCommodityCode = '';

        if (firstActiveStockLine) {
            firstActiveStockLineCommodityCode = this.getCommodityCode(firstActiveStockLine.commodityId);
        }

        return (
            <Page size='A4' style={styles.page}>
                <View style={styles.pageMargin}>
                    <View style={[styles.fdr, styles.aic]}>
                        <View style={styles.flx1} />
                        <Text style={[styles.fs15]}>{`${this.state.selectedExporter} Inspection Certificate`}</Text>
                        <View style={styles.w30} />
                        <Text style={[styles.fs8]}>{`Date Printed: ${moment(moment.now()).format(DATE_FORMAT_DEFAULT)}`}</Text>
                    </View>
                    <View style={[styles.jcc, styles.aic, styles.bbw1, styles.pt5, styles.pb5, styles.mb5]}>
                        <Image style={{ height: 40 }} source={`${ASSET_BASE}/assets/images/zz2-logo.jpg`} />
                    </View>
                    <View style={styles.fdr}>
                        <View style={[styles.fdc, styles.fs12]}>
                            <Text>From: Bertie van Zyl (Edms) Bpk</Text>
                            <Text style={styles.pl40}>Posbus 19</Text>
                            <Text style={styles.pl40}>Mooketsi 0825</Text>
                            <Text>Tel   : 015 395 2040</Text>
                            <Text>Fax  : 015 395 2052</Text>
                        </View>
                        <View style={styles.flx1} />
                        <View style={[styles.fdc, styles.pr100, styles.fs12]}>
                            <Text>{`FOR: ${this.getOrganizationName(compliance.organizationId)}`}</Text>
                        </View>
                    </View>
                    <View style={[styles.bbw1, styles.pt2, styles.pb5, styles.mb5, styles.fs12, styles.pr80, styles.aife]}>
                        <Text>{`Inspection Date: ${inspectedOn ? formatDateTimeToDateOnly(inspectedOn) : ''}`}</Text>
                    </View>
                    <View style={[styles.fdr, styles.fs10, styles.pt2, styles.pb2]}>
                        <View>
                            <Text>Inspector:</Text>
                        </View>
                        <View style={[styles.t2, styles.posa, { left: 130 }]}>
                            <Text>{compliance.inspectorId}</Text>
                        </View>
                        <View style={[styles.t2, styles.posa, { left: 240 }]}>
                            <Text>Intake Waybill:</Text>
                        </View>
                        <View style={[styles.t2, styles.posa, { left: 340 }]}>
                            <Text>{compliance.waybill}</Text>
                        </View>
                    </View>
                    <View style={[styles.fdr, styles.fs10, styles.pt2, styles.pb2]}>
                        <View>
                            <Text>Inspection Point:</Text>
                        </View>
                        <View style={[styles.t2, styles.posa, { left: 130 }]}>
                            <Text>{compliance.siteId ? this.getSiteInspectionPointCode(compliance.siteId) : ''}</Text>
                        </View>
                        <View style={[styles.t2, styles.posa, { left: 240 }]}>
                            <Text>Packhouse Code:</Text>
                        </View>
                        <View style={[styles.t2, styles.posa, { left: 340 }]}>
                            <Text>{compliance.siteId ? this.getSiteCode(compliance.siteId) : ''}</Text>
                        </View>
                    </View>
                    <View style={[styles.fdr, styles.fs10, styles.pt2, styles.pb2]}>
                        <View>
                            <Text>PUC(s):</Text>
                        </View>
                        <View style={[styles.t2, styles.posa, { left: 50 }]}>
                            <Text>{this.getComplianceFarmCodes(compliance)}</Text>
                        </View>
                        <View style={[styles.t2, styles.posa, { left: 240 }]}>
                            <Text>UPN:</Text>
                        </View>
                        <View style={[styles.t2, styles.posa, { left: 340 }]}>
                            <Text>{compliance.upn}</Text>
                        </View>
                    </View>
                    <View style={[styles.fdr, styles.fs10, styles.pt2, styles.pb5, styles.bbw1]}>
                        <View>
                            <Text>Global Gap Number(s):</Text>
                        </View>
                        <View style={[styles.t2, styles.posa, { left: 130 }]}>
                            <Text>{this.getComplianceFarmGGN(compliance)}</Text>
                        </View>
                    </View>
                    {firstActiveStockLineCommodityCode !== 'ON' // Temporarily removed variety column and added gross weight for Onions Issue No: 772
                        ?
                        <View style={styles.tableRow}>
                            <Text style={styles.certCol1}>Comm</Text>
                            <Text style={styles.certCol2}>Variety</Text>
                            <Text style={styles.certCol3}>Pack</Text>
                            <Text style={styles.certCol4}>Grd</Text>
                            <Text style={styles.certCol5}>Cnt</Text>
                            <Text style={styles.certCol6}>Inven</Text>
                            <Text style={styles.certCol7}>Mark</Text>
                            <Text style={styles.certCol8}>Farm</Text>
                            <Text style={styles.certCol9}>TM</Text>
                            <Text style={styles.certCol10}>Rgn</Text>
                            <Text style={styles.certCol11}>Orchard</Text>
                            <Text style={styles.certCol12}>Crtn</Text>
                            <Text style={styles.certCol13}>Barcode</Text>
                            <Text style={styles.certCol14}>Accept</Text>
                        </View>
                        :
                        <View style={styles.tableRow}>
                            <Text style={styles.onionCertCols.certCol1}>Comm</Text>
                            <Text style={styles.onionCertCols.certCol2}>Pack</Text>
                            <Text style={styles.onionCertCols.certCol3}>Grd</Text>
                            <Text style={styles.onionCertCols.certCol4}>Cnt</Text>
                            <Text style={styles.onionCertCols.certCol5}>Inven</Text>
                            <Text style={styles.onionCertCols.certCol6}>Mark</Text>
                            <Text style={styles.onionCertCols.certCol7}>Farm</Text>
                            <Text style={styles.onionCertCols.certCol8}>TM</Text>
                            <Text style={styles.onionCertCols.certCol9}>Rgn</Text>
                            <Text style={styles.onionCertCols.certCol10}>Orchard</Text>
                            <Text style={styles.onionCertCols.certCol11}>Gross</Text>
                            <Text style={styles.onionCertCols.certCol12}>Crtn</Text>
                            <Text style={styles.onionCertCols.certCol13}>Barcode</Text>
                            <Text style={styles.onionCertCols.certCol14}>Accept</Text>
                        </View>
                    }
                    {firstActiveStockLineCommodityCode !== 'ON'
                        ? compliance.complianceLines.filter(x => x.isActive).filter(x => x.isRejected === rejected).map(x => this.getTableRow(x))
                        : compliance.complianceLines.filter(x => x.isActive).filter(x => x.isRejected === rejected).map(x => this.getTableRowOnionSpecific(x))
                    }
                    <View style={styles.fdc}>
                        {this.getPalletBaseTypeStockCount(compliance).map((x, index) => {
                            return (
                                <View key={`pdfcert_comp_pallet_base_stock_count_${index}`} style={styles.fdr}>
                                    <Text style={[styles.fs11, styles.pt5, { left: 20 }]}>
                                        {`${x.palletBaseType.name}`}:    {x.palletCount}
                                    </Text>
                                </View>
                            );
                        })}
                        <View style={styles.fdr}>
                            <Text style={[styles.fs11, styles.pt5, styles.posa, { left: 310 }]}>
                                    Total Cartons:    {this.getComplianceCartonCount(compliance)}
                            </Text>
                        </View>
                        { this.getCommodityCode(compliance.commodityId ?? 0) === 'ON' ?
                            <View style={styles.fdr}>
                                <Text style={[styles.fs11, styles.pt5, styles.posa, { top: 15, left: 310 }]}>
                                        Total Gross Weight:    {this.getComplianceGrossWeight(compliance)}
                                </Text>
                            </View>
                            : <View/>
                        }
                    </View>
                    <View style={styles.flx1} />
                    <View style={[styles.fdc, styles.mt5]}>
                        <Text style={styles.fs11}> {`Approved by Inspector:   ${compliance.inspectorId}`} </Text>
                        <View style={[styles.fdr, styles.mt35]}>
                            <Text style={styles.fs11}> Signature: </Text>
                            <View style={styles.flx1} />
                        </View>
                    </View>
                </View>
                <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
                    `Page ${pageNumber} of ${totalPages}`
                )} fixed> </Text>
            </Page>
        );
    };

    private getTableRowGrossWeight = (stock : IStock, line : IStockLineCertificateSummary) => {
        return (stock.grossWeight / stock.cartons) * line.cartons;
    };

    private getTableRow = (row : IComplianceLine) => {
        const stock = this.props.stocks.find(x => x.id === row.stockId);
        const stockLineSummary = getCertificateStockLineSummary(stock && stock.isActive ? stock.stockLines.filter(x => x.isActive) : []);
        return <View style={styles.fdc} key={'pdfcert_comp_line' + row.id}>
            {stock && stockLineSummary.map((line, index) => <View wrap={false} key={'pdfcert_comp_line' + row.id + '_' + stock.id + '_' + index} style={styles.tableRow}>
                <Text style={styles.certCol1}>{this.getCommodityCode(line.commodityId)}</Text>
                <Text style={styles.certCol2}>{this.getVarietyCode(line.varietyId)}</Text>
                <Text style={styles.certCol3}>{this.getPackCode(line.packId)}</Text>
                <Text style={styles.certCol4}>{this.getGradeCode(line.gradeId)}</Text>
                <Text style={styles.certCol5}>{this.getSizeCode(line.sizeId)}</Text>
                <Text style={styles.certCol6}>{this.getInventoryCode(stock.inventoryId)}</Text>
                <Text style={styles.certCol7}>{this.getMarkCode(stock.markId)}</Text>
                <Text style={styles.certCol8}>{this.getFarmCode(line.farmId)}</Text>
                <Text style={styles.certCol9}>{this.getMarketCode(stock.marketId)}</Text>
                <Text style={styles.certCol10}>{this.getRegionCode(stock.regionId)}</Text>
                <Text style={styles.certCol11}>{line.orchardId ? `${this.getOrchardComplianceCode(line.orchardId)} - ${this.getOrchardName(line.orchardId)}`.substr(0, 14) : ''}</Text>
                <Text style={styles.certCol12}>{line.cartons}</Text>
                <Text style={styles.certCol13}>{stock.barcode}</Text>
                <Text style={styles.certCol14}>{row.isRejected ? 'N' : 'Y'}</Text>
            </View>)}
        </View>;
    };

    private getTableRowOnionSpecific = (row : IComplianceLine) => { // Temporarily removed variety column and added gross weight for Onions Issue No: 772
        const stock = this.props.stocks.find(x => x.id === row.stockId);
        const stockLineSummary = getCertificateStockLineSummary(stock && stock.isActive ? stock.stockLines.filter(x => x.isActive) : []);
        return <View style={styles.fdc} key={'pdfcert_comp_line' + row.id}>
            {stock && stockLineSummary.map((line, index) => <View wrap={false} key={'pdfcert_comp_line' + row.id + '_' + stock.id + '_' + index} style={styles.tableRow}>
                <Text style={styles.onionCertCols.certCol1}>{this.getCommodityCode(line.commodityId)}</Text>
                <Text style={styles.onionCertCols.certCol2}>{this.getPackCode(line.packId)}</Text>
                <Text style={styles.onionCertCols.certCol3}>{this.getGradeCode(line.gradeId)}</Text>
                <Text style={styles.onionCertCols.certCol4}>{this.getSizeCode(line.sizeId)}</Text>
                <Text style={styles.onionCertCols.certCol5}>{this.getInventoryCode(stock.inventoryId)}</Text>
                <Text style={styles.onionCertCols.certCol6}>{this.getMarkCode(stock.markId)}</Text>
                <Text style={styles.onionCertCols.certCol7}>{this.getFarmCode(line.farmId)}</Text>
                <Text style={styles.onionCertCols.certCol8}>{this.getMarketCode(stock.marketId)}</Text>
                <Text style={styles.onionCertCols.certCol9}>{this.getRegionCode(stock.regionId)}</Text>
                <Text style={styles.onionCertCols.certCol10}>{line.orchardId ? `${this.getOrchardComplianceCode(line.orchardId)} - ${this.getOrchardName(line.orchardId)}`.substr(0, 14) : ''}</Text>
                <Text style={styles.onionCertCols.certCol11}>{this.getTableRowGrossWeight(stock, line)}</Text>
                <Text style={styles.onionCertCols.certCol12}>{line.cartons}</Text>
                <Text style={styles.onionCertCols.certCol13}>{stock.barcode}</Text>
                <Text style={styles.onionCertCols.certCol14}>{row.isRejected ? 'N' : 'Y'}</Text>
            </View>)}
        </View>;
    };

    private getComplianceGrossWeight = (compliance : ICompliance) => {
        let weight = 0;
        compliance.complianceLines.filter(x => x.isActive).forEach((x) => {
            const stock = this.props.stocks.find(y => y.id === x.stockId && y.isActive);
            if (stock) {
                weight += stock.grossWeight;
            }
        });
        return weight;
    };

    private getComplianceCartonCount = (compliance : ICompliance) => {
        let cartons = 0;
        compliance.complianceLines.filter(x => x.isActive).forEach((x) => {
            const stock = this.props.stocks.find(y => y.id === x.stockId && y.isActive);
            if (stock) {
                cartons += stock.cartons;
            }
        });
        return cartons;
    };

    private getPalletBaseTypeStockCount = (compliance : ICompliance) => {
        let results : Array<{ palletBaseType : PalletBaseType; palletCount : number; cartons : number }> = [];
        compliance.complianceLines.filter(x => x.isActive).forEach((x) => {
            const stock = this.props.stocks.find(y => y.id === x.stockId && y.isActive);
            if (stock) {
                const palletBaseType = this.props.palletBaseTypes.find(z => z.id === stock.palletBaseTypeId);
                if (palletBaseType) {
                    const index = results.findIndex(y => y.palletBaseType.id === palletBaseType.id);

                    if (index === -1) {
                        const data : { palletBaseType : PalletBaseType; palletCount : number; cartons : number } = {
                            palletBaseType,
                            palletCount: 1,
                            cartons: stock.cartons,
                        };

                        results = addArrayElement(results, data);
                    } else {
                        const data : { palletBaseType : PalletBaseType; palletCount : number; cartons : number } = {
                            palletBaseType,
                            palletCount: results[index].palletCount + 1,
                            cartons: results[index].cartons + stock.cartons,
                        };
                        results = upsertArrayElement(results, data, y => y.palletBaseType.id === palletBaseType.id) ?? results;
                    }
                }
            }
        });
        return results;
    };

    private setLoading = (isLoading : boolean = false) => {
        this.setState({ isLoading });
    };

    private getOrganizationName = (id : number) => {
        const item = this.props.organizations.find(x => x.id === id);
        return item ? item.name : '';
    };

    private getFarmCode = (id : number) => {
        const item = this.props.farms.find(x => x.id === id);
        return item ? item.code : '';
    };

    private getFarmGlobalGapNumber = (id : number) => {
        const item = this.props.farms.find(x => x.id === id);
        return item ? item.globalGapNumber : '';
    };

    private getCommodityCode = (id : number) => {
        const item = this.props.commodities.find(x => x.id === id);
        return item ? item.code : '';
    };

    private getVarietyCode = (id : number) => {
        const item = this.props.varieties.find(x => x.id === id);
        return item ? item.code : '';
    };

    private getMarketCode = (id : number) => {
        const item = this.props.markets.find(x => x.id === id);
        return item ? item.code : '';
    };

    private getPackCode = (id : number) => {
        const item = this.props.packs.find(x => x.id === id);
        return item ? item.code : '';
    };

    private getMarkCode = (id : number) => {
        const item = this.props.marks.find(x => x.id === id);
        return item ? item.code : '';
    };

    private getInventoryCode = (id : number) => {
        const item = this.props.inventories.find(x => x.id === id);
        return item ? item.code : '';
    };

    private getSizeCode = (id : number) => {
        const item = this.props.sizes.find(x => x.id === id);
        return item ? item.code : '';
    };

    private getGradeCode = (id : number) => {
        const item = this.props.grades.find(x => x.id === id);
        return item ? item.code : '';
    };

    private getSiteCode = (id : number) => {
        const item = this.props.sites.find(x => x.id === id);
        return item ? item.code : '';
    };

    private getSiteInspectionPointCode = (siteId : number) => {
        const site = this.props.sites.find(x => x.id === siteId);
        const inspectionPoint = this.props.inspectionPoints.find(x => x.id === (site && site.inspectionPointId) && x.isActive);
        return inspectionPoint ? inspectionPoint.code : '';
    };

    private getOrchardComplianceCode = (id ?: number) => {
        const item = this.props.orchards.find(x => x.id === id);
        return item && item.complianceCode ? item.complianceCode : '';
    };

    private getOrchardName = (id ?: number) => {
        const item = this.props.orchards.find(x => x.id === id);
        return item ? item.name : '';
    };

    private getRegionCode = (id : number) => {
        const item = this.props.regions.find(x => x.id === id);
        return item ? item.code : '';
    };

    private handleCSVImport = async (data : Array<Array<string>>) => {
        let compliance : ICompliance | undefined;
        let oldCompliance : ICompliance | undefined;
        if (data.some(x => x[5] !== '206')) {
            generalShowErrorSnackbar('This is not a valid 206 file, some of the lines don\'t have a transaction type of 206');
            return;
        }
        if (data.some(x => (x[37] !== 'Y') && (x[37] !== 'N'))) {
            generalShowErrorSnackbar('This is not a valid 206 file, some of the lines have no inspection result');
            return;
        }

        data.forEach((x, i) => {
            if (i > 0) {
                if (!compliance) {
                    compliance = this.props.compliances.find(y => y.waybill === x[6]);
                    oldCompliance = compliance;
                    if (compliance) {
                        compliance.upn = x[10];
                        compliance.inspectorId = x[42];
                    }
                }
                if (compliance) {
                    const stock = this.props.stocks.find(z => z.barcode === x[12]);
                    if (stock) {
                        const line = compliance.complianceLines.filter(y => y.isActive).find(y => y.stockId === stock.id);
                        if (line) {
                            const date = moment(x[43] + ' ' + x[44], DATE_FORMAT_CSV + ' ' + TIME_FORMAT_CSV);
                            const inspectedOn = moment(date).utc().format(DATE_FORMAT_DEFAULT);
                            line.inspectedOn = inspectedOn;
                            line.isInspected = x[36] === 'Y';
                            line.isRejected = x[37] !== 'Y';
                            line.rejectReason1 = x[38];
                            line.rejectReason2 = x[39];
                            line.rejectReason3 = x[40];
                            line.updatedOn = undefined;
                        }
                    }
                }
            }
        });
        if (compliance && oldCompliance) {
            compliance.updatedOn = undefined;
            compliance.status = data.some(x => x[37] !== 'Y') ? 'Partially Rejected' : 'Approved';
            try {
                const res = await ComplianceHttpService.addOrUpdateCompliance(compliance);

                if (res && res.data) {
                    dataSetCompliance(res.data);
                    this.refreshData(true);
                    generalShowSuccessSnackbar('Compliance successfully imported.');
                } else {
                    generalShowErrorSnackbar('An error occurred importing the compliance.');
                    if (oldCompliance) dataSetCompliance(oldCompliance);
                    this.setLoading(false);
                }
            } catch (e) {
                generalShowErrorSnackbar('An error occurred importing the compliance.');
                if (oldCompliance) dataSetCompliance(oldCompliance);
                this.setLoading(false);
            }
        }
        if (compliance) {
            this.requestExportCertificate(compliance);
        }
    };

    private refreshData = async (noAnnounce ?: boolean) => {
        this.setLoading(true);
        // checks if indexedDB is available.
        const isIndexedDBAvailable = !!self.indexedDB ? true : false;

        try {
            const res = await ComplianceHttpService.getComplianceDashboardData(this.getDate('from'), this.getDate('to'), this.state.barcodeSearchValue, this.props.selectedSiteIds, !isIndexedDBAvailable);

            dataSetAllStockRelatedData(res.data);
            this.setLoading(false);
            if (!noAnnounce) {
                generalShowSuccessSnackbar('Data Refreshed');
            }
        } catch (e) {
            generalShowErrorSnackbar('An error occurred retrieving compliance data.');
            this.setLoading(false);
        }
    };

    private onDragEnd = async (dropResult : DropResult) => {
        const complianceId = dropResult.draggableId && Number(dropResult.draggableId);
        const newStatus = dropResult.destination && dropResult.destination.droppableId;
        const compliance = this.props.compliances.find(x => x.id === complianceId);
        if (compliance && newStatus && (newStatus !== compliance.status)) {
            if (newStatus === 'Approved' || newStatus === 'Partially Rejected') {
                generalShowErrorSnackbar('Compliances cannot be moved to approved/rejected.');
                return;
            }
            const oldCompliance = { ...compliance };
            const newCompliance = { ...compliance };
            newCompliance.status = newStatus;
            dataSetCompliance(newCompliance);
            newCompliance.updatedOn = undefined;
            this.setLoading(true);
            const complianceStatus : IComplianceStatus = {
                id: newCompliance.id,
                status: newCompliance.status,
            };

            try {
                const res = await ComplianceHttpService.setComplianceStatus(complianceStatus);

                if (res && res.data) {
                    dataSetCompliance(newCompliance);
                    generalShowSuccessSnackbar(`Compliance status successfully changed to ${newStatus.toLowerCase()}.`);
                    this.setLoading(false);
                } else {
                    generalShowErrorSnackbar('An error occurred updating the compliance status.');
                    dataSetCompliance(oldCompliance);
                    this.setLoading(false);
                }
            } catch (e) {
                generalShowErrorSnackbar('An error occurred updating the compliance status.');
                dataSetCompliance(oldCompliance);
                this.setLoading(false);
            }
        }
    };

    private openManualInspectingCompliancePopup = () => this.setState({ isManualInspectingComplianceOpen: true });

    private getDroppableStyle = (status : string, snapshot : DroppableStateSnapshot) => {
        const complianceId = snapshot.draggingOverWith;
        const compliance = this.props.compliances.find(x => x.id === (complianceId && Number(complianceId)));
        if (compliance && snapshot.isDraggingOver && !this.state.collapseStatusColumn[status]) {
            if (compliance.status !== status && (status === 'Approved' || status === 'Partially Rejected')) {
                return { backgroundColor: '#ff6666', cursor: 'no-drop' };
            }
            return { backgroundColor: '#E6E6E6' };
        }
        return undefined;
    };

    private columnScrolled = (e : React.UIEvent<HTMLDivElement>, status : string) => {
        const scrollArea = document.getElementById(`${status}_scrollArea`);
        const scrolledValue = scrollArea && scrollArea.scrollTop;
        const fixedElements = jQuery('div[id*=dropdown]');
        fixedElements.toArray().forEach((x) => {
            x.style.marginTop = scrolledValue ? (0 - scrolledValue).toString() + 'px' : '';
        });
    };

    private requestExportCertificateDownloadButton = () => this.state.downloadPopupCompliance && this.requestExportCertificate(this.state.downloadPopupCompliance);

    private requestExportCertificate = (requestExportCertificate : ICompliance) => this.setState({ requestExportCertificate });
    private cancelExportCertificate = () => this.setState({ requestExportCertificate: undefined });

    private exportCertificate = () => {
        if (this.state.requestExportCertificate) {
            buildPDF(this.complianceCertificate, `Compliance_Certificate_${this.state.requestExportCertificate.waybill}.pdf`, this.state.requestExportCertificate);
        }
        this.setState({ requestExportCertificate: undefined });
    };

    private handleDateRangeChange = (start : moment.Moment, end : moment.Moment, changedBy ?: 'start' | 'end') => {
        const selectedFromDate = changedBy === 'start' ? moment.utc(start) : end < start ? moment.utc(end) : moment.utc(start);
        const selectedToDate = changedBy === 'end' ? moment.utc(end) : start > end ? moment.utc(start) : moment.utc(end);
        this.setState({ selectedFromDate, selectedToDate });
    };

    private complianceDeleteConfirmed = async (deleteReason : string) => {
        if (this.state.deletingCompliance) {
            const oldCompliance = { ...this.state.deletingCompliance };
            const newCompliance = { ...this.state.deletingCompliance };
            newCompliance.isActive = false;
            dataSetCompliance(newCompliance);
            newCompliance.updatedOn = undefined;

            if (deleteReason !== '' && deleteReason.length >= 200) {
                this.setLoading(true);
                try {
                    const res = await ComplianceHttpService.deleteCompliance(newCompliance.id, deleteReason);

                    if (res && res.data) {
                        dataSetCompliance(res.data);
                        generalShowSuccessSnackbar('Compliance successfully deleted.');
                        this.setLoading(false);
                    } else {
                        generalShowErrorSnackbar('An error occurred deleting the compliance.');
                        dataSetCompliance(oldCompliance);
                        this.setLoading(false);
                    }
                } catch (e) {
                    generalShowErrorSnackbar('An error occurred deleting the compliance.');
                    dataSetCompliance(oldCompliance);
                    this.setLoading(false);
                }
            } else {
                generalShowErrorSnackbar('Reason for deleting this compliance must be at least 200 characters.');
            }
        }
        this.closeDeleteConfirmationPopup();
    };

    private openInspectionCertificateUploadDialog = () => {
        this.setState({ openComplianceFileUploadDialog: true });
    };

    private closeInspectionCertificateUploadDialog = () => {
        this.setState({ openComplianceFileUploadDialog: false, selectedFiles: [] });
    };

    private closeDeleteConfirmationPopup = () => this.setState({ deletingCompliance: undefined });

    private getCompliances = (props : IComplianceDashboardProps) => props.compliances ?? [];
    private getSelectedSites = (props : IComplianceDashboardProps) => props.selectedSiteIds;
    private getStocks = (props : IComplianceDashboardProps) => props.stocks;
    private getAgreementCodes = (props : IComplianceDashboardProps) => props.agreementCodes;
    private getBarcodeSearchValue = (props : IComplianceDashboardProps, state : IComplianceDashboardState) => state.barcodeSearchValue;

    private getAgreementCodeOptions = createSelector(
        [this.getAgreementCodes],
        (agreementCodes : Array<IAgreementCode>) => {
            return agreementCodes.filter(x => x.isActive).map((x) => {
                return { label: x.code, value: x.id };
            });
        },
    );

    public getPartiallyRejectedValues = createSelector(
        [this.getCompliances, this.getSelectedSites],
        (compliances, selectedSiteIds : Array<number>) => {
            return compliances.filter(x => x.status === 'Partially Rejected'
                && x.isActive
                && selectedSiteIds?.some(y => y === x.siteId)
                && x.complianceLines.some(y => y.isActive));
        },
    );

    public getApprovedValues = createSelector(
        [this.getCompliances, this.getSelectedSites],
        (compliances, selectedSiteIds : Array<number>) => {
            return compliances.filter(x => x.status === 'Approved'
                && x.isActive
                && selectedSiteIds?.some(y => y === x.siteId)
                && x.complianceLines
                    .some(y => y.isActive));
        },
    );

    public getClosedValues = createSelector(
        [this.getCompliances, this.getSelectedSites],
        (compliances, selectedSiteIds : Array<number>) => {
            return compliances.filter(x => x.status === 'Closed'
                && x.isActive
                && selectedSiteIds?.some(y => y === x.siteId)
                && x.complianceLines
                    .some(y => y.isActive));
        },
    );

    public openStatusMessagePopup = () => this.setState({ isStatusMessageOpen: true });
    public closeStatusMessagePopup = () => this.setState({
        isStatusMessageOpen: false,
        statusResponse: '',
        retrievingStatusStatus: 'unstarted',
    });

    public openMessagePopup = () => this.setState({ isMessageOpen: true });
    public closeMessagePopup = () => this.setState({
        isMessageOpen: false,
        turResponse: '',
        ppecbResponse: '',
        obtainingTokensStatus: 'unstarted',
        agreementCodeStatus: 'unstarted',
        submitToTUMStatus: 'unstarted',
        submitToPPECBStatus: 'unstarted',
        selectedAgreementCode: undefined,
    });

    public openIntegrationDetailsPopup = () => this.setState({ isIntegrationDetailsOpen: true });
    public closeIntegrationDetailsPopup = () => this.setState({ isIntegrationDetailsOpen: false });

    // Set Responses
    public setTURResponse = (turResponse : string) => this.setState({ turResponse });
    public setPPECBResponse = (ppecbResponse : string) => this.setState({ ppecbResponse });
    public setStatusResponse = (statusResponse : string) => this.setState({ statusResponse });
    public setMessageIsLoading = (isMessageLoading : boolean) => this.setState({ isMessageLoading });

    // Set Statusses
    public setObtainingTokensStatus = (obtainingTokensStatus : 'unstarted' | 'loading' | 'completed' | 'failed') => this.setState({ obtainingTokensStatus });
    public setAgreementCodeStatus = (agreementCodeStatus : 'unstarted' | 'loading' | 'completed' | 'failed') => this.setState({ agreementCodeStatus });
    public setSubmitToTURStatus = (submitToTUMStatus : 'unstarted' | 'loading' | 'completed' | 'failed') => this.setState({ submitToTUMStatus });
    public setSubmitToPPECBStatus = (submitToPPECBStatus : 'unstarted' | 'loading' | 'completed' | 'failed') => this.setState({ submitToPPECBStatus });
    public setRetrievingStatusStatus = (retrievingStatusStatus : 'unstarted' | 'loading' | 'completed' | 'failed') => this.setState({ retrievingStatusStatus });

    // Set current JSON Requests
    public setCurrentAgreementCodeRequest = (currentAgreementCodeRequest : string) => this.setState({ currentAgreementCodeRequest });
    public setCurrentTURRequest = (currentTURRequest : string) => this.setState({ currentTURRequest });
    public setCurrentPPECBRequest = (currentPPECBRequest : string) => this.setState({ currentPPECBRequest });
    public setCurrentPPECBValidationRequest = (currentPPECBValidationRequest : string) => this.setState({ currentPPECBValidationRequest });
    public setCurrentStatusRequest = (currentStatusRequest : string) => this.setState({ currentStatusRequest });

    // Set current JSON Responses
    public setCurrentAgreementCodeResponse = (currentAgreementCodeResponse : string) => this.setState({ currentAgreementCodeResponse });
    public setCurrentTURResponse = (currentTURResponse : string) => this.setState({ currentTURResponse });
    public setCurrentPPECBResponse = (currentPPECBResponse : string) => this.setState({ currentPPECBResponse });
    public setCurrentPPECBValidationResponse = (currentPPECBValidationResponse : string) => this.setState({ currentPPECBValidationResponse });
    public setCurrentStatusResponse = (currentStatusResponse : string) => this.setState({ currentStatusResponse });

    private getFilteredCompliances = createSelector(
        [this.getCompliances, this.getBarcodeSearchValue, this.getStocks, this.getSelectedSites],
        (compliances, barcodeSearchValue, stocks, selectedSiteIds : Array<number>) => {

            const stock = barcodeSearchValue ? stocks.find(x => x.barcode.includes(barcodeSearchValue)) : undefined;

            return compliances.filter(x => x.isActive
                && selectedSiteIds?.some(y => y === x.siteId)
                && (x.complianceLines.length < 1 || x.complianceLines.some(y => y.isActive && (!stock || y.stockId === stock?.id))));
        });

    private renderComplianceItem = React.memo(({ data, index, style } : React.PropsWithChildren<ListChildComponentProps>) => {
        const compliance : ICompliance = data[index];
        if (!compliance) return <div/>;
        const status = compliance.status;
        return <Draggable isDragDisabled={status === 'Closed'} index={index} draggableId={compliance.id.toString()} key={`compliance_${status}${compliance.id.toString()}`}>
            {(dragProvided, dragSnapshot) => {
                const draggableContentProps : IDraggableContentProps = {
                    index,
                    dragProvided,
                    dragSnapshot,
                    filteredCompliances: data,
                    isClone: false,
                };

                return <div style={style}>
                    {this.getComplianceItemInner(draggableContentProps)}
                </div>;

            }}
        </Draggable>;
    });

    private setHorizontalAccordionOpen = (status : string, isOpen : boolean) => {
        const collapseStatusColumn = { ...this.state.collapseStatusColumn };
        collapseStatusColumn[status] = !isOpen;
        this.setState({ collapseStatusColumn });
    };

    private getCollapseStatusColumn = (props : IComplianceDashboardProps, state : IComplianceDashboardState) => state.collapseStatusColumn;

    private renderCompliances = createSelector([this.getFilteredCompliances, this.getCollapseStatusColumn], (compliances, collapsed) =>
        <DragDropContext onDragEnd={this.onDragEnd}>
            {COMPLIANCE_STATUSSES.map((status) => {
                const filteredCompliances = compliances.filter(x => x.status === status).sort((a, b) => compareNumber(b.id, a.id));
                return <ComplianceHorizontalAccordion title={status} key={status} isOpen={!collapsed[status]} setIsOpen={this.setHorizontalAccordionOpen}>
                    {status === 'Inspecting' &&
                        <CSVReader onFileLoaded={this.handleCSVImport}></CSVReader>
                    }
                    <Droppable droppableId={status}
                        mode={'virtual'}
                        renderClone={(dragProvided, dragSnapshot, rubric) => {
                            const index = rubric.source.index;
                            const draggableContentProps : IDraggableContentProps = {
                                index,
                                dragProvided,
                                dragSnapshot,
                                filteredCompliances,
                                isClone: true,
                            };
                            return this.getComplianceItemInner(draggableContentProps) ?? <div/>;
                        }} >
                        {(dropProvided, dropSnapshot) =>
                            <div id={`${status}_scrollArea`} onScroll={e => this.columnScrolled(e, status)} className={'fdc hfill oya oxh mb5'} style={this.getDroppableStyle(status, dropSnapshot)} ref={dropProvided.innerRef} {...dropProvided.droppableProps}>
                                { filteredCompliances.length > 0 &&
                                    <WindowList
                                        itemCount={filteredCompliances.length}
                                        width={'100%'}
                                        height={1000}
                                        itemSize={96}
                                        itemData={filteredCompliances}
                                    >
                                        {this.renderComplianceItem}
                                    </WindowList>
                                }
                            </div>
                        }
                    </Droppable>
                </ComplianceHorizontalAccordion>;
            })}
        </DragDropContext>);

    private renderFinishedCompliances = createSelector([this.getFilteredCompliances, this.getCollapseStatusColumn], (compliances, collapsed) =>
        <DragDropContext onDragEnd={this.onDragEnd}>
            {['Partially Rejected', 'Approved', 'Closed'].map((status) => {
                const filteredCompliances = compliances.filter(x => x.status === status).sort((a, b) => compareNumber(b.id, a.id));
                return <ComplianceHorizontalAccordion title={status} titleColour={this.getStatusColour(status)} key={status} isOpen={!collapsed[status]} setIsOpen={this.setHorizontalAccordionOpen}>
                    <Droppable droppableId={status}
                        mode={'virtual'}
                        renderClone={(dragProvided, dragSnapshot, rubric) => {
                            const index = rubric.source.index;
                            const draggableContentProps : IDraggableContentProps = {
                                index,
                                dragProvided,
                                dragSnapshot,
                                filteredCompliances,
                                isClone: true,
                            };
                            return this.getComplianceItemInner(draggableContentProps) ?? <div/>;
                        }} >
                        {(dropProvided, dropSnapshot) =>
                            <div id={`${status}_scrollArea`} onScroll={e => this.columnScrolled(e, status)} className={`fdc hfill oya oxh mb5 ${this.state.collapseStatusColumn[status] ? 'dn' : ''}`} style={this.getDroppableStyle(status, dropSnapshot)} ref={dropProvided.innerRef} {...dropProvided.droppableProps}>
                                { filteredCompliances.length > 0 &&
                            <WindowList
                                itemCount={filteredCompliances.length}
                                width={'100%'}
                                height={1000}
                                itemSize={96}
                                itemData={filteredCompliances}
                            >
                                {this.renderComplianceItem}
                            </WindowList>
                                }
                            </div>}
                    </Droppable>
                </ComplianceHorizontalAccordion>;
            })}
        </DragDropContext>);

    private downloadJSONDetails = () => {
        const currentAgreementCodeRequestFile = new Blob([this.state.currentAgreementCodeRequest]);
        const currentTURRequestFile = new Blob([this.state.currentTURRequest]);
        const currentPPECBRequestFile = new Blob([this.state.currentPPECBRequest]);
        const currentPPECBValidationRequestFile = new Blob([this.state.currentPPECBValidationRequest]);
        const currentAgreementCodeResponseFile = new Blob([this.state.currentAgreementCodeResponse]);
        const currentTURResponseFile = new Blob([this.state.currentTURResponse]);
        const currentPPECBResponseFile = new Blob([this.state.currentPPECBResponse]);
        const currentPPECBValidationResponseFile = new Blob([this.state.currentPPECBValidationResponse]);

        FileSaver.saveAs(currentAgreementCodeRequestFile, `agreement_code_request_${moment(moment.now()).format(DATETIME_SECONDS_PALTRACK_FILE)}.json`);
        FileSaver.saveAs(currentTURRequestFile, `tur_request_${moment(moment.now()).format(DATETIME_SECONDS_PALTRACK_FILE)}.json`);
        FileSaver.saveAs(currentPPECBRequestFile, `ppecb_request_${moment(moment.now()).format(DATETIME_SECONDS_PALTRACK_FILE)}.json`);
        FileSaver.saveAs(currentPPECBValidationRequestFile, `ppecb_validation_request_${moment(moment.now()).format(DATETIME_SECONDS_PALTRACK_FILE)}.json`);
        FileSaver.saveAs(currentAgreementCodeResponseFile, `agreement_code_response_${moment(moment.now()).format(DATETIME_SECONDS_PALTRACK_FILE)}.json`);
        FileSaver.saveAs(currentTURResponseFile, `tur_response_${moment(moment.now()).format(DATETIME_SECONDS_PALTRACK_FILE)}.json`);
        FileSaver.saveAs(currentPPECBResponseFile, `ppecb_response_${moment(moment.now()).format(DATETIME_SECONDS_PALTRACK_FILE)}.json`);
        FileSaver.saveAs(currentPPECBValidationResponseFile, `ppecb_validation_response_${moment(moment.now()).format(DATETIME_SECONDS_PALTRACK_FILE)}.json`);
    };

    private downloadStatusJSONDetails = () => {
        const currentStatusRequestFile = new Blob([this.state.currentStatusRequest]);
        const currentStatusResponseFile = new Blob([this.state.currentStatusResponse]);

        FileSaver.saveAs(currentStatusRequestFile, `status_request_${moment(moment.now()).format(DATETIME_SECONDS_PALTRACK_FILE)}.json`);
        FileSaver.saveAs(currentStatusResponseFile, `status_response_${moment(moment.now()).format(DATETIME_SECONDS_PALTRACK_FILE)}.json`);
    };

    private isDroppable = (isDragging : boolean, newStatus ?: string) => !isDragging || (!!newStatus && (newStatus !== 'Partially Rejected') && (newStatus !== 'Approved'));
    private getDraggableContentProps = (props : IDraggableContentProps) => props;

    private getRights = (props : IComplianceDashboardProps) => props.auth?.session?.user?.rights || [];
    private getPathName = (props : IComplianceDashboardProps) => props.location.pathname;

    private hasAdminRight = createSelector(
        [this.getRights, this.getPathName],
        (rights : Array<IRight>, url : string) => rights.some(x => url.includes(x.url) && x.isActive && x.code.endsWith('_ADMIN')));

    private getStatusIndicator = (status : 'unstarted' | 'loading' | 'completed' | 'failed') => {
        switch (status) {
            case 'unstarted':
                return '';
            case 'loading':
                return <CircularProgress size={'20px'}/>;
            case 'failed':
                return <BooleanFlag value={false}/>;
            case 'completed':
                return <BooleanFlag value={true}/>;
        }
    };

    private getBarcodes = createSelector(
        [this.getCompliances],
        (compliances : Array<ICompliance>) => {

            const s = compliances.map((x) => {
                return {
                    key: x.id,
                    value:
                    <div className={'fdc'}>
                        {x.complianceLines.map(y => y.stock?.barcode).map(z => (
                            <div>{z}</div>
                        ))}
                    </div>,
                };
            });
            return s;
        }
    );

    private getComplianceItemInner = createSelector([this.getDraggableContentProps], ({ index, filteredCompliances, dragProvided, dragSnapshot, isClone } : IDraggableContentProps) => {
        const compliance = filteredCompliances[index];
        if (!compliance) return <div/>;
        const palletCount = compliance.complianceLines.filter(x => x.isActive && !x.isRejected).length;
        const rejectCount = compliance.complianceLines.filter(x => x.isActive && x.isRejected && !x.isReplaced).length;
        return <div id={`compliance_${status}${compliance.id.toString()}${isClone ? '_clone' : ''}`}
            ref={dragProvided.innerRef}
            {...dragProvided.draggableProps}
            {...dragProvided.dragHandleProps}
        >
            <div
                className={`m5 PaperBorder fdc ${this.isDroppable(dragSnapshot.isDragging, dragSnapshot.draggingOver) ? '' : 'curnd'}`} >
                <div className={'fdr p5'} style={{ backgroundColor: materialTheme.custom.panel.card.header }}>
                    <Typography variant={'subtitle2'} className={'fdc wfill jcc'} >
                        <div className={'fdr'}>
                            <div className={'fdc'}>
                                <PackmanLink
                                    type={'transactions'}
                                    targetPage={'compliance'}
                                    id={Number(compliance.id)}
                                    text={`#${compliance.id}`} />
                                <div className={'fs12 aic'}>{compliance.createdOn && formatDateTimeToDateOnly(compliance.createdOn)}</div>
                            </div>
                            <div className={'flx1'} />
                            <div className='fdc hfill jcc fwb'>
                                <PackmanLink
                                    type={'transactions'}
                                    targetPage={'compliance'}
                                    id={Number(compliance.id)}
                                    text={compliance.waybill} />
                            </div>
                            <div className={'flx1'} />
                        </div>
                    </Typography>
                    <Tooltip title={'Actions'}>
                        <IconButton className={'p0 h30 w30'} value={compliance.id} onClick={this.openActionListPopup}>
                            <SettingsIcon className='cgray3' />
                        </IconButton>
                    </Tooltip>
                </div>
                <div className={'fdr aic p5'}>
                    <Tooltip disableInteractive title={this.getBarcodes(this.props)?.find(x => x.key === compliance.id)?.value ?? ''}>
                        <div className={'fs12'}><FontAwesomeIcon icon={faPallet} className={'ml10 mr10 mb1'} size={'lg'} />{` ${palletCount}`}</div>
                    </Tooltip>
                    {rejectCount > 0 && <div className={'pl2 fs12 cred'}>{`(${rejectCount})`}</div>}
                    <div className={'flx1'} />
                    {(compliance.turSubmitted && compliance.ppecbSubmitted) && <img className={'h30 w30 p0'} src={`${getIconLocation()}/workspace_premium.svg`} />}
                    <Tooltip title={'Compliance Summary'}>
                        <IconButton className={'p0 h30 w30'} value={compliance.id} onClick={this.showComplianceSummaryDialog}>
                            <Icon fontSize={'small'}>{'info'}</Icon>
                        </IconButton>
                    </Tooltip>
                </div>
            </div>
        </div>;
    });

    private getStatusColour = (status : string) => {
        switch (status) {
            case 'Approved':
                return colours.green;
            case 'Partially Rejected':
                return colours.orange;
            default:
                return undefined;
        }
    };

    public downloadIntegrationJSON = async () => {
        const compliance = this.state.downloadPopupCompliance;
        if (!compliance) return;

        let turToken = this.props.auth?.turToken?.access_token ?? '';

        if (!turToken) {
            await getTURToken();
            turToken = this.props.auth?.turToken?.access_token ?? '';
            if (!turToken) {
                generalShowErrorSnackbar('Could not find TUR token');
                this.setObtainingTokensStatus('failed');
                return;
            }
        }

        const currentAgreementCodeRequestFile = new Blob([`commodityCode: ${this.getCommodityCode(compliance?.commodityId ?? 0)}, countryCode: ${this.getCountryCode(compliance)}`]);
        FileSaver.saveAs(currentAgreementCodeRequestFile, `agreement_code_request_${moment(moment.now()).format(DATETIME_SECONDS_PALTRACK_FILE)}.json`);

        await getAgreementCode(this.getCommodityCode(compliance?.commodityId ?? 0), this.getCountryCode(compliance)).then(async (agreementCodeResult) => {
            const agreementCode = agreementCodeResult.data;
            const consignment = mapComplianceToConsignment(compliance, agreementCode);
            const eLot = mapConsignmentsToELot([consignment], agreementCode, turToken);

            const currentTURRequestFile = new Blob([JSON.stringify(eLot)]);
            const currentPPECBRequestFile = new Blob([JSON.stringify(consignment)]);

            FileSaver.saveAs(currentTURRequestFile, `tum_request_${moment(moment.now()).format(DATETIME_SECONDS_PALTRACK_FILE)}.json`);
            FileSaver.saveAs(currentPPECBRequestFile, `ppecb_request_${moment(moment.now()).format(DATETIME_SECONDS_PALTRACK_FILE)}.json`);
        });
    };

    private setSelectedAgreementCode = (e : React.ChangeEvent<{}>, selectedAgreementCode : IOptionType) => {
        this.setState({ selectedAgreementCode });
    };

    private getAgreementCode = (compliance : ICompliance) => {
        const stock = this.props.stocks.find(x => x.id === compliance?.complianceLines[0].stockId);
        const agreementCode = this.props.agreementCodes.find(x => x.commodityIds.some(y => y === compliance.commodityId) && x.countryIds.some(y => y === stock?.countryId));

        return agreementCode ? agreementCode.code : '';
    };

    public onInspectionResubmit = () => {
        this.submitForInspection(true);
    };

    public submitForInspection = async (resubmit ?: boolean) => {

        const compliance = this.state.downloadPopupCompliance ?? (!!resubmit ? this.state.actionPopupCompliance : undefined);
        if (!compliance) return;

        this.setObtainingTokensStatus('unstarted');
        this.setSubmitToTURStatus('unstarted');
        this.setSubmitToPPECBStatus('unstarted');

        this.openMessagePopup();

        const newCompliance = { ...compliance };
        let token = this.props.auth?.ppecbToken?.token ?? '';
        let turToken = this.props.auth?.turToken?.access_token ?? '';
        this.setObtainingTokensStatus('loading');

        if (!token) {
            await getConsignmentToken();
            token = this.props.auth?.ppecbToken?.token ?? '';
            if (!token) {
                generalShowErrorSnackbar('Could not find PPECB token');
                this.setObtainingTokensStatus('failed');
                return;
            }
        }

        if (!turToken) {
            await getTURToken();
            turToken = this.props.auth?.turToken?.access_token ?? '';
            if (!turToken) {
                generalShowErrorSnackbar('Could not find TUR token');
                this.setObtainingTokensStatus('failed');
                return;
            }
        }

        this.setObtainingTokensStatus('completed');

        this.setCurrentAgreementCodeRequest(`commodityCode: ${this.getCommodityCode(compliance?.commodityId ?? 0)}, countryCode: ${this.getCountryCode(compliance)}`);

        let agreementCode = '';

        if (this.state.selectedAgreementCode) {
            const selectedAgreementCode = this.props.agreementCodes.find(x => x.id === this.state.selectedAgreementCode?.value);
            agreementCode = selectedAgreementCode?.code ?? '';
        } else {
            agreementCode = this.getAgreementCode(compliance);
        }

        const consignment = mapComplianceToConsignment(compliance, agreementCode);
        const eLot = mapConsignmentsToELot([consignment], agreementCode, turToken);
        this.setSubmitToTURStatus('loading');

        this.setCurrentTURRequest(JSON.stringify(eLot));

        if (this.state.isTURFallback) {
            await upsertELot(eLot).then(async (eLotResult) => {
                this.setCurrentTURResponse(JSON.stringify(eLotResult));
                // eslint-disable-next-line no-console
                console.log(eLotResult);
                let eLotKey = eLotResult.data?.eLotKey;

                if (eLotResult.data.isSuccessful) {
                    this.setSubmitToTURStatus('completed');
                    newCompliance.turSubmitted = true;
                    newCompliance.eLotKey = eLotResult.data?.eLotKey;
                    if (!!this.state.downloadPopupCompliance) {
                        this.setState({ downloadPopupCompliance: newCompliance });
                    } else if (!!this.state.actionPopupCompliance) {
                        this.setState({ actionPopupCompliance: newCompliance });
                    }
                    await this.afterELot(consignment,  token, newCompliance);
                } else {
                    if (eLotResult.data.message.includes('already')) {
                        eLot.IsUpdate = true;
                        this.setCurrentTURRequest(JSON.stringify(eLot));
                        await upsertELot(eLot).then(async (eLotUpdResult) => {
                            this.setCurrentTURResponse(JSON.stringify(eLotUpdResult));
                            // eslint-disable-next-line no-console
                            console.log(eLotUpdResult);
                            eLotKey = eLotUpdResult.data?.eLotKey;
                            if (eLotKey) {
                                this.setSubmitToTURStatus('completed');
                                newCompliance.turSubmitted = true;
                                newCompliance.eLotKey = eLotUpdResult.data?.eLotKey;
                                if (!!this.state.downloadPopupCompliance) {
                                    this.setState({ downloadPopupCompliance: newCompliance });
                                } else if (!!this.state.actionPopupCompliance) {
                                    this.setState({ actionPopupCompliance: newCompliance });
                                }
                            } else {
                                this.setTURResponse(JSON.stringify(eLotUpdResult?.data?.message));
                                this.setSubmitToTURStatus('failed');
                            }
                            await this.afterELot(consignment, token, newCompliance);
                        }).catch(async () => {
                            generalShowErrorSnackbar('ELot could not be uploaded.');
                            this.setTURResponse('ELot could not be updated.');
                            this.setSubmitToTURStatus('failed');
                            await this.afterELot(consignment, token, newCompliance);
                            return;
                        });
                    } else {
                        generalShowErrorSnackbar('ELot could not be uploaded.');
                        this.setTURResponse(JSON.stringify(eLotResult?.data?.message));
                        this.setSubmitToTURStatus('failed');
                        await this.afterELot(consignment, token, newCompliance);
                        return;
                    }
                }

            }).catch(async () => {
                generalShowErrorSnackbar('ELot could not be uploaded.');
                this.setTURResponse('ELot could not be uploaded.');
                this.setSubmitToTURStatus('failed');
                await this.afterELot(consignment, token, newCompliance);
                return;
            });
        } else { // Upsert TUM
            await upsertTUMV3TradeUnit({ complianceId: compliance.id, agreementCodeOverride: this.state.selectedAgreementCode ? agreementCode : undefined  }).then(async (eLotResult) => {
                this.setCurrentTURResponse(JSON.stringify(eLotResult));
                // eslint-disable-next-line no-console
                console.log(eLotResult);

                if (eLotResult.data.isSuccessful) {
                    this.setSubmitToTURStatus('completed');
                    newCompliance.turSubmitted = true;
                    newCompliance.eLotKey = eLotResult.data?.transactionId;
                    if (!!this.state.downloadPopupCompliance) {
                        this.setState({ downloadPopupCompliance: newCompliance });
                    } else if (!!this.state.actionPopupCompliance) {
                        this.setState({ actionPopupCompliance: newCompliance });
                    }
                    await this.afterELot(consignment,  token, newCompliance);
                } else {
                    this.setTURResponse(JSON.stringify(eLotResult?.data?.message));
                    this.setSubmitToTURStatus('failed');
                }
            }).catch(async () => {
                generalShowErrorSnackbar('ELot could not be uploaded.');
                this.setTURResponse('ELot could not be uploaded.');
                this.setSubmitToTURStatus('failed');
                await this.afterELot(consignment, token, newCompliance);
                return;
            });
        }

    };

    private updateConsignment = async () => {
        const compliance = this.state.downloadPopupCompliance ?? this.state.actionPopupCompliance;
        if (!compliance) return;

        const agreementCode = this.state.currentAgreementCodeResponse;
        const consignment = mapComplianceToConsignment(compliance, agreementCode);
        const newCompliance = { ...compliance };
        let token = this.props.auth?.ppecbToken?.token;

        if (!token) {
            await getConsignmentToken();
            token = this.props.auth?.ppecbToken?.token;
            if (!token) {
                generalShowErrorSnackbar('Could not find PPECB token');
                this.setObtainingTokensStatus('failed');
                return;
            }
        }

        const messageId = compliance.inspectionMessageId;

        if (!messageId) {
            generalShowErrorSnackbar('Consignment message id not found');
        }

        this.setSubmitToPPECBStatus('loading');
        consignment.inspectionMessageId = messageId;
        await updateConsignment(consignment).then(async (ppecbResult) => {
            // eslint-disable-next-line no-console
            console.log(ppecbResult);
            if (ppecbResult && ppecbResult?.status && ppecbResult.status === 200) {
                generalShowSuccessSnackbar('Consignment updated on PPECB');
                this.setSubmitToPPECBStatus('completed');
                newCompliance.status = 'Inspecting';
                newCompliance.ppecbSubmitted = true;
                const complianceStatus : IComplianceStatus = {
                    id: newCompliance.id,
                    status: newCompliance.status,
                    PPECBSubmitted: newCompliance.ppecbSubmitted,
                };
                const res = await ComplianceHttpService.setComplianceStatus(complianceStatus);
                if (res && res.data) {
                    dataSetCompliance(newCompliance);
                    generalShowSuccessSnackbar(`Compliance status successfully changed to ${newCompliance.status.toLowerCase()}.`);
                    this.setLoading(false);
                } else {
                    generalShowErrorSnackbar('An error occurred updating the compliance status.');
                    dataSetCompliance(compliance);
                    this.setLoading(false);
                }
            } else {
                this.setPPECBResponse(JSON.stringify(ppecbResult?.data));
                generalShowErrorSnackbar('Consignment could not be updated on PPECB');
                this.setSubmitToPPECBStatus('failed');
            }
        });
    };

    private deleteConsignment = async () => {
        const compliance = this.state.downloadPopupCompliance ?? this.state.actionPopupCompliance;
        if (!compliance) return;
        const newCompliance = { ...compliance };

        const messageId = compliance.inspectionMessageId;

        if (!messageId) {
            generalShowErrorSnackbar('Consignment message id not found');
            return;
        }

        let token = this.props.auth?.ppecbToken?.token;

        if (!token) {
            await getConsignmentToken();
            token = this.props.auth?.ppecbToken?.token;
            if (!token) {
                generalShowErrorSnackbar('Could not find PPECB token');
                this.setObtainingTokensStatus('failed');
                return;
            }
        }

        this.setSubmitToPPECBStatus('loading');
        await deleteConsignment(messageId ?? 0).then(async (ppecbResult) => {
            // eslint-disable-next-line no-console
            console.log(ppecbResult);
            if (ppecbResult && ppecbResult.status && ppecbResult?.status === 200) {
                generalShowSuccessSnackbar('Consignment deleted from PPECB');
                this.setSubmitToPPECBStatus('completed');
                newCompliance.status = 'Planned';
                newCompliance.ppecbSubmitted = false;
                const complianceStatus : IComplianceStatus = {
                    id: newCompliance.id,
                    status: newCompliance.status,
                    PPECBSubmitted: newCompliance.ppecbSubmitted,
                };
                const res = await ComplianceHttpService.setComplianceStatus(complianceStatus);
                if (res && res.data) {
                    dataSetCompliance(newCompliance);
                    generalShowSuccessSnackbar(`Compliance status successfully changed to ${newCompliance.status.toLowerCase()}.`);
                    this.setLoading(false);
                } else {
                    generalShowErrorSnackbar('An error occurred updating the compliance status.');
                    dataSetCompliance(compliance);
                    this.setLoading(false);
                }
            } else {
                this.setPPECBResponse(JSON.stringify(ppecbResult?.data));
                generalShowErrorSnackbar('Consignment could not be deleted from PPECB');
                this.setSubmitToPPECBStatus('failed');
            }
        });

    };

    private deleteConsignmentOnlyPPECB = async () => {
        const compliance = this.state.downloadPopupCompliance ?? this.state.actionPopupCompliance;
        if (!compliance) return;
        const newCompliance = { ...compliance };

        const messageId = compliance.inspectionMessageId;

        if (!messageId) {
            generalShowErrorSnackbar('Consignment message id not found');
            return;
        }

        let token = this.props.auth?.ppecbToken?.token;

        if (!token) {
            await getConsignmentToken();
            token = this.props.auth?.ppecbToken?.token;
            if (!token) {
                generalShowErrorSnackbar('Could not find PPECB token');
                this.setObtainingTokensStatus('failed');
                return;
            }
        }

        this.setSubmitToPPECBStatus('loading');
        await deleteConsignment(messageId ?? 0).then(async (ppecbResult) => {
            // eslint-disable-next-line no-console
            console.log(ppecbResult);
            if (ppecbResult && ppecbResult.status && ppecbResult?.status === 200) {
                generalShowSuccessSnackbar('Consignment deleted from PPECB');
                this.setSubmitToPPECBStatus('completed');
                newCompliance.ppecbSubmitted = false;
                const complianceStatus : IComplianceStatus = {
                    id: newCompliance.id,
                    status: 'Inspecting',
                };
                const res = await ComplianceHttpService.updateComplianceAfterPPECBConsignmentDelete(complianceStatus);
                if (res && res.data) {
                    dataSetCompliance(newCompliance);
                    generalShowSuccessSnackbar('Compliance successfully updated.');
                    this.setLoading(false);
                } else {
                    generalShowErrorSnackbar('An error occurred updating the compliance.');
                    dataSetCompliance(compliance);
                    this.setLoading(false);
                }
            } else {
                this.setPPECBResponse(JSON.stringify(ppecbResult?.data));
                generalShowErrorSnackbar('Consignment could not be deleted from PPECB');
                this.setSubmitToPPECBStatus('failed');
            }
        });

    };

    private afterELot = async (consignment : IConsignment, token : string, compliance : ICompliance) => {
        this.setSubmitToPPECBStatus('loading');

        this.setCurrentPPECBRequest(JSON.stringify(consignment));
        await uploadConsignment(consignment).then(async (ppecbResult) => {
            this.setCurrentPPECBResponse(JSON.stringify(ppecbResult));
            // eslint-disable-next-line no-console
            console.log(JSON.stringify(consignment));

            // eslint-disable-next-line no-console
            console.log(ppecbResult);

            if (ppecbResult.status === 200) {
                const messageId = ppecbResult.data?.inspectionMessageId;
                // eslint-disable-next-line no-console
                console.log(messageId);
                if (messageId) {
                    this.setCurrentPPECBValidationRequest(JSON.stringify(messageId));
                    await validateConsignment(messageId).then(async (validationResult) => {
                        this.setCurrentPPECBValidationResponse(JSON.stringify(validationResult?.data));
                        if (validationResult?.data?.errors && validationResult?.data?.errors.length > 0) {
                            this.setPPECBResponse(JSON.stringify(validationResult?.data?.errors));
                            generalShowErrorSnackbar('File could not be submitted to PPECB');
                            this.setSubmitToPPECBStatus('failed');
                            return;
                        } else if (validationResult?.data && validationResult?.status && validationResult?.status === 200) {
                            generalShowSuccessSnackbar('File submitted to PPECB');
                            this.setSubmitToPPECBStatus('completed');
                            try {
                                const newCompliance = { ...compliance };
                                newCompliance.inspectionMessageId = messageId;
                                newCompliance.status = 'Inspecting';
                                newCompliance.ppecbSubmitted = true;
                                const complianceStatus : IComplianceStatus = {
                                    id: newCompliance.id,
                                    status: newCompliance.status,
                                    PPECBSubmitted: newCompliance.ppecbSubmitted,
                                    inspectionMessageId: newCompliance.inspectionMessageId,
                                };
                                const res = await ComplianceHttpService.setComplianceStatus(complianceStatus);

                                if (res && res.data) {
                                    dataSetCompliance(newCompliance);
                                    generalShowSuccessSnackbar(`Compliance status successfully changed to ${newCompliance.status.toLowerCase()}.`);
                                    this.setLoading(false);
                                } else {
                                    generalShowErrorSnackbar('An error occurred updating the compliance status.');
                                    dataSetCompliance(compliance);
                                    this.setLoading(false);
                                }
                            } catch (e) {
                                generalShowErrorSnackbar('An error occurred updating the compliance status.');
                                dataSetCompliance(compliance);
                                this.setLoading(false);
                            }
                        } else {
                            this.setPPECBResponse(JSON.stringify(validationResult?.data));
                            this.setSubmitToPPECBStatus('failed');
                            generalShowErrorSnackbar('Could not validate consignment');
                        }
                    });
                } else {
                    this.setSubmitToPPECBStatus('failed');
                    generalShowErrorSnackbar('No message id was returned, could not submit consignment to PPECB');
                }
            } else {
                this.setPPECBResponse(JSON.stringify(ppecbResult?.data));
                if (JSON.stringify(ppecbResult?.data).includes('Inspection message already created.')) {
                    generalShowInfoSnackbar('Compliance already submitted to PPECB');
                    try {
                        const newCompliance = { ...compliance };
                        newCompliance.status = 'Inspecting';
                        newCompliance.ppecbSubmitted = true;
                        const complianceStatus : IComplianceStatus = {
                            id: newCompliance.id,
                            status: newCompliance.status,
                            PPECBSubmitted: newCompliance.ppecbSubmitted,
                        };
                        const res = await ComplianceHttpService.setComplianceStatus(complianceStatus);

                        if (res && !!res.data) {
                            dataSetCompliance(newCompliance);
                            this.setSubmitToPPECBStatus('completed');
                            generalShowSuccessSnackbar(`Compliance status successfully changed to ${newCompliance.status.toLowerCase()}.`);
                            this.setLoading(false);
                        } else {
                            generalShowErrorSnackbar('An error occurred updating the compliance status.');
                            dataSetCompliance(compliance);
                            this.setLoading(false);
                        }
                    } catch (e) {
                        generalShowErrorSnackbar('An error occurred updating the compliance status.');
                        dataSetCompliance(compliance);
                        this.setLoading(false);
                    }
                } else {
                    this.setSubmitToPPECBStatus('failed');
                    generalShowErrorSnackbar('Compliance could not be submitted to PPECB');

                }
            }
        }).catch(() => {
            generalShowErrorSnackbar('Compliance could not be submitted to PPECB');
            this.setSubmitToPPECBStatus('failed');
            return;
        });
    };

    private renderTUMOrPPECBErrorResponse = (response : string) => {
        let isJsonObject : boolean = false;
        let isJsonArray : boolean = false;
        let jsonValue : any;
        try {
            jsonValue = JSON.parse(response);
            const type = Object.prototype.toString.call(jsonValue);
            isJsonObject = type === '[object Object]';
            isJsonArray = type === '[object Array]';
        } catch (err) {
            isJsonObject = false;
            isJsonArray = false;
        }

        if (isJsonObject) {
            const columns = Object.keys(jsonValue);

            const tableColumns = columns.map((header) => {
                if (isBoolean(jsonValue[header])) {
                    return { title: header, field: header, formatFunction: booleanToYesNo, type: 'boolean', enableFiltering: true, enableSorting: true };
                } else {
                    return { title: header, field: header, enableFiltering: true, enableSorting: true };
                }
            });

            return (
                <CustomTable
                    enableSorting
                    enableFiltering
                    fitWidthToPage
                    columns={tableColumns}
                    rows={[jsonValue]}
                />
            );
        } else if (isJsonArray) {
            const columns = Object.keys(jsonValue[0]);

            const tableColumns = columns.map((header) => {
                if (isBoolean(jsonValue[0][header])) {
                    return { title: header, field: header, formatFunction: booleanToYesNo, type: 'boolean', enableFiltering: true, enableSorting: true };
                } else {
                    return { title: header, field: header, enableFiltering: true, enableSorting: true };
                }
            });

            return (
                <CustomTable
                    enableSorting
                    enableFiltering
                    fitWidthToPage
                    columns={tableColumns}
                    rows={jsonValue}
                />
            );
        } else {
            return (
                <TextField
                    value={response}
                    multiline
                    variant={'outlined'}
                    disabled
                    className={'mnw400 mxh250 pr20 oya'}
                />
            );
        }
    };

    private getCountryCode = (compliance ?: ICompliance) => {
        const stock = this.props.stocks.find(x => x.id === compliance?.complianceLines[0].stockId);
        return this.props.countries.find(x => x.id === stock?.countryId)?.code ?? 'NL';
    };

    private barcodeSearchValueChanged = (event : CustomChangeEvent) => {
        this.setState({ barcodeSearchValue: event.target.value });
    };

    private onTURFallBackToggle = () => this.setState(prevState => ({ isTURFallback: !prevState.isTURFallback }));

    private hasManualInspectionRight = createSelector(
        [this.getRights, this.getPathName],
        (rights : Array<IRight>, url : string) => rights.some(x => url.includes(x.url) && x.isActive && x.code === 'COMPLIANCE_MANUAL_INSPECTION'));

    public render() {
        const { selectedFromDate, selectedToDate, complianceToEdit, deletingCompliance, selectedFiles, isLoading, openComplianceFileUploadDialog, isManualInspectingComplianceOpen, actionPopupCompliance, actionListPopupCompliance, downloadPopupCompliance, isMessageOpen , turResponse, ppecbResponse } = this.state;

        return (
            <Screen isPadded={false} isScrollable={false} isLoading={this.state.isLoading}>
                {this.state.isLoading &&
                    <div className={'posa post0 posr0 posb0 posl0 aic jcc zi1000'}>
                        <div className={'posr aic jcc h50 w50'}>
                            <div className={'posa post0 posr0 posb0 posl0 aic jcc'}>
                                <img height={40} src={`${ASSET_BASE}/assets/images/ZZ2_Pallets.png`} />
                            </div>
                            <CircularProgress color={'primary'} className={'posa post0 posr0 posb0 posl0'} size={50} />
                        </div>
                    </div>
                }
                <div className={'fdc hfill pl10 pr10 pt10 mb10 oh'}>
                    <div className={'fdr'}>
                        <CustomTooltip title={'Refresh Compliances'}>
                            <IconButton color={'inherit'} onClick={() => this.refreshData()}>
                                <Icon>refresh</Icon>
                            </IconButton>
                        </CustomTooltip>
                        <div className={'flx1'}/>
                        <div className={'mt10 mr20'}>
                            <SingleToggleButton label={'TUR Fallback?'} value={this.state.isTURFallback} onChange={this.onTURFallBackToggle} name={''}/>
                        </div>
                        <TextField fullWidth variant='standard' className={'w250 mt5'} label={'Filter By Barcode'} value={this.state.barcodeSearchValue} onChange={this.barcodeSearchValueChanged}/>
                        <CustomTooltip title={'Search remote for barcode'}>
                            <IconButton className={'mt20 mr10 h20 w20'} onClick={() => this.refreshData(true)}><Icon>search</Icon></IconButton>
                        </CustomTooltip>
                        <div className={'w10'}/>
                        <TransactionFilter className='pr10 pt5 mb15' selectedFromDate={selectedFromDate} selectedToDate={selectedToDate} handleDateRangeChange={this.handleDateRangeChange} onApplyClick={this.refreshData}  />
                    </div>
                    <div className={'fdr hfill oh'} >
                        {this.renderCompliances(this.props, this.state)}
                        {this.renderFinishedCompliances(this.props, this.state)}
                    </div>
                    {!!isManualInspectingComplianceOpen && !!actionPopupCompliance &&
                        <PackmanDialog
                            title={`${actionPopupCompliance && actionPopupCompliance.waybill} - Manual Inspection`}
                            isInfo={true}
                            fullScreen={true}
                            isOpen={!!isManualInspectingComplianceOpen && !!actionPopupCompliance}
                            onClose={() => this.onManualInspectionClosed()}>
                            <ComplianceManualInspection
                                open={!!isManualInspectingComplianceOpen && !!actionPopupCompliance}
                                compliance={actionPopupCompliance}
                                onClose={this.onManualInspectionClosed}
                                refreshData={this.refreshData}
                            />
                        </PackmanDialog>
                    }
                    <PackmanDialog
                        title={`${this.state.stockReplacingCompliance && this.state.stockReplacingCompliance.waybill} - Replace Rejected Stock`}
                        isInfo={true}
                        fullScreen={true}
                        isOpen={!!this.state.stockReplacingCompliance}
                        onClose={() => this.setState({ stockReplacingCompliance: undefined })}>
                        <ComplianceStockReplacement
                            open={!!this.state.stockReplacingCompliance}
                            compliance={this.state.stockReplacingCompliance}
                            onClose={() => this.setState({ stockReplacingCompliance: undefined })}
                            refreshData={this.refreshData}
                        />
                    </PackmanDialog >
                    <Dialog open={!!this.state.requestExportCertificate}>
                        <div className={'p20'}>
                            Select Exporting Inspector
                            <div className={'h10'} />
                            <Select
                                className={'wfill'}
                                value={this.state.selectedExporter || 'PPECB'}
                                onChange={e => this.setState({ selectedExporter: e.target.value })}
                            >
                                <MenuItem value={'PPECB'}>PPECB</MenuItem>
                                <MenuItem value={'AMTA'}>AMTA</MenuItem>
                            </Select>
                            <div className={'fdr mt10 mr10 ml10'}>
                                <Button className={'m5'} variant='contained' color='primary' onClick={this.exportCertificate}>
                                    Ok
                                </Button>
                                <Button className={'m5'} variant='contained' color='primary' onClick={this.cancelExportCertificate}>
                                    Cancel
                                </Button>
                            </div>
                        </div>
                    </Dialog>
                    {!!deletingCompliance &&
                        <DeleteConfirmationDialog
                            isLoading={this.state.isLoading}
                            onSubmit={this.complianceDeleteConfirmed}
                            onclose={this.closeDeleteConfirmationPopup}
                            isOpen={!!deletingCompliance}
                            title={'Compliance'}/>
                    }
                    <PackmanDialog
                        title='Compliance Wizard'
                        isInfo={true}
                        fullScreen={true}
                        isOpen={this.state.isEditing}
                        onClose={this.closeEditDialog}>
                        {complianceToEdit &&
                            <ComplianceWizard
                                selectedCompliance={complianceToEdit}
                            />
                        }
                    </PackmanDialog >
                    {/* Summary Dialog */}
                    <PackmanDialog
                        title={`Compliance Summary - ${this.state.selectedCompliance?.waybill}`}
                        isInfo={true}
                        fullScreen={true}
                        isOpen={this.state.isShowingComplianceSummary}
                        onClose={this.closeComplianceSummaryDialog}>
                        {this.state.selectedCompliance &&
                            <ComplianceSummary
                                setWaybill={this.setSelectedComplianceWaybill}
                                consignments={this.state.consignments}
                                selectedComplianceId={this.state.selectedCompliance.id}
                                refreshData={this.refreshData}
                            />
                        }
                    </PackmanDialog >
                    <PackmanDialog
                        isOpen={this.state.isIntegrationDetailsOpen}
                        onClose={this.closeIntegrationDetailsPopup}
                        isInfo
                        fullScreen
                        title='Integration Details' >
                        <TextField
                            value={
                                '-- Tokens --\n\n' +
                                `PPECB Token: ${this.props.auth.ppecbToken?.token}\n` +
                                `TUM Token: ${this.props.auth.turToken?.access_token}\n\n\n` +

                                '-- Endpoints --\n\n' +
                                `TUM Token Request: ${PACKMAN_API_URL}Consignment/TURToken  ==>  https://uas.ecert.co.za/oauth2/token\n` +
                                `PPECB Token Request: ${PPECB_API_URL}oauth/ApiAuth\n` +
                                `Agreement Code Request: ${PACKMAN_API_URL}Consignment/AgreementCode  ==>  https://app.ecert.co.za/api/v1/Agreement/Get\n` +
                                `TUM Submit Request: ${PACKMAN_API_URL}Consignment/PostTUMELot  ==>  http://${TUM_API_URL}TrackingUnit/eLot\n` +
                                `PPECB Submit Request: ${PPECB_API_URL}pi/ProductInspection/consignment\n` +
                                `PPECB Get Status Request: ${PPECB_API_URL}pi/ProductInspection/inspectionResult\n`
                            }
                            multiline
                            variant={'outlined'}
                            disabled
                            className={'mnw800 mxh800 m5 ml20 mr20'}
                        />
                        <div className={'fdr m10 jcfe'}>
                            <Button className={'m5'} variant='contained' color='primary' onClick={this.downloadIntegrationJSON}>
                                Download Json
                            </Button>
                            <div className={'flx1'} />
                            <Button className={'m5'} variant='contained' color='primary' onClick={this.closeIntegrationDetailsPopup}>
                                Ok
                            </Button>
                        </div>
                    </PackmanDialog>
                    <PackmanDialog
                        isOpen={this.state.isStatusMessageOpen}
                        onClose={this.closeStatusMessagePopup}
                        isInfo
                        title='Get Status' >
                        <div className='flx1 fdc'>
                            <div className='fdr pt10'>
                                <div className='flx1 fdc pl20'>
                                    {'Retrieving Status'}
                                </div>
                                <div className='flx1 fdc'>
                                    {this.getStatusIndicator(this.state.retrievingStatusStatus)}
                                </div>
                            </div>
                        </div>
                        <div className='fdc pt10 pb10 ml20 mr20'>
                            {'Status Response :'}
                        </div>
                        <TextField
                            value={this.state.statusResponse}
                            multiline
                            variant={'outlined'}
                            disabled
                            className={'mnw400 mxh800 m5 pl20 pr20'}
                        />
                        <div className={'fdr m10 jcfe'}>
                            {
                                this.hasAdminRight(this.props) &&
                                    <Button className={'m5'} variant='contained' color='primary' onClick={this.downloadStatusJSONDetails}
                                        disabled={this.state.retrievingStatusStatus === 'loading' || this.state.retrievingStatusStatus === 'unstarted'}
                                    >
                                        Download Details
                                    </Button>
                            }
                            <div className='flx1' />
                            <Button className={'m5'} variant='contained' color='primary' onClick={this.closeStatusMessagePopup}>
                                Ok
                            </Button>
                        </div>
                    </PackmanDialog>
                    {isMessageOpen &&
                        <PackmanDialog
                            isOpen={isMessageOpen}
                            onClose={this.closeMessagePopup}
                            maxWidth={'lg'}
                            isInfo
                            title={`Response - ${this.state.downloadPopupCompliance?.waybill ?? ''}`} >
                            <div className='flx1 fdc'>
                                <div className='fdr pt10'>
                                    <div className='flx1 fdc pl20'>
                                        {'Obtaining Tokens'}
                                    </div>
                                    <div className='flx1 fdc'>
                                        {this.getStatusIndicator(this.state.obtainingTokensStatus)}
                                    </div>
                                </div>
                                <div className='fdr pt10'>
                                    <div className='flx1 fdc pl20'>
                                        {'Submitting to TUM'}
                                    </div>
                                    <div className='flx1 fdc'>
                                        {this.getStatusIndicator(this.state.submitToTUMStatus)}
                                    </div>
                                </div>
                                <div className='fdr pt10 pb10'>
                                    <div className='flx1 fdc pl20'>
                                        {'Submitting to PPECB'}
                                    </div>
                                    <div className='flx1 fdc'>
                                        {this.getStatusIndicator(this.state.submitToPPECBStatus)}
                                    </div>
                                </div>
                            </div>
                            <div className={'fdr wfill pl20 pr20'}>
                                <div className={'fdc flx1 mr10'}>
                                    <div className='fdc pt10 pb10 pr20'>
                                        {'TUM Response :'}
                                    </div>
                                    {this.renderTUMOrPPECBErrorResponse(turResponse)}
                                </div>
                                <div className={'fdc flx1'}>
                                    <div className='fdc pt10 pb10 pr20'>
                                        {'PPECB Response :'}
                                    </div>
                                    {this.renderTUMOrPPECBErrorResponse(ppecbResponse)}
                                </div>
                            </div>
                            <div className={`${this.hasAdminRight(this.props) && (this.state.submitToTUMStatus === 'failed' || this.state.submitToPPECBStatus === 'failed') ? 'fdr m10' : 'dn'}`}>
                                <AutocompleteSelect
                                    className={'flx1'}
                                    name={'agreementCode'}
                                    label={'Agreement Code'}
                                    options={this.getAgreementCodeOptions(this.props)}
                                    onChange={this.setSelectedAgreementCode}
                                    value={this.state.selectedAgreementCode ?? []}
                                />
                                <div className={'w10'} />
                                {
                                    this.hasAdminRight(this.props) &&
                                        <Button className={'m5'} variant='contained' color='primary' onClick={this.onInspectionResubmit} disabled={!this.state.selectedAgreementCode}>
                                            Resubmit
                                        </Button>
                                }
                            </div>
                            <div className={'fdr m10 jcfe'}>
                                {
                                    this.hasAdminRight(this.props) &&
                                        <Button className={'m5'} variant='contained' color='primary' onClick={this.downloadJSONDetails}
                                            disabled={this.state.obtainingTokensStatus === 'loading' || this.state.obtainingTokensStatus === 'unstarted'
                                            || this.state.submitToTUMStatus === 'loading' || this.state.submitToTUMStatus === 'unstarted'
                                            || this.state.submitToPPECBStatus === 'loading' || this.state.submitToPPECBStatus === 'unstarted'}
                                        >
                                            Download Details
                                        </Button>
                                }
                                <div className='flx1' />
                                {
                                    this.hasAdminRight(this.props) &&
                                        <Button className={'m5'} variant='contained' color='primary' onClick={this.updateConsignment}
                                            disabled={this.state.obtainingTokensStatus === 'loading' || this.state.obtainingTokensStatus === 'unstarted'
                                        || this.state.submitToTUMStatus === 'loading' || this.state.submitToTUMStatus === 'unstarted'
                                        || this.state.submitToPPECBStatus === 'loading' || this.state.submitToPPECBStatus === 'unstarted'}
                                        >
                                            Update Consignment
                                        </Button>
                                }
                            </div>
                            <div className={'fdr ml10 mr10 jcfe'}>
                                {
                                    this.hasAdminRight(this.props) &&
                                        <Button className={'m5'} variant='contained' color='primary' onClick={this.deleteConsignment}
                                            disabled={this.state.obtainingTokensStatus === 'loading' || this.state.obtainingTokensStatus === 'unstarted'
                                        || this.state.submitToTUMStatus === 'loading' || this.state.submitToTUMStatus === 'unstarted'
                                        || this.state.submitToPPECBStatus === 'loading' || this.state.submitToPPECBStatus === 'unstarted'}
                                        >
                                            Delete Consignment
                                        </Button>
                                }
                                <div className='flx1' />
                                <Button className={'m5'} variant='contained' color='primary' onClick={this.closeMessagePopup}
                                    disabled={this.state.obtainingTokensStatus === 'loading' || this.state.obtainingTokensStatus === 'unstarted'
                                    || this.state.submitToTUMStatus === 'loading' || this.state.submitToTUMStatus === 'unstarted'
                                    || this.state.submitToPPECBStatus === 'loading' || this.state.submitToPPECBStatus === 'unstarted'}
                                >
                                    Ok
                                </Button>
                            </div>
                            <div className={'fdr ml10 mr10 jcfe'}>
                                {
                                    this.hasAdminRight(this.props) &&
                                        <Button className={'m5'} variant='contained' color='primary' onClick={this.deleteConsignmentOnlyPPECB}
                                            disabled={this.state.obtainingTokensStatus === 'loading' || this.state.obtainingTokensStatus === 'unstarted'
                                        || this.state.submitToTUMStatus === 'loading' || this.state.submitToTUMStatus === 'unstarted'
                                        || this.state.submitToPPECBStatus === 'loading' || this.state.submitToPPECBStatus === 'unstarted'}
                                        >
                                            Delete Consignment PPECB Only
                                        </Button>
                                }
                                <div className='flx1' />
                            </div>
                        </PackmanDialog>
                    }
                    { !!downloadPopupCompliance &&
                        <PackmanDialog isLoading={isLoading} maxWidth={'md'} fullWidth isOpen={!!downloadPopupCompliance} isInfo title={'SELECT AN ACTION' + ' - ' + downloadPopupCompliance.waybill} onClose={this.closeDownloadPopup}>
                            {isLoading &&
                                <div className={'posa post0 posr0 posb0 posl0 aic jcc zi1000'}>
                                    <div className={'posr aic jcc h50 w50'}>
                                        <div className={'posa post0 posr0 posb0 posl0 aic jcc'}>
                                            <img height={40} src={`${ASSET_BASE}/assets/images/ZZ2_Pallets.png`} />
                                        </div>
                                        <CircularProgress color={'primary'} className={'posa post0 posr0 posb0 posl0'} size={50} />
                                    </div>
                                </div>
                            }
                            <div className={'fdc wfill hfill jcc aic pt10'}>
                                { downloadPopupCompliance.status === 'Approved' &&
                                    <div className={'fdr aic flx1 pl10 pr10 mb20'}>
                                        <Typography className={'fs14 mr5'}>PI Uploaded?</Typography>
                                        <BooleanFlag value={downloadPopupCompliance.piUploaded ?? false}/>
                                    </div>
                                }
                                <div className={'fdr'}>
                                    <div className={'fdc flx1 ml10 mr10'}>
                                        { ['Planned', 'Inspecting'].some(x => x === downloadPopupCompliance.status)  &&
                                        <div>
                                            <PopupOptionButton
                                                text={'SUBMIT FOR INSPECTION'}
                                                widthClass='w400'
                                                icon ={<GetAppIcon/>}
                                                disabled={isLoading || !downloadPopupCompliance.complianceLines.some(x => x.isActive && !x.isRejected)}
                                                onClick={this.submitForInspection}
                                            />
                                        </div>
                                        }
                                        { ['Approved'].some(x => x === downloadPopupCompliance.status) &&
                                            <PopupOptionButton
                                                text={'UPLOAD PI FILE'}
                                                widthClass='w400'
                                                icon={<CloudUploadIcon/>}
                                                disabled={isLoading}
                                                onClick={this.onPIFileUpload}
                                            />
                                        }
                                        { ['Approved'].some(x => x === downloadPopupCompliance.status) &&
                                            <PopupOptionButton
                                                text={'EMAIL PI FILE'}
                                                widthClass='w400'
                                                icon={<CloudUploadIcon/>}
                                                disabled={this.state.isLoading}
                                                onClick={this.showEmailForm}
                                            />
                                        }
                                        { ['Approved', 'Partially Rejected', 'Closed'].some(x => x === downloadPopupCompliance.status) &&
                                        <div>
                                            <PopupOptionButton
                                                text={'UPLOAD COMPLIANCE FILES'}
                                                widthClass='w400'
                                                icon ={<CloudUploadIcon/>}
                                                disabled={isLoading}
                                                onClick={this.openInspectionCertificateUploadDialog}
                                            />
                                        </div>
                                        }
                                        {
                                            this.hasAdminRight(this.props) &&
                                            <PopupOptionButton
                                                text={'INTEGRATION DETAILS'}
                                                widthClass='w400'
                                                icon ={<InfoIcon/>}
                                                disabled={isLoading}
                                                onClick={this.openIntegrationDetailsPopup}
                                            />
                                        }
                                    </div>

                                    <div className={'fdc flx1 ml10 mr10'}>
                                        {   downloadPopupCompliance.status !== 'Partially Rejected' &&
                                            <PopupOptionButton
                                                text={'DOWNLOAD FINDING SHEET'}
                                                disabled={isLoading}
                                                widthClass='w400'
                                                icon={<ImportExportIcon className=''/>}
                                                onClick={this.downloadFindingSheet}
                                            />
                                        }
                                        { ['Approved', 'Partially Rejected', 'Closed'].some(x => x === downloadPopupCompliance.status) &&
                                            this.requestExportCertificateDownloadButton &&
                                                <PopupOptionButton
                                                    text={'DOWNLOAD EXPORT CERTIFICATE'}
                                                    icon={<PrintIcon/>}
                                                    widthClass='w400'
                                                    disabled={isLoading}
                                                    onClick={this.requestExportCertificateDownloadButton}
                                                />
                                        }
                                    </div>
                                </div>

                            </div>
                        </PackmanDialog>
                    }
                    {!!this.state.isEmailFormOpen &&
                        <PackmanDialog
                            title='Email PI File'
                            isEdit={true}
                            isLoading={this.state.isLoading}
                            isOpen={this.state.isEmailFormOpen}
                            onClose={this.closeEmailForm}>
                            <Formik
                                initialValues={this.generateEmail(this.props, this.state)}
                                isInitialValid={EmailFormValues.formSchema.isValidSync(this.generateEmail(this.props, this.state))}
                                onSubmit={this.onEmailSubmit}
                                onReset={this.onEmailReset}
                                enableReinitialize
                                validationSchema={EmailFormValues.formSchema}
                                component={EmailForm} />
                        </PackmanDialog >}
                    {/* Compliance Files Upload Dialog */}
                    { !!openComplianceFileUploadDialog &&
                        <PackmanDialog
                            title={'Upload Compliance Files'}
                            isInfo
                            isOpen={!!openComplianceFileUploadDialog}
                            isLoading={isLoading}
                            onClose={this.closeInspectionCertificateUploadDialog}>
                            <Screen isPadded={false} isScrollable={false}>
                                <div style={{ height: 500 }} className={'w400 aic fdc p20'}>
                                    <div style={{ height: 400 }} className={'wfill'}>
                                        <FileSelector
                                            accept={'image/*,application/pdf'}
                                            files={selectedFiles ? selectedFiles : []}
                                            disabled={isLoading}
                                            onFileSelected={this.handleFileInputChange}
                                            onFileRemoved={this.onFileRemove}
                                        />
                                    </div>
                                    <div className={'flx1'}/>
                                    <div className={'fdr pt10 pb10'}>
                                        <Button
                                            className={'fwb h35'}
                                            variant='text'
                                            color='primary'
                                            onClick={this.closeInspectionCertificateUploadDialog}>
                                                Cancel
                                        </Button>
                                        <PillButton
                                            disabled={selectedFiles.length === 0}
                                            className={'ml15 pl20 pr20 h35'}
                                            text={'Upload File'}
                                            color={'secondary'}
                                            onClick={this.onInspectionCertificateUpload}
                                        />
                                    </div>
                                </div>
                            </Screen>
                        </PackmanDialog >
                    }
                    {   !!actionListPopupCompliance &&
                        <PackmanDialog maxWidth={'sm'} fullWidth isOpen={!!actionListPopupCompliance} isInfo title={'SELECT AN ACTION' + ' - ' + actionListPopupCompliance.waybill} onClose={this.closeActionListPopup}>
                            <div className={'fdc wfill hfill jcc aic pt10'}>
                                <PopupOptionButton
                                    text={'Inspections Actions'}
                                    widthClass='w350'
                                    icon={<SettingsIcon className='' />}
                                    onClick={() => this.openActionPopup(actionListPopupCompliance.id)}
                                />
                                <PopupOptionButton
                                    text={'Download/Upload Compliance files'}
                                    isImage={true}
                                    widthClass='w350'
                                    imgSource={`${getIconLocation()}/downloadsIcon_yellow.svg`}
                                    onClick={() => this.showDownloadPopup(actionListPopupCompliance.id)}
                                />
                                {
                                    (actionListPopupCompliance.status === 'Partially Rejected' && actionListPopupCompliance) &&
                                    <PopupOptionButton
                                        text={'Replace Rejected Stock'}
                                        widthClass='w350'
                                        icon={<Icon>find_replace</Icon>}
                                        onClick={() => this.handleStockReplace(actionListPopupCompliance.id)}
                                    />
                                }
                                {
                                    (actionListPopupCompliance && (actionListPopupCompliance.status === 'Planned' || actionListPopupCompliance.status === 'Draft')) &&
                                    <PopupOptionButton
                                        disabled={actionListPopupCompliance.complianceLines.some(x => x.isRejected)}
                                        text={'Edit'}
                                        widthClass='w350'
                                        icon={<Icon className={'cs'}>edit</Icon>}
                                        onClick={() => this.editCompliance(actionListPopupCompliance.id)}
                                    />
                                }
                                {
                                    (actionListPopupCompliance && (actionListPopupCompliance.status === 'Planned' || actionListPopupCompliance.status === 'Draft')) &&
                                    <PopupOptionButton
                                        text={'Delete'}
                                        widthClass='w350'
                                        icon={<Icon className={'cs'}>delete</Icon>}
                                        onClick={() => this.deleteCompliance(actionListPopupCompliance.id)}
                                    />
                                }
                            </div>
                        </PackmanDialog>
                    }
                    { !!actionPopupCompliance &&
                        <PackmanDialog maxWidth={'sm'} fullWidth isOpen={!!actionPopupCompliance} isInfo title={'SELECT AN ACTION' + ' - ' + actionPopupCompliance.waybill} onClose={this.closeActionPopup}>
                            <div className={'fdc wfill hfill jcc aic pt10'}>
                                <div className={'fdr mt10'}>
                                    <Typography className={'fs14 mr5'}>PPECB Submitted?</Typography>
                                    <BooleanFlag value={actionPopupCompliance.ppecbSubmitted ?? false}/>
                                </div>
                                <div className={'fdr m10'}>
                                    <Typography className={'fs14 mr5'}>TUR Submitted?</Typography>
                                    <BooleanFlag value={actionPopupCompliance.turSubmitted ?? false}/>
                                </div>
                                { (this.hasAdminRight(this.props) && ['Approved', 'Partially Rejected'].some(x => x === actionPopupCompliance.status)) &&
                                    <PopupOptionButton
                                        text={'RESUBMIT TO PPECB'}
                                        icon={<AssignmentTurnedInIcon className=''/>}
                                        onClick={this.onPPECBResubmitClick}
                                    />
                                }
                                { ['Approved', 'Partially Rejected', 'Inspecting'].some(x => x === actionPopupCompliance.status) &&
                                    <PopupOptionButton
                                        text={'GET STATUS'}
                                        icon={<AssignmentTurnedInIcon className=''/>}
                                        onClick={this.onGetStatusClick}
                                    />
                                }
                                {
                                    <PopupOptionButton
                                        text={'VIEW ON INSPECTION'}
                                        icon={<PageviewIcon className=''/>}
                                        onClick={this.onViewOnInspectionClicked}
                                    />
                                }
                                { actionPopupCompliance.status === 'Inspecting' && !!this.hasManualInspectionRight(this.props) &&
                                    <PopupOptionButton
                                        text={'MANUAL INSPECTION'}
                                        icon={<SearchIcon className=''/>}
                                        onClick={this.openManualInspectingCompliancePopup}
                                    />
                                }
                            </div>
                        </PackmanDialog>
                    }
                </div>
            </Screen>
        );
    }
}

const mapStateToProps = (state : IRootState) => {
    return {
        auth: state.auth,
        selectedSiteIds: state.data.selectedSiteIds,
        stocks: state.data.stocks,
        compliances: state.data.compliances,
        organizations: state.masterData.organizations,
        sizes: state.masterData.sizes,
        inventories: state.masterData.inventories,
        farms: state.masterData.farms,
        commodities: state.masterData.commodities,
        varieties: state.masterData.varieties,
        packs: state.masterData.packs,
        marks: state.masterData.marks,
        countries: state.masterData.countries,
        sites: state.masterData.sites,
        contactInfos: state.masterData.contactInfos,
        grades: state.masterData.grades,
        orchards: state.masterData.orchards,
        regions: state.masterData.regions,
        markets: state.masterData.markets,
        inspectionPoints: state.masterData.inspectionPoints,
        colours: state.masterData.colours,
        palletBaseTypes: state.masterData.palletBaseTypes,
        agreementCodes: state.masterData.agreementCodes,
    };
};

const mapDispatchToProps = (dispatcher : Dispatch<RootAction>) => bindActionCreators(
    {
    },
    dispatcher,
);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ComplianceDashboard);
