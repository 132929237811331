import * as React from 'react';
import { Dialog, Typography, IconButton, Icon, Toolbar, AppBar, TextField, Button, CircularProgress, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import { IRootState, IAuthState, DispatchCall, RootAction } from '../../@types/redux';
import { connect } from 'react-redux';
import CustomTextField from '../../components/input/CustomTextField';
import { Form, FormState } from 'informed';
import { isEmptyObject, setArrayElement, addArrayElement, removeArrayElement, uppercase, formatMomentToDatePicker, formatDateTimeToDatePicker } from '../../services/appFunctionsService';
import { generalShowErrorSnackbar, generalShowSuccessSnackbar, generalShowWarningSnackbar } from '../../store/general/Functions';
import uuidv1 from 'uuid';
import { dataSetStock, dataSetStockEditFormRelatedData } from '../../store/data/Functions';
import { IFormApi } from '../../@types/other';
import validationFunctions from '../../services/validationService';
import StockLineForm from './StockLineForm';
import CustomAutoSuggest from '../../components/input/CustomAutoSuggest';
import jQuery from 'jquery';
import CustomTable from '../../components/datagrid/CustomTable';
import CustomDatePicker from '../../components/input/CustomDatePicker';
import OrderViewer from '../order/OrderViewer';
import moment from 'moment';
import ConfirmationPrompt from '../../components/dialog/ConfirmationPrompt';
import { IStock } from '../../@types/model/stock/stock';
import { IOrganization } from '../../@types/model/masterData/organization/organization';
import { ISite } from '../../@types/model/masterData/site/site';
import { IFarm } from '../../@types/model/masterData/farm/farm';
import { IMark } from '../../@types/model/masterData/mark/mark';
import { IMarket } from '../../@types/model/masterData/market/market';
import { IRegion } from '../../@types/model/masterData/region/region';
import { ICountry } from '../../@types/model/masterData/country/country';
import { ICommodity } from '../../@types/model/masterData/commodity/commodity';
import { IVariety } from '../../@types/model/masterData/variety/variety';
import { IPack } from '../../@types/model/masterData/pack/pack';
import { ISize } from '../../@types/model/masterData/size/size';
import { IGrade } from '../../@types/model/masterData/grade/grade';
import { IBatch } from '../../@types/model/batch/batch';
import { IColour } from '../../@types/model/masterData/colour/colour';
import { IOrchard } from '../../@types/model/masterData/orchard/orchard';
import { IPalletBaseType } from '../../@types/model/masterData/palletBaseType/palletBaseType';
import { IOrderHeader } from '../../@types/model/order/orderHeader';
import { IStockLine } from '../../@types/model/stock/stockLine';
import PillButton from '../../components/input/PillButton';
import { createSelector } from 'reselect';
import lodash from 'lodash';
import CustomToggleButton from '../../components/input/CustomToggleButton';
import CustomTextInput from '../../components/input/CustomTextInput';
import PackmanDialog from '../../components/dialog/PackmanDialog';
import AutocompleteSelect from '../../components/input/AutoCompleteSelect';
import { IMaterialStock } from '../../@types/model/materialStock/materialStock';
import { CustomChangeEvent, IOptionType } from '../../@types/helper';
import { IAllocateMaterialStock } from '../../@types/model/stock/allocateMaterialsToStock';
import StockHttpService from '../../services/http/stock/stockHttpService';
import { IMaterialsOnStock } from '../../@types/model/stock/materialsOnStock';
import { IMaterialStockLine } from '../../@types/model/materialStock/materialStockLine';
import { IInventory } from '../../@types/model/masterData/inventory/inventory';
import { setSelectedInventoryLocalStorage } from '../../services/localStorageService';
import { dataSetBatches, dataSetMaterialStocks } from '../../store/data/Actions';
import { Dispatch, bindActionCreators } from 'redux';
import { IMaterial } from '../../@types/model/masterData/material/material';
import { dataSetMaterials } from '../../store/masterData/Actions';
import { IStockView } from '../../@types/model/stock/stockView';
import { IProject } from '../../@types/model/masterData/project/project';
import { RouteComponentProps, withRouter } from 'react-router';
import { syncMasterData } from '../../services/masterDataSyncService';

interface IStockFormProps extends RouteComponentProps {
    setSelectedInventory : (inventory ?: IInventory) => void;
    dataSetMaterialStocks : DispatchCall<Array<IMaterialStock>>;
    dataSetBatches : DispatchCall<Array<IBatch>>;
    dataSetMaterials : DispatchCall<Array<IMaterial>>;
    selectedStockView ?: IStockView;
    stocks : Array<IStock>;
    organizations : Array<IOrganization>;
    sites : Array<ISite>;
    farms : Array<IFarm>;
    marks : Array<IMark>;
    markets : Array<IMarket>;
    regions : Array<IRegion>;
    countries : Array<ICountry>;
    commodities : Array<ICommodity>;
    varieties : Array<IVariety>;
    packs : Array<IPack>;
    sizes : Array<ISize>;
    grades : Array<IGrade>;
    batches : Array<IBatch>;
    colours : Array<IColour>;
    orchards : Array<IOrchard>;
    palletBaseTypes : Array<IPalletBaseType>;
    orderHeaders : Array<IOrderHeader>;
    isEditing ?: boolean;
    isAdding : boolean;
    onClose : () => void;
    setLoading : (loading : boolean) => void;
    refreshData : (noAnnounce ?: boolean) => void;
    auth : IAuthState;
    selectedOrganizationIds : Array<number> ;
    selectedSiteIds : Array<number> ;
    materialStocks : Array<IMaterialStock>;
    inventoryData : Array<IInventory>;
    selectedInvetory ?: IInventory;
    filterFromDate : moment.Moment;
    filterToDate : moment.Moment;
    materials : Array<IMaterial>;
    projects : Array<IProject>;
    hasSuperAdminRight : boolean;
}
interface IStockFormState {
    isLoading : boolean;
    selectedStock ?: IStock;
    stockId : number;
    materialStockData : Array<IMaterialStock>;
    currentStockLines : Array<IStockLine>;
    currentStock ?: IStock;
    setStockLineFormValues : Array<(values : IStockLine) => void>;
    prevScrollPos : number;
    editingStockLine ?: IStockLine;
    isEditingStockline : boolean;
    formValid : boolean;
    openOrderViewer : boolean;
    selectedOrder : number;
    newCountryValue ?: number;
    showConfirmationPrompt : boolean;
    stockLinesCaptureExpanded : boolean;
    stockSummaryCaptureExpanded : boolean;
    stockLinesUpdated : boolean;
    clearOrganizations : boolean;
    currentMarketId ?: number;
    selectedOrganization : number;
    selectedCurrentOrganization : number;
    selectedSite : number;
    createdStockBarcode ?: string;
    showStockCreatedDialog : boolean;
    showClosingStockFormDialog : boolean;
    showAllocateMaterialToStockDialog : boolean;
    selectedMaterialStock ?: IOptionType;
    materialStockAmount ?: number;
    materialsToBeAllocatedToStock : Array<IAllocateMaterialStock>;
    allocatedMaterialsToStock : Array<IAllocateMaterialStock>;
    updatedAllocatedMaterialsToStock : Array<IAllocateMaterialStock>;
    currentMaterialStockOnSelectedStock : Array<IAllocateMaterialStock>;
    selectedMaterial ?: IOptionType;
    isClearingStockLines : boolean;
    isTemporary : boolean;
    comment : string;
    reportFile : string;
    showOrchardComplianceCodeWarningDialog : boolean;

    selectedCommodityId : number;
    showStockLineForm : boolean;
}

class StockForm extends React.Component<IStockFormProps, IStockFormState> {
    private formApi : IFormApi<IStock>;
    constructor(props : IStockFormProps) {
        super(props);

        this.state = {
            isLoading: false,
            stockId: 0,
            materialStockData: [],
            currentStockLines: [],
            setStockLineFormValues: [],
            prevScrollPos: 0,
            isEditingStockline: false,
            formValid: false,
            stockLinesUpdated : false,
            openOrderViewer: false,
            selectedOrder: 0,
            selectedOrganization: 0,
            selectedCurrentOrganization: 0,
            selectedSite: 0,
            showConfirmationPrompt: false,
            stockLinesCaptureExpanded: false,
            stockSummaryCaptureExpanded: false,
            clearOrganizations: false,
            isClearingStockLines: false,
            isTemporary: false,
            showStockCreatedDialog: false,
            showClosingStockFormDialog: false,
            showAllocateMaterialToStockDialog: false,
            materialsToBeAllocatedToStock: [],
            allocatedMaterialsToStock: [],
            updatedAllocatedMaterialsToStock: [],
            currentMaterialStockOnSelectedStock: [],
            comment: '',
            reportFile: 'PalletLabel.repx',
            showOrchardComplianceCodeWarningDialog: false,

            selectedCommodityId: 0,
            showStockLineForm: false,
        };

    }

    public componentDidMount = async () => {
        this.setLoading(true);

        // checks if indexedDB is available.
        const isIndexedDBAvailable = !!self.indexedDB ? true : false;
        if (isIndexedDBAvailable) {
            await syncMasterData(false);
        }

        if (this.props.selectedStockView) {
            try {
                const res = await StockHttpService.getStockEditFormData(this.props.selectedStockView.currentSiteId, this.props.selectedStockView.id, !isIndexedDBAvailable);

                if (res.data) {
                    dataSetStockEditFormRelatedData(res.data);
                    this.setState({ selectedStock: res.data.stock, materialStockData: res.data.materialStocks }, () => {
                        this.setState({
                            stockId: this.props.selectedStockView?.id ?? 0,
                            selectedOrganization: this.props.selectedStockView?.currentOrganizationId ?? 0,
                            currentMarketId: this.state.selectedStock?.marketId,
                            currentStockLines: this.getStockLines(),
                            allocatedMaterialsToStock: this.getAllocatedMaterials() ?? [],
                            currentMaterialStockOnSelectedStock: this.getAllocatedMaterials() ?? [],
                            updatedAllocatedMaterialsToStock: this.getAllocatedMaterials() ?? [],
                            isLoading: false,
                        }, () => {
                            if (this.state.selectedStock && this.state.selectedStock.orderId) {
                                this.onOrderViewerSubmit(this.state.selectedStock.orderId);
                            }
                        });
                    });
                }
            } catch (e) {
                generalShowErrorSnackbar('Failed to load stock edit form data');
                this.setLoading(false);
            }
        }
        if (this.props.isAdding) {
            const siteId = this.props.selectedSiteIds[0];
            try {
                const res = await StockHttpService.getStockEditFormData(siteId, undefined, !isIndexedDBAvailable);
                dataSetStockEditFormRelatedData(res.data);

                let selectedOrganization = 0;
                let selectedCurrentOrganization = 0;
                if (this.props.selectedOrganizationIds?.length === 1) {
                    selectedOrganization = this.props.selectedOrganizationIds[0];
                    selectedCurrentOrganization = this.props.selectedOrganizationIds[0];
                }
                this.setState({ currentStockLines: this.getStockLines(), selectedOrganization, selectedCurrentOrganization }, () => {
                    this.setLoading(false);
                });
            } catch (e) {
                generalShowErrorSnackbar('Failed to load stock edit form data');
                this.setLoading(false);
            }
        }
    };

    private getAllocatedMaterials = () => {
        const selectedStock = this.state.selectedStock;
        const materialStocks = this.state.materialStockData;

        if (selectedStock && materialStocks) {
            let lines : Array<IMaterialStockLine> = [];
            materialStocks.filter(x => x.isActive).forEach((x) => {
                x.materialStockLines.filter(y => y.isActive).forEach((y) => {
                    if (y.stockId === selectedStock.id) {
                        lines = addArrayElement(lines, y);
                    }
                });
            });

            const incomingLines = lines.filter(x => x.isIncoming);
            const allocatedLines = lines.filter(x => x.isAllocation && !x.isIncoming);

            const linesGroupedByMaterialStock = lodash.groupBy(allocatedLines, x => x.materialStockId);

            if (lines.length > 0) {
                return lodash.map(linesGroupedByMaterialStock, (materialStockLines) => {
                    const firstLine = materialStockLines[0];

                    const incomingAmounts = incomingLines.filter(y => y.materialStockId === firstLine.materialStockId).map(x => x.amount);
                    const incomingAmount = lodash.sum(incomingAmounts);

                    const allocatedAmount = lodash.sumBy(materialStockLines, x => x.amount);

                    const amount = allocatedAmount - incomingAmount;

                    return { stockId: firstLine.stockId, amount, materialStock: this.getMaterialStock(firstLine.materialStockId) ?? { label: '', value: 0 } };
                });
            }
        }
    };

    private getMaterialStock = (materialStockId : number) => {
        const materialStocks = this.state.materialStockData;
        const materialStock = materialStocks.find(x => x.id === materialStockId);

        if (materialStock) {
            return { label: materialStock.dateCode, value: materialStock.id };
        }
    };

    private onCreateAnotherStock = () => {
        this.setState({ showStockCreatedDialog: false, isLoading: false });
    };

    private close = () => {
        this.setState({
            isLoading: false,
            stockId: 0,
            currentStockLines: [],
            setStockLineFormValues: [],
            prevScrollPos: 0,
            isEditingStockline: false,
            formValid: false,
            stockLinesUpdated : false,
            openOrderViewer: false,
            selectedOrder: 0,
            selectedOrganization: 0,
            selectedCurrentOrganization: 0,
            selectedSite: 0,
            showConfirmationPrompt: false,
            showStockCreatedDialog: false,
            showClosingStockFormDialog: false,
            currentStock: undefined,
            newCountryValue: undefined,
            currentMarketId: undefined,
            clearOrganizations: true,
            editingStockLine: undefined,
            stockLinesCaptureExpanded: false,
            materialsToBeAllocatedToStock: [],
            allocatedMaterialsToStock: [],
            updatedAllocatedMaterialsToStock: [],
            currentMaterialStockOnSelectedStock: [],
            comment: '',
            reportFile: 'PalletLabel.repx',
            showOrchardComplianceCodeWarningDialog: false,
            stockSummaryCaptureExpanded: false,
            isClearingStockLines: false,
            isTemporary: false,
            showAllocateMaterialToStockDialog: false,
            selectedCommodityId: 0,
        });
        this.formApi.reset();
        this.props.onClose();
        this.props.refreshData(true);
    };

    private clearSelectStockLine = () => {
        this.setState({
            setStockLineFormValues: [],
            editingStockLine: undefined,
            isEditingStockline: false,
        });
    };

    private resetForm = () => {
        this.setState({
            isLoading: false,
            stockId: 0,
            currentStockLines: [],
            setStockLineFormValues: [],
            prevScrollPos: 0,
            isEditingStockline: false,
            formValid: false,
            stockLinesUpdated : false,
            openOrderViewer: false,
            selectedOrder: 0,
            selectedOrganization: 0,
            selectedCurrentOrganization: 0,
            selectedSite: 0,
            showConfirmationPrompt: false,
            showStockCreatedDialog: false,
            showClosingStockFormDialog: false,
            stockLinesCaptureExpanded: false,
            currentStock: undefined,
            newCountryValue: undefined,
            currentMarketId: undefined,
            clearOrganizations: true,
            materialsToBeAllocatedToStock: [],
            allocatedMaterialsToStock: [],
            updatedAllocatedMaterialsToStock: [],
            currentMaterialStockOnSelectedStock: [],
            comment: '',
            reportFile: 'PalletLabel.repx',
            showOrchardComplianceCodeWarningDialog: false,
            stockSummaryCaptureExpanded: false,
            isClearingStockLines: false,
            isTemporary: false,
            showAllocateMaterialToStockDialog: false,
        });
        this.formApi.reset();
        this.formApi.validate();
    };

    private setLoading = (loading : boolean = false) => {
        this.setState({ isLoading : loading });
    };

    private getMarketChannel = (marketId : number) => {
        const markets = this.props.markets;
        const market = markets && markets.find(x => x.id === marketId);
        return market ? market.channel : '';
    };

    private getStockLines = () => {
        const selectedStock = this.state.selectedStock;
        return selectedStock ? selectedStock.stockLines : [];
    };

    private getOrganizations = (props : IStockFormProps) => props.organizations;
    private getSites = (props : IStockFormProps) => props.sites;
    private getMarkets = (props : IStockFormProps) => props.markets;
    private getCountries = (props : IStockFormProps) => props.countries;
    private getMarks = (props : IStockFormProps) => props.marks;
    private getInventories = (props : IStockFormProps) => props.inventoryData;

    private getClearOrganizations = (props : IStockFormProps, state : IStockFormState) => state.clearOrganizations;
    private getSelectedOrganizationIdsFromProps = (props : IStockFormProps) => props.selectedOrganizationIds;
    private getSelectedStock = (props : IStockFormProps, state : IStockFormState) => state.selectedStock;
    private getSelectedOrganizationIdsFromState = (props : IStockFormProps, state : IStockFormState) => state.selectedOrganization;
    private getCurrentStock = (props : IStockFormProps, state : IStockFormState) => state.currentStock;

    private getOrganizationOptions = createSelector(
        [this.getOrganizations, this.getClearOrganizations],
        (organizations : Array<IOrganization>, clearOrganizations : boolean) => {
            if (!organizations) return [];
            if (clearOrganizations) {
                this.setState({ clearOrganizations: false });
                return [];
            }
            return lodash.filter(organizations, x => x.isActive).map((x) => {
                return { label: `(${x.code}) ${x.name}`, value: x.id };
            });
        },
    );

    private getOriginalSiteOptions = createSelector(
        [this.getSites],
        (sites : Array<ISite>) => {
            if (!sites) return [];

            return sites.filter(x => x.isActive).map((x) => {
                return { value: x.id, label: `(${x.code}) ${x.description}` };
            });
        },
    );

    private getCurrentSiteOptions = createSelector(
        [this.getSites],
        (sites : Array<ISite>) => {
            if (!sites) return [];

            return lodash.filter(sites, x => x.isActive).map((x) => {
                return { value: x.id, label: `(${x.code}) ${x.description}` };
            });
        },
    );

    private getPalletBaseTypes = (props : IStockFormProps) => props.palletBaseTypes;

    private getPalletBaseTypeOptions = createSelector(
        [this.getPalletBaseTypes],
        (palletBaseTypes : Array<IPalletBaseType>) => {
            if (!palletBaseTypes) return [];

            return lodash.filter(palletBaseTypes, x => x.isActive).map((x) => {
                return { value: x.id, label: `(${x.code}) ${x.name}` };
            });
        },
    );

    private getMarketOptions = createSelector(
        [this.getMarkets, this.getCountries, this.getSelectedStock, this.getSelectedOrganizationIdsFromProps, this.getSelectedOrganizationIdsFromState],
        (markets, countries, selectedStock, selectedOrganizationIdsFromProps, selectedOrganizationFromState) => {
            const selectedOrganization = selectedStock?.originalOrganizationId || selectedOrganizationFromState || (selectedOrganizationIdsFromProps?.length === 1 ? selectedOrganizationIdsFromProps[0] : 0);
            if (!markets || !countries || !selectedOrganization) return [];

            return lodash.filter(markets, x => (countries.findIndex(y => y.regionId === x.regionId) !== -1) && x.isActive).map((x) => {
                return { value: x.id, label: `(${x.code}) ${x.name}` };
            });
        },
    );

    private getCountryOptions = createSelector(
        [this.getCountries, this.getCurrentStock, this.getSelectedStock, this.getSelectedOrganizationIdsFromProps, this.getSelectedOrganizationIdsFromState],
        (countries, currentStock, selectedStock, selectedOrganizationIdsFromProps, selectedOrganizationFromState) => {
            const selectedRegion = currentStock && this.getRegionId(currentStock.marketId);
            const selectedOrganization = selectedStock?.originalOrganizationId || selectedOrganizationFromState || (selectedOrganizationIdsFromProps?.length === 1 ? selectedOrganizationIdsFromProps[0] : 0);
            if (!countries || !selectedOrganization) return [];

            return lodash.filter(countries, x => x.isActive && x.regionId === Number(selectedRegion)).map((x) => {
                return { value: x.id, label: `(${x.code}) ${x.name}` };
            });
        },
    );

    private getMarkOptions = createSelector(
        [this.getMarks],
        (marks : Array<IMark>) => {
            if (!marks) return [];

            return lodash.filter(marks, x => x.isActive).map((x) => {
                return { value: x.id, label: `(${x.code}) ${x.description}` };
            });
        },
    );

    private getInventoryOptions = createSelector(
        [this.getInventories],
        (inventoryData : Array<IInventory>) => {
            if (!inventoryData) return [];

            return lodash.filter(inventoryData, x => x.isActive).map((x) => {
                return { value: x.id, label: `(${x.code}) ${x.description}` };
            });
        },
    );

    private getStocklineEstGrossWeight = (stockline : IStockLine) => {
        if (!stockline || !stockline.packId || !stockline.cartons) {
            return 0;
        }
        const pack = this.props.packs.find(x => x.id === stockline.packId);
        if (!pack) {
            return 0;
        }

        return Number(pack.grossWeight) * Number(stockline.cartons);
    };

    private getRights = (props : IStockFormProps) => props.auth?.session?.user?.rights || [];
    private getPathName = (props : IStockFormProps) => props.location.pathname;

    private hasStockSuperAdminRight = createSelector(
        [this.getRights, this.getPathName],
        (rights, url) => rights.some(x => url.includes(x.url) && x.isActive && x.code.endsWith('_EDIT_SUPER_ADMIN')));

    private getStocklineEstNettWeight = (stockline : IStockLine) => {
        if (!stockline || !stockline.packId || !stockline.cartons) {
            return 0;
        }
        const pack = this.props.packs.find(x => x.id === stockline.packId);
        if (!pack) {
            return 0;
        }

        return Number(pack.nettWeight) * Number(stockline.cartons);
    };

    private totalEstGrossWeight = () => {
        let total = 0;
        const stock = this.state.currentStock ? { ...this.state.currentStock } : undefined;
        if (stock && stock.palletBaseTypeId) {
            const palletBaseType = this.props.palletBaseTypes.find(x => x.id === stock.palletBaseTypeId);
            if (palletBaseType && palletBaseType.defaultWeight) {
                total += Number(palletBaseType.defaultWeight);
            }
        }
        this.state.currentStockLines.forEach(x => total += this.getStocklineEstGrossWeight(x));
        return total;
    };

    private totalEstNettWeight = () => {
        let total = 0;
        this.state.currentStockLines.forEach(x => total += this.getStocklineEstNettWeight(x));
        return total;
    };

    private stockLinesScrolled = () => {
        const scrollArea = document.getElementById('stockline_scrollArea');
        const scrolledValue = scrollArea && scrollArea.scrollTop;
        const fixedElements = jQuery('div[id*=dropdown]');
        fixedElements.toArray().forEach((x) => {
            x.style.marginTop = scrolledValue ? (0 - scrolledValue).toString() + 'px' : '';
        });
    };

    private checkOrchardComplianceCode = (row : IStockLine) => {
        const currentMarket = this.props.markets.find(x => x.id === this.state.currentMarketId);
        const orchard = this.props.orchards.find(x => x.id === row.orchardId);

        if (currentMarket?.channel === 'E' && !orchard?.complianceCode) {
            return true;
        } else {
            return false;
        }
    };

    private preSubmitCheckForOrchards = () => {
        const currentMarket = this.props.markets.find(x => x.id === this.state.currentMarketId);
        const orchardIds = this.state.currentStockLines.map(x => x.orchardId);
        const orchards = this.props.orchards.filter(x => orchardIds.some(y => y === x.id));

        if (currentMarket?.channel === 'E' && orchards.some(x => !x.complianceCode)) {
            this.setState({ showOrchardComplianceCodeWarningDialog: true });
        } else {
            this.submit();
        }
    };

    private onOrchardComplianceCodeWarningClose = () => {
        this.setState({ showOrchardComplianceCodeWarningDialog: false });
    };

    private isAllocatedMaterialsToStockChanges = () => {
        const alreadyAllocatedMaterials = this.state.currentMaterialStockOnSelectedStock;
        const newAllocatedMaterials = this.state.allocatedMaterialsToStock;

        if (alreadyAllocatedMaterials && newAllocatedMaterials
            && alreadyAllocatedMaterials.length === newAllocatedMaterials.length
            && JSON.stringify(alreadyAllocatedMaterials) === JSON.stringify(newAllocatedMaterials)) {
            return true;
        } else {
            return false;
        }
    };

    private submit = async () => {
        this.onOrchardComplianceCodeWarningClose();
        const values = this.formApi.getState().values;
        const touched = this.formApi.getState().touched;
        const valid = !this.formApi.getState().invalid;

        if (!valid) {
            generalShowErrorSnackbar('Check form validation!');
            return;
        }

        if (!!this.isAllocatedMaterialsToStockChanges() && (isEmptyObject(touched) && !this.state.stockLinesUpdated) && (this.state.comment === '' || this.state.selectedStock?.userComment === this.state.comment) && (this.state.selectedStock?.reportFile === this.state.reportFile || this.state.reportFile === 'PalletLabel.repx')) {
            if (this.props.isEditing) {
                generalShowErrorSnackbar('Nothing to update!');
            } else if (this.props.isAdding) {
                generalShowErrorSnackbar('Nothing to add!');
            }
            return;
        }

        const selectedCurrentSite = this.props.sites.find(x => x.id === values.currentSiteId);

        if (selectedCurrentSite && !!selectedCurrentSite.isBRCCompliant && this.state.allocatedMaterialsToStock.length < 1) {
            generalShowErrorSnackbar('Materials is required. Please allocate materials to this pallet.');
            return;
        }

        if (this.state.currentStockLines.length < 1 && !values.isTemporary) {
            generalShowErrorSnackbar('Stock lines are required when stock is not temporary.');
            return;
        }

        if (values.marketId === undefined && !values.isTemporary) {
            generalShowErrorSnackbar('Target market is required when stock is not temporary.');
            return;
        }

        if (!isEmptyObject(touched) || this.state.stockLinesUpdated || this.state.allocatedMaterialsToStock.length !== 0 || this.state.comment !== '' || this.state.reportFile !== 'PalletLabel.repx' || this.state.selectedStock?.reportFile !== this.state.reportFile) {
            this.setLoading(true);
            const newRowModel : IStock = { ...values };
            const selectedOrganization = this.state.selectedStock?.originalOrganizationId || this.state.selectedOrganization || (this.props.selectedOrganizationIds?.length === 1 ? this.props.selectedOrganizationIds[0] : 0);
            const regionId = this.getRegionId(newRowModel.marketId);
            newRowModel.regionId = regionId ? regionId : 0;
            if (this.props.isEditing && this.state.selectedStock) {
                newRowModel.id = this.state.selectedStock.id;
                newRowModel.guid = this.state.selectedStock.guid;
                newRowModel.originalSiteId = this.state.selectedStock.originalSiteId || this.state.selectedStock.currentSiteId;
                newRowModel.barcode = this.state.selectedStock.barcode;
                newRowModel.tempBarcode = this.state.selectedStock.tempBarcode;
                newRowModel.sscc = this.state.selectedStock.sscc;
                newRowModel.createdByName = this.state.selectedStock.createdByName;
            } else if (this.props.isEditing && !this.state.selectedStock) {
                generalShowErrorSnackbar('No stock object selected!');
                this.setLoading(false);
                return;
            }

            if (!selectedOrganization) {
                generalShowErrorSnackbar('Organization not selected!');
                this.setLoading(false);
                return;
            }

            if (this.props.selectedInvetory === undefined) {
                const selectedInventoryId = newRowModel.inventoryId;

                const selectedInventory = this.props.inventoryData.find(x => x.id === selectedInventoryId);

                setSelectedInventoryLocalStorage(selectedInventory);

                this.props.setSelectedInventory(selectedInventory);
            }

            let sequence : number = 1;

            newRowModel.channel = this.getMarketChannel(newRowModel.marketId);
            newRowModel.status = this.state.selectedStock?.status ?? 'In Stock';
            newRowModel.userComment = this.state.comment;
            newRowModel.reportFile = this.state.reportFile;
            newRowModel.hasMixedLoad = this.hasMixedLoad();
            newRowModel.cartons = this.totalCartons();
            newRowModel.estimatedGrossWeight = this.totalEstGrossWeight();
            newRowModel.estimatedNettWeight = this.totalEstNettWeight();
            newRowModel.stockLines = [...this.state.currentStockLines].map((x) => {
                const newStockLineModel = { ...x };
                newStockLineModel.palletSize = x.isActive ? 1 / this.state.currentStockLines.filter(y => y.isActive).length : 0;
                newStockLineModel.estimatedGrossWeight = this.getStocklineEstGrossWeight(newStockLineModel);

                if (!!x.isActive) {
                    newStockLineModel.sequence = sequence;

                    sequence = sequence + 1;
                } else {
                    newStockLineModel.sequence = 0;
                }

                if (this.state.selectedOrder) {
                    newStockLineModel.isDeviatedFromOrder = this.isDeviated(x);
                } else {
                    newStockLineModel.isDeviatedFromOrder = undefined;
                }
                if (x.id === 0) {
                    newStockLineModel.guid = uuidv1();
                }
                return newStockLineModel;
            });
            if (this.state.allocatedMaterialsToStock.length >= 1) {
                const materials = Array<IMaterialsOnStock>();

                this.state.allocatedMaterialsToStock.forEach((x) => {
                    const material : IMaterialsOnStock = {
                        materialStockId: Number(x.materialStock.value),
                        amount: x.amount,
                        submittedOn: x.submittedOn,
                    };

                    materials.push(material);
                });
                newRowModel.materials = materials;
            }
            if (this.props.isAdding) {
                newRowModel.guid = uuidv1();
                newRowModel.originalSiteId = newRowModel.originalSiteId;
                newRowModel.currentSiteId = newRowModel.currentSiteId;
                newRowModel.originalOrganizationId = newRowModel.originalOrganizationId;
                newRowModel.currentOrganizationId = newRowModel.currentOrganizationId;
                newRowModel.createdIn = 'Web App';
            }
            try {
                const res = await StockHttpService.addOrUpdateStock(newRowModel);

                if (res && res.data) {
                    dataSetStock(res.data);
                    this.setState({ createdStockBarcode: res.data.barcode });
                    generalShowSuccessSnackbar(`Stock ${this.props.isEditing ? 'updated' : 'added'} successfully.`);
                    if (this.props.isAdding) {
                        this.setState({ showStockCreatedDialog: true });
                    } else {
                        this.close();
                        this.props.refreshData(true);
                    }
                } else {
                    generalShowErrorSnackbar(`An error occurred ${this.props.isEditing ? 'updating' : 'adding'} stock data.`);
                    this.setLoading(false);
                }
            } catch (e) {
                if (e.status === 400) {
                    generalShowErrorSnackbar(e.data?.Message);
                } else {
                    generalShowErrorSnackbar(`An error occurred ${this.props.isEditing ? 'updating' : 'adding'} stock data.`);
                }
                this.setLoading(false);
            }
        }
    };

    private onOrganizationChange = (orgId : any) => this.setState({
        selectedOrganization: Number(orgId),
        isClearingStockLines: true,
    });

    private onCurrentOrganizationChange = (orgId : any) => this.setState({
        selectedCurrentOrganization: Number(orgId),
    });

    private onSiteChange = () => this.setState({
        isClearingStockLines: true,
    });

    private onMarketChange = (id : any) => {
        const marketId = Number(id);
        const regionId = this.getRegionId(marketId);
        let countryId = 0;
        if (this.isLocalPallet(marketId) && regionId) {
            countryId = this.getLocalCountryId(regionId);
            this.setState({
                newCountryValue: countryId,
                currentMarketId: marketId,
                isClearingStockLines: true,
            });
        } else {
            this.setState({
                newCountryValue: undefined,
                currentMarketId: marketId,
                isClearingStockLines: true,
            });
        }
    };

    private onPalletBaseTypeChange = () => this.setState({ isClearingStockLines: true });

    private onInventoryChange = (inventoryId : any) => {
        const selectedInventoryId = Number(inventoryId);

        const selectedInventory = this.props.inventoryData.find(x => x.id === selectedInventoryId);

        setSelectedInventoryLocalStorage(selectedInventory);

        this.props.setSelectedInventory(selectedInventory);
    };

    private onStockLineEdit = (row : IStockLine) => {
        if (this.state.editingStockLine) {
            this.setState({ editingStockLine: undefined, showStockLineForm: false }, () => {
                this.setState({
                    isEditingStockline : true,
                    editingStockLine: row,
                    stockLinesCaptureExpanded: true,
                    showStockLineForm: true,
                });
            });
        } else {
            this.setState({ showStockLineForm: false }, () => {
                this.setState({
                    isEditingStockline : true,
                    editingStockLine: row,
                    stockLinesCaptureExpanded: true,
                    showStockLineForm: true,
                });
            });
        }
    };

    private isDeviated = (stockLine : IStockLine) => {
        const order = this.props.orderHeaders.find(x => x.id === this.state.selectedOrder);
        let returnValue = true;

        if (order) {
            order.orderRequestLines.forEach(z => z.orderPlannedLines.forEach((x) => {
                if (x.commodityId === stockLine.commodityId && x.varietyId === stockLine.varietyId && x.packId === stockLine.packId &&
                x.sizeId === stockLine.sizeId && x.gradeId === stockLine.gradeId && x.colourId === stockLine.colourId) {
                    returnValue = false;
                }
            }));
        }
        return returnValue;
    };

    private setFormApi = (formApi : IFormApi<IStock>) => {
        this.formApi = formApi;
    };

    private hasMixedLoad = () => {
        const lines = [...this.state.currentStockLines];
        return lines && lines.length > 1 ? true : false;
    };

    private getNameFromId = (id : number, objects : Array<{name : string; id : number}>) => {
        if (!objects || !(objects.length > 0)) {
            return '';
        }
        const obj = objects.find(x => x.id === id);
        return obj && obj.name ? obj.name : '';
    };

    private getDescriptionFromId = (id : number, objects : Array<{description : string; id : number}>) => {
        if (!objects || !(objects.length > 0)) {
            return '';
        }
        const obj = objects.find(x => x.id === id);
        return obj && obj.description ? obj.description : '';
    };

    private getCodeFromId = (id : number, objects : Array<{code : string; id : number}>) => {
        if (!objects || !(objects.length > 0)) {
            return '';
        }
        const obj = objects.find(x => x.id === id);
        return obj && obj.code ? obj.code : '';
    };

    private onFormChange = (state : FormState<IStock>) => {
        const values = state.values;
        const currentStock = { ...this.state.currentStock };
        if (values && (JSON.stringify(values) !== (currentStock && JSON.stringify(currentStock)))) {
            const newValues = { ...values };
            const regionId = this.getRegionId(newValues.marketId);
            if (!!values.originalOrganizationId && !!values.currentOrganizationId && !!values.originalSiteId && !!values.currentSiteId && !!values.palletBaseTypeId && !!values.markId && !!values.marketId && (values.marketId && this.isLocalPallet(values.marketId) ? true : !!values.countryId)) {
                this.setState({ stockLinesCaptureExpanded: true, showStockLineForm: true });
            } else {
                this.setState({ stockLinesCaptureExpanded: false, showStockLineForm: false });
            }
            if (this.isLocalPallet(newValues.marketId) && regionId) {
                newValues.countryId = this.getLocalCountryId(regionId);
            } else if (regionId) {
                newValues.countryId = this.isValidCountryId(newValues.countryId, regionId) ? newValues.countryId : 0;
            }
            if (JSON.stringify(values) !== JSON.stringify(newValues)) {
                newValues.regionId = regionId ? regionId : 0;
                this.setState({ currentStock: newValues, formValid: !state.invalid  }, () => {
                    if (newValues.countryId) {
                        this.formApi.setValue('countryId', newValues.countryId);
                    }
                });
            } else {
                newValues.regionId = regionId ? regionId : 0;
                this.setState({ currentStock: newValues, formValid: !state.invalid  });
            }
        } else if (state) {
            this.setState({ formValid: !state.invalid });
        }
    };

    private isValidCountryId = (countryId : number, regionId : number) => {
        const valid = this.props.countries.findIndex(x => x.id === countryId && x.regionId === regionId);
        return valid !== -1 ? true : false;
    };

    private isLocalPallet = (marketId ?: number) => {
        const stock = this.state.currentStock && { ...this.state.currentStock };
        if (!this.props.markets) {
            return false;
        }
        const mId = marketId ? marketId : stock ? stock.marketId : 0;
        const market = this.props.markets.find(x => x.id === mId);
        return market && market.channel !== 'E' ? true : false;
    };

    private getLocalCountryId = (regionId : number) => {
        if (!this.props.countries) {
            return 0;
        }
        const country = this.props.countries.find(x => x.regionId === regionId && uppercase(x.name) === 'SOUTH AFRICA');
        return country ? country.id : 0;
    };

    private addStockLine = (stockline : IStockLine) => {
        const currentStockLines : Array<IStockLine> = [...this.state.currentStockLines];
        const oldStockLineIndex = currentStockLines.findIndex(x => x.guid === stockline.guid);
        const orchard = this.props.orchards.find(x => x.id === stockline.orchardId);
        const selectedSite = this.props.sites.find(x => x.id === this.state.currentStock?.currentSiteId);
        const currentMarket = this.props.markets.find(x => x.id === this.state.currentMarketId);

        const activeCurrentStockLines = lodash.orderBy(currentStockLines.filter(x => x.isActive),  line => line.createdOn, 'desc');
        console.log(currentStockLines, oldStockLineIndex, activeCurrentStockLines);
        // const oldActiveStockLineIndex = activeCurrentStockLines.findIndex(x => x.guid === stockline.guid);

        if (oldStockLineIndex !== -1) {
            let sequence = 1;

            const newStockLine = stockline;

            const updatedCurrentStockLines = setArrayElement([...currentStockLines], oldStockLineIndex, newStockLine);

            updatedCurrentStockLines.forEach((line) => {
                console.log(line.sequence, sequence, currentStockLines);

                if (line.isActive) {
                    line.sequence = sequence;

                    sequence = sequence + 1;
                } else {
                    line.sequence = 0;
                }
            });

            // newStockLine.sequence = oldActiveStockLineIndex !== -1 ? oldActiveStockLineIndex + 1 : 0;

            this.setState({ stockLinesUpdated: true, currentStockLines: updatedCurrentStockLines });
            if (orchard && selectedSite && currentMarket && selectedSite.isBRCCompliant && currentMarket.channel === 'E' && !orchard.complianceCode) {
                generalShowWarningSnackbar('Orchard on this stockline does not have a compliance code');
            }
        } else {
            const newStockLine = stockline;
            newStockLine.sequence = stockline.isActive ? activeCurrentStockLines.length + 1 : 0;
            this.setState({ stockLinesUpdated: true, currentStockLines: addArrayElement([...currentStockLines], newStockLine, 'end') });
            if (orchard && selectedSite && currentMarket && selectedSite.isBRCCompliant && currentMarket.channel === 'E' && !orchard.complianceCode) {
                generalShowWarningSnackbar('Orchard on this stockline does not have a compliance code');
            }
        }
    };

    private removeStockLine = (i : number) => this.setState({ currentStockLines: removeArrayElement(this.state.currentStockLines, i) }, this.fixSequenceAfterDelete);

    private removeStockLineAtGuid = (guid : string) => {
        const index = this.state.currentStockLines.findIndex(x => x.guid === guid);
        if (index !== -1) {
            this.setState({ currentStockLines: removeArrayElement(this.state.currentStockLines, index) }, this.fixSequenceAfterDelete);
        }
    };

    private fixSequenceAfterDelete = () => {
        let sequence = 1;
        this.state.currentStockLines.forEach((x, i) => {
            const newStockline = x;

            if (!!newStockline.isActive) {
                newStockline.sequence = sequence;

                sequence = sequence + 1;
            } else {
                newStockline.sequence = 0;
            }
            this.setState({ currentStockLines: setArrayElement(this.state.currentStockLines, i, newStockline) });
        });
    };

    private getCurrentStockLine = (props : IStockFormProps, state : IStockFormState) => state.currentStockLines;

    private getTotalCartons = createSelector(
        [this.getCurrentStockLine],
        (currentStockLines) => {
            let total = 0;
            currentStockLines.filter(x => x.isActive).forEach(x => total += Number(x.cartons));
            return total;
        },
    );

    private totalCartons = () => {
        let total = 0;
        this.state.currentStockLines.filter(x => x.isActive).forEach(x => total += Number(x.cartons));
        return total;
    };

    private getCommodityCode = (commodityId : number) => {
        const commodities = this.props.commodities;
        const commodity = commodities && commodities.find(x => x.id === commodityId);
        return commodity ? commodity.code : '';
    };

    private getVarietyCode = (varietyId : number) => {
        const varieties = this.props.varieties;
        const variety = varieties && varieties.find(x => x.id === varietyId);
        return variety ? variety.code : '';
    };

    private getPackDescription = (packId : number) => {
        const packs = this.props.packs;
        const pack = packs && packs.find(x => x.id === packId);
        return pack ? pack.code : '';
    };

    private getSizeCode = (sizeId : number) => {
        const sizes = this.props.sizes;
        const size = sizes && sizes.find(x => x.id === sizeId);
        return size ? size.code : '';
    };

    private getGradeName = (gradeId : number) => {
        const grades = this.props.grades;
        const grade = grades && grades.find(x => x.id === gradeId);
        return grade ? grade.name : '';
    };

    private getColourCode = (colourId : number) => {
        const colours = this.props.colours;
        const colour = colours && colours.find(x => x.id === colourId);
        return colour ? colour.code : '';
    };

    private getOrchardName = (orchardId : number) => {
        const orchards = this.props.orchards;
        const orchard = orchards && orchards.find(x => x.id === orchardId);
        return orchard ? '(' + orchard.code + ') ' + orchard.name : '';
    };

    private getRegionId = (marketId : number) => {
        if (!this.props.markets) {
            return undefined;
        }
        const market = this.props.markets.find(x => x.id === marketId);
        return market && market.regionId;
    };

    private getFarmName = (farmId : number) => {
        const farms = this.props.farms;
        const farm = farms && farms.find(x => x.id === farmId);
        return farm ? farm.name : '';
    };

    private getProjectNumber = (projectId : number) => {
        const project = this.props.projects.find(x => x.id === projectId);

        return project ? project.number : '';
    };

    private getColumns = () => [
        { title: 'Sequence', field: 'sequence', enableSorting: true, minWidth: 70 },
        { title: 'Commodity', field: 'commodityId', formatFunction: this.getCommodityCode, enableSorting: true, minWidth: 80 },
        { title: 'Variety', field: 'varietyId', formatFunction: this.getVarietyCode, enableSorting: true, minWidth: 70 },
        { title: 'Farm', field: 'farmId', formatFunction: this.getFarmName, enableSorting: true, minWidth: 100 },
        { title: 'Pack', field: 'packId', formatFunction: this.getPackDescription, enableSorting: true, minWidth: 80 },
        { title: 'Size', field: 'sizeId', formatFunction: this.getSizeCode, enableSorting: true, minWidth: 70 },
        { title: 'Grade', field: 'gradeId', formatFunction: this.getGradeName, enableSorting: true, minWidth: 70 },
        { title: 'Colour', field: 'colourId', formatFunction: this.getColourCode, enableSorting: true, minWidth: 70 },
        { title: 'Orchard', field: 'orchardId', formatFunction: this.getOrchardName, enableSorting: true, minWidth: 100 },
        { title: 'Project No.', field: 'projectId', formatFunction: this.getProjectNumber, enableSorting: true, minWidth: 80 },
        { title: 'Cartons', field: 'cartons', enableSorting: true, minWidth: 70 },
        { title: 'Total Inners', field: 'totalInners', enableSorting: true, minWidth: 80 },
        { title: 'Batch', field: 'batchId', formatFunction: this.getBatchCode, enableSorting: true, minWidth: 100 },
    ];

    private getCurrentStockLines = (props : IStockFormProps, state : IStockFormState) => state.currentStockLines;

    private stockLineRows = createSelector(
        [this.getCurrentStockLines],
        (currentStockLines : Array<IStockLine>) => {
            if (!currentStockLines) return [];

            return currentStockLines.map((x, i) => {
                /* hackfix to ensure all the id's arent 0 on the table causing it to display the same line repeatedly */
                if (x.id === 0) {
                    x.id = i;
                }
                return x;
            });
        },
    );

    private openOrderViewer = () => this.setState({ openOrderViewer : true });
    private onOrderViewerClose = () => {
        this.setState({ openOrderViewer: false , selectedOrder: 0 }, () => {
            this.formApi.setValue('orderId', '');
            this.formApi.setTouched('orderId', true);
        });
    };

    private onOrderViewerSubmit = (orderId : number) => {
        this.setState({ selectedOrder : orderId, openOrderViewer: false }, () => {
            this.formApi.setValue('orderId', orderId ? orderId : '');
            this.formApi.setTouched('orderId', true);
        });
    };

    private getBatchCode = (batchId : number) => {
        const batch = this.props.batches.find(x => x.id === batchId);
        return batch && batch.batchCode;
    };

    private getOrderNumber = (orderId : number) => {
        const order = this.props.orderHeaders.find(x => x.id === orderId);
        return order && order.orderNumber;
    };

    private getCurrentOrgValue = () => {
        if (this.state.selectedStock && this.state.selectedStock.currentOrganizationId !== 0) {
            return this.state.selectedStock.currentOrganizationId;
        } else if (this.state.selectedOrganization !== 0) {
            return this.state.selectedOrganization;
        } else if (this.props.selectedOrganizationIds?.length === 1) {
            return this.props.selectedOrganizationIds[0];
        } else {
            return '';
        }
    };

    private toggleFormCancelDialog = () => {
        this.setState({ showClosingStockFormDialog: !this.state.showClosingStockFormDialog });
    };

    private openMaterialAllocationToStockDialog = () => {
        this.setState({ showAllocateMaterialToStockDialog: true });
    };

    private closeMaterialAllocationToStockDialog = () => {
        const allocatedMaterialsToStock = [...this.state.allocatedMaterialsToStock];

        this.setState({
            showAllocateMaterialToStockDialog: false,
            selectedMaterialStock: undefined,
            materialStockAmount: undefined,
            materialsToBeAllocatedToStock: [],
            updatedAllocatedMaterialsToStock: allocatedMaterialsToStock,
            selectedMaterial: undefined,
        });
    };

    private getMaterialStocks = (props : IStockFormProps) => props.materialStocks;
    private getSelectedSites = (props : IStockFormProps) => props.selectedSiteIds;
    private getMaterials = (props : IStockFormProps) => props.materials;
    private getSelectedMaterial = (props : IStockFormProps, state : IStockFormState) => state.selectedMaterial;

    private getMaterialOptions = createSelector(
        [this.getMaterials, this.getSelectedSites],
        (materials : Array<IMaterial>) => {
            if (!materials) return [];

            return materials.filter(x => x.isActive).map((x) => {
                return { label: `(${x.code}) ` + x.name, value: x.id };
            });
        },
    );

    private onMaterialChange = (e : CustomChangeEvent, selectedMaterial : IOptionType) => {
        this.setState({ selectedMaterial });
    };

    private getMaterialStockOptions = createSelector(
        [this.getMaterialStocks, this.getSelectedMaterial, this.getSelectedSites],
        (materialStocks : Array<IMaterialStock>, selectedMaterial : IOptionType, selectedSiteIds : Array<number>) => {
            if (!materialStocks) return [];

            return materialStocks.filter(x => x.isActive
                && !x.isFinalized
                && (x.materialId === selectedMaterial?.value || !selectedMaterial)
                && (selectedSiteIds?.some(y => y === x.siteId))).map((x) => {
                return { label: `(${x.id}) ` + x.dateCode, value: x.id };
            });
        },
    );

    private getAllocatedMaterialStockOptions = createSelector(
        [this.getMaterialStocks, this.getSelectedSites],
        (materialStocks : Array<IMaterialStock>, selectedSiteIds : Array<number>) => {
            if (!materialStocks) return [];

            return materialStocks.filter(x => x.isActive
                && (selectedSiteIds?.some(y => y === x.siteId))).map((x) => {
                return { label: `(${x.id}) ` + x.dateCode, value: x.id };
            });
        },
    );

    private onMaterialStockChange = (e : CustomChangeEvent, selectedMaterialStock : IOptionType) => {
        if (!this.state.selectedMaterial && selectedMaterialStock !== null) {
            const materialStock = this.props.materialStocks.find(x => x.id === selectedMaterialStock.value);
            const materialsLinkedToMaterialStock = this.props.materials.filter(x => x.id === materialStock?.materialId);

            if (!!materialsLinkedToMaterialStock) {
                const newSelectedMaterial = {
                    label: `(${materialsLinkedToMaterialStock[0].code}) ` + materialsLinkedToMaterialStock[0].name,
                    value: materialsLinkedToMaterialStock[0].id,
                };
                this.setState({ selectedMaterialStock, selectedMaterial: newSelectedMaterial });
            } else {
                this.setState({ selectedMaterialStock });
            }
        } else {
            this.setState({ selectedMaterialStock });
        }
    };

    private addMaterialStock = () => {
        if (this.state.selectedMaterialStock) {
            const newMaterial : IAllocateMaterialStock = {
                materialStock: this.state.selectedMaterialStock,
                amount: this.state.materialStockAmount ?? 0,
            };
            this.setState({ materialsToBeAllocatedToStock: addArrayElement(this.state.materialsToBeAllocatedToStock, newMaterial, 'end') }, () => {
                this.setState({ selectedMaterialStock: undefined, materialStockAmount: 0, selectedMaterial: undefined });
            });
        }
    };

    private removeAllocatedMaterialStock = (materialStock : IOptionType) => {
        const index = this.state.updatedAllocatedMaterialsToStock.findIndex(x => Number(x.materialStock.value) === materialStock.value);
        this.setState({ updatedAllocatedMaterialsToStock: removeArrayElement(this.state.updatedAllocatedMaterialsToStock, index) });
    };

    private removeMaterialToBeAllocatedToStock = (materialStock : IOptionType) => {
        const index = this.state.materialsToBeAllocatedToStock.findIndex(x => Number(x.materialStock.value) === materialStock.value);
        this.setState({ materialsToBeAllocatedToStock: removeArrayElement(this.state.materialsToBeAllocatedToStock, index) });
    };

    private onAllocateMaterialStockToStockSubmit = () => {
        const materialsToBeAllocatedToStock = this.state.materialsToBeAllocatedToStock;
        const updatedAllocatedMaterialsToStock = this.state.updatedAllocatedMaterialsToStock;
        let allocatedMaterialsToStock : Array<IAllocateMaterialStock> = [];

        this.setLoading(true);
        updatedAllocatedMaterialsToStock.forEach((x) => {
            allocatedMaterialsToStock = addArrayElement(allocatedMaterialsToStock, x);
        });

        if (materialsToBeAllocatedToStock.length > 0) {
            materialsToBeAllocatedToStock.forEach((x) => {
                const index = this.state.allocatedMaterialsToStock.findIndex(y => y.materialStock.value === Number(x.materialStock.value));
                if (index === -1) {
                    allocatedMaterialsToStock = addArrayElement(this.state.allocatedMaterialsToStock, x);
                } else {
                    allocatedMaterialsToStock = setArrayElement(this.state.allocatedMaterialsToStock, index, x);
                }
            });
        }

        this.setState({ allocatedMaterialsToStock, updatedAllocatedMaterialsToStock: allocatedMaterialsToStock, showAllocateMaterialToStockDialog: false, materialsToBeAllocatedToStock: [] });
        this.setLoading(false);
    };

    private getAllocatedMaterialStockToStock = (props : IStockFormProps, state : IStockFormState) => state.allocatedMaterialsToStock;
    private getLoading = (props : IStockFormProps, state : IStockFormState) => state.isLoading;

    private getAddMaterialsButton = createSelector(
        [this.getAllocatedMaterialStockToStock, this.getLoading],
        (allocatedMaterialsToStock : Array<IAllocateMaterialStock>, isLoading : boolean) => {
            if (allocatedMaterialsToStock.length < 1) {
                return (
                    <Button
                        className={'fwb h35'}
                        variant='text'
                        color='primary'
                        onClick={this.openMaterialAllocationToStockDialog}>
                        Add Materials
                    </Button>
                );
            } else {
                return (
                    <PillButton
                        text={'Update Materials'}
                        className={'ml15 pl20 pr20 h35'}
                        onClick={this.openMaterialAllocationToStockDialog}
                        color={'secondary'}
                        size={'small'}
                        disabled={!!isLoading}
                    />
                );
            }
        },
    );

    private onExistingMaterialStockChange = (id : number, e : CustomChangeEvent, selectedMaterialStock : IOptionType) => {
        const index = this.state.updatedAllocatedMaterialsToStock.findIndex(x => Number(x.materialStock.value) === id);
        const materialStock = this.state.updatedAllocatedMaterialsToStock.find(x => Number(x.materialStock.value) === id);
        const newMaterial : IAllocateMaterialStock = {
            materialStock: selectedMaterialStock,
            amount: materialStock?.amount ?? 0,
        };
        this.setState({ updatedAllocatedMaterialsToStock: setArrayElement(this.state.updatedAllocatedMaterialsToStock, index, newMaterial) });
    };

    private onNewMaterialStockChange = (id : number, e : CustomChangeEvent, selectedMaterialStock : IOptionType) => {
        const index = this.state.materialsToBeAllocatedToStock.findIndex(x => Number(x.materialStock.value) === id);
        const materialStock = this.state.materialsToBeAllocatedToStock.find(x => Number(x.materialStock.value) === id);
        const newMaterial : IAllocateMaterialStock = {
            materialStock: selectedMaterialStock,
            amount: materialStock?.amount ?? 0,
        };
        this.setState({ materialsToBeAllocatedToStock: setArrayElement(this.state.materialsToBeAllocatedToStock, index, newMaterial) });
    };

    private onMaterialStockAmountChange = (e : React.ChangeEvent<HTMLInputElement>, id : number) => {
        const index = this.state.updatedAllocatedMaterialsToStock.findIndex(x => Number(x.materialStock.value) === id);
        const materialStock = this.state.updatedAllocatedMaterialsToStock.find(x => Number(x.materialStock.value) === id)?.materialStock;
        if (materialStock) {
            const newMaterial : IAllocateMaterialStock = {
                materialStock,
                amount: Number(e.target.value),
            };
            this.setState({ updatedAllocatedMaterialsToStock: setArrayElement(this.state.updatedAllocatedMaterialsToStock, index, newMaterial) });
        }
    };

    private onNewMaterialStockAmountChange = (e : React.ChangeEvent<HTMLInputElement>, id : number) => {
        const index = this.state.materialsToBeAllocatedToStock.findIndex(x => Number(x.materialStock.value) === id);
        const materialStock = this.state.materialsToBeAllocatedToStock.find(x => Number(x.materialStock.value) === id)?.materialStock;
        if (materialStock) {
            const newMaterial : IAllocateMaterialStock = {
                materialStock,
                amount: Number(e.target.value),
            };
            this.setState({ materialsToBeAllocatedToStock: setArrayElement(this.state.materialsToBeAllocatedToStock, index, newMaterial) });
        }
    };

    private onCommentChange = (event : CustomChangeEvent) => {
        const selectedStock = this.state.selectedStock;
        if (selectedStock) {
            selectedStock.userComment = event.currentTarget.value;
        }
        this.setState({ comment: event.currentTarget.value, selectedStock });
    };

    private onReportFileChange = (event : CustomChangeEvent) => {
        this.setState({ reportFile: event.currentTarget.value });
    };

    private isStockLineAccordianDisabled = () => {
        const values = this.formApi?.getState().values;
        return !(values
            && !!values.originalOrganizationId
            && !!values.currentOrganizationId
            && !!values.originalSiteId
            && !!values.currentSiteId
            && !!values.palletBaseTypeId
            && !!values.markId
            && !!values.marketId
            && (values.marketId && this.isLocalPallet(values.marketId) ? true : !!values.countryId));
    };

    private setSelectedCommodityId = (commodityId : number) => {
        this.setState({ selectedCommodityId: commodityId });
    };

    private getUpdatedAllocatedMaterialsToStock = (props : IStockFormProps, state : IStockFormState) => state.updatedAllocatedMaterialsToStock;

    private hasExistingMaterialsChanged = createSelector(
        [this.getUpdatedAllocatedMaterialsToStock],
        (updatedAllocatedMaterialsToStock : Array<IAllocateMaterialStock>) => {
            const existingMaterials = this.state.allocatedMaterialsToStock;

            let result : boolean = false;

            const changedMaterials = existingMaterials.find(x => updatedAllocatedMaterialsToStock.some(y => y?.materialStock.value === x?.materialStock.value && y?.amount !== x?.amount));

            if ((existingMaterials && existingMaterials.length !== updatedAllocatedMaterialsToStock.length) || !!changedMaterials) {
                result = true;
            }

            return result;
        },
    );

    private getMaterialName = (materialStockId : number) => {
        const materialStock = this.props.materialStocks.find(x => x.id === materialStockId);
        const material = this.props.materials.find(x => x.id === materialStock?.materialId);
        return material ? { label: `(${material.code}) - ${material.name}`, value: material.id } : undefined;
    };

    public render() {
        const selectedOrganization = this.state.currentStock?.originalOrganizationId
                                        || this.state.selectedStock?.originalOrganizationId
                                        || this.state.selectedOrganization
                                        || (this.props.selectedOrganizationIds?.length === 1 ? this.props.selectedOrganizationIds[0] : 0);
        const selectedSite = this.state.currentStock?.originalSiteId
                                        || this.state.currentStock?.currentSiteId
                                        || (this.props.selectedSiteIds?.length === 1 ? this.props.selectedSiteIds[0] : 0);

        const marketId = this.state.selectedStock && this.state.selectedStock.marketId ? this.state.selectedStock.marketId : 0;
        const regionId = this.getRegionId(marketId);
        const countryId = this.state.selectedStock ? (this.state.selectedStock.countryId ? this.state.selectedStock.countryId :
            (marketId !== 0 && this.isLocalPallet(marketId) && regionId) ? this.getLocalCountryId(regionId) : 0) : 0;

        const updatedAllocatedMaterialsToStock = this.state.updatedAllocatedMaterialsToStock;
        const materialsToBeAllocatedToStock = this.state.materialsToBeAllocatedToStock;
        const selectedMarket = this.props.markets.find(x => x.id === this.state.currentMarketId);

        return <div>
            <Dialog open={!!this.props.selectedStockView || this.props.isAdding} fullScreen>
                <div className={'oya hfill flx1 fdc mnw1000'}>
                    <Form getApi={this.setFormApi} onChange={this.onFormChange}>
                        <AppBar>
                            <Toolbar>
                                { !this.props.isAdding ?
                                    <Typography variant='h6' color='inherit' className={'flx1'}>
                                        Edit Stock: {this.state.selectedStock && this.state.selectedStock.barcode }
                                    </Typography>
                                    :
                                    <Typography variant='h6' color='inherit' className={'flx1'}>
                                        Add New Stock
                                    </Typography>
                                }
                                <IconButton color='inherit' aria-label='Close' onClick={this.toggleFormCancelDialog} disabled={this.state.isLoading}>
                                    <Icon className={'cw'}>close</Icon>
                                </IconButton>
                            </Toolbar>
                        </AppBar>
                        <Toolbar />
                        {this.state.isLoading &&
                            <div className={'posa post0 posr0 posb0 posl0 aic jcc zi1000'}>
                                <div className={'posr aic jcc h50 w50'}>
                                    <div className={'posa post0 posr0 posb0 posl0 aic jcc'}>
                                        <img height={40} src={`${ASSET_BASE}/assets/images/ZZ2_Pallets.png`} />
                                    </div>
                                    <CircularProgress color={'primary'} className={'posa post0 posr0 posb0 posl0'} size={50} />
                                </div>
                            </div>
                        }
                        {
                            this.state.isLoading &&
                            <div className={'posa post0 posr0 posb0 posl0 aic jcc zi1000'}>
                                <div className={'posr aic jcc h50 w50'}>
                                    <div className={'posa post0 posr0 posb0 posl0 aic jcc'}>
                                        <img height={40} src={`${ASSET_BASE}/assets/images/ZZ2_Pallets.png`} />
                                    </div>
                                    <CircularProgress color={'primary'} className={'posa post0 posr0 posb0 posl0'} size={50} />
                                </div>
                            </div>
                        }
                        <div className={'h5'} />
                        <div className={'fdc flx1'}>
                            <div className={'fdr pl10 pr10 mt10'}>
                                <Typography className={'fdr wfill fw500 pl15 pt10 pb10 pr10 aic'} variant='stockFormHeader'>
                                    STOCK
                                    <div className={'flx1'}/>
                                    TOTAL CARTONS: {this.getTotalCartons(this.props, this.state)}
                                    <div className={'w30'}/>
                                </Typography>
                                <div className={'w5'}/>
                            </div>
                            <div className={'fdr mb10 pt10'}>
                                <div className={'w50'}></div>
                                <Typography className={'flx1 pl10 pr10'} variant='subtitle1'>
                                    <CustomAutoSuggest
                                        initialValue={this.props.selectedStockView && this.props.selectedStockView.originalOrganizationId !== 0
                                            ? this.props.selectedStockView.originalOrganizationId
                                            : this.props.selectedOrganizationIds?.length === 1 ? this.props.selectedOrganizationIds[0] : ''}
                                        hasInitialValue={!!(this.props.selectedStockView && this.props.selectedStockView.originalOrganizationId) ||
                                            (this.props.selectedOrganizationIds?.length === 1)}
                                        className={'flx1'}
                                        label={'Original Organization'}
                                        field={'originalOrganizationId'}
                                        options={this.getOrganizationOptions(this.props, this.state)}
                                        onChange={this.onOrganizationChange}
                                        placeholder={'Select Original Organization...'}
                                        validate={validationFunctions.required}
                                        autoFocus={this.props.isAdding && (this.props.selectedSiteIds?.length !== 1)}
                                        disabled={(this.props.isEditing && !this.props.hasSuperAdminRight) || this.state.isLoading}
                                        validateOnMount
                                        validateOnChange
                                        validateOnBlur
                                        value={this.props.selectedStockView && this.props.selectedStockView.originalOrganizationId !== 0
                                            ? this.props.selectedStockView.originalOrganizationId
                                            : this.props.selectedOrganizationIds?.length === 1 ? this.props.selectedOrganizationIds[0] : ''}
                                    />
                                </Typography>
                                <span className={'w10'} />
                                <Typography className={'flx1 pl10 pr10'} color='inherit' variant='subtitle1'>
                                    <CustomAutoSuggest
                                        initialValue={this.props.selectedStockView && this.props.selectedStockView.currentOrganizationId !== 0
                                            ? this.props.selectedStockView.currentOrganizationId
                                            : this.props.selectedOrganizationIds?.length === 1 ? this.props.selectedOrganizationIds[0] : ''}
                                        hasInitialValue={!!(this.props.selectedStockView && this.props.selectedStockView.currentOrganizationId) ||
                                            (this.props.selectedOrganizationIds?.length === 1)}
                                        className={'flx1'}
                                        label={'Current Organization'}
                                        field={'currentOrganizationId'}
                                        options={this.getOrganizationOptions(this.props, this.state)}
                                        onChange={this.onCurrentOrganizationChange}
                                        placeholder={'Select Current Organization...'}
                                        validate={validationFunctions.required}
                                        validateOnMount
                                        validateOnChange
                                        validateOnBlur
                                        value={this.getCurrentOrgValue()}
                                        disabled={this.state.isLoading}
                                    />
                                </Typography>
                                <span className={'w10'} />
                                <Typography className={'flx1 pl10 pr10'} color='inherit' variant='subtitle1'>
                                    <CustomAutoSuggest
                                        initialValue={this.props.selectedStockView && this.props.selectedStockView.currentSiteId !== 0
                                            ? this.props.selectedStockView.currentSiteId
                                            : this.props.selectedSiteIds?.length === 1 ? this.props.selectedSiteIds[0] : ''}
                                        hasInitialValue={!!(this.props.selectedStockView && this.props.selectedStockView.currentSiteId) ||
                                            (this.props.selectedSiteIds?.length === 1)}
                                        className={'flx1'}
                                        field={'originalSiteId'}
                                        label={'Original Site'}
                                        placeholder={'Select Original Site...'}
                                        options={this.getOriginalSiteOptions(this.props)}
                                        disabled={true}
                                        validate={validationFunctions.required}
                                        validateOnMount
                                        validateOnChange
                                        validateOnBlur
                                        value={this.props.selectedStockView && this.props.selectedStockView.currentSiteId !== 0
                                            ? this.props.selectedStockView.currentSiteId
                                            : this.props.selectedSiteIds?.length === 1 ? this.props.selectedSiteIds[0] : ''}
                                    />
                                </Typography>
                                <span className={'w10'} />
                                <Typography className={'flx1 pl10 pr10'} color='inherit' variant='subtitle1'>
                                    <CustomAutoSuggest
                                        initialValue={this.props.selectedStockView && this.props.selectedStockView.currentSiteId !== 0
                                            ? this.props.selectedStockView.currentSiteId
                                            : this.props.selectedSiteIds?.length === 1 ? this.props.selectedSiteIds[0] : ''}
                                        hasInitialValue={!!(this.props.selectedStockView && this.props.selectedStockView.currentSiteId) ||
                                            (this.props.selectedSiteIds?.length === 1)}
                                        className={'flx1'}
                                        field={'currentSiteId'}
                                        label={'Current Site'}
                                        placeholder={'Select Current Site...'}
                                        options={this.getCurrentSiteOptions(this.props)}
                                        onChange={this.onSiteChange}
                                        disabled={true}
                                        validate={validationFunctions.required}
                                        validateOnMount
                                        validateOnChange
                                        validateOnBlur
                                        value={this.props.selectedStockView && this.props.selectedStockView.currentSiteId !== 0
                                            ? this.props.selectedStockView.currentSiteId
                                            : this.props.selectedSiteIds?.length === 1 ? this.props.selectedSiteIds[0] : ''}
                                    />
                                </Typography>
                                <span className={'w10'} />
                                <Typography className={'flx1 pl10 pr10'} color='inherit' variant='subtitle1'>
                                    {!!this.state.isTemporary &&
                                        <CustomAutoSuggest
                                            initialValue={this.state.selectedStock && this.state.selectedStock.marketId !== 0 ? this.state.selectedStock.marketId : ''}
                                            hasInitialValue={!!(this.state.selectedStock && this.state.selectedStock.marketId)}
                                            className={'flx1'}
                                            field={'marketId'}
                                            label={'Target Market'}
                                            placeholder={'Select Target Market...'}
                                            options={this.getMarketOptions(this.props, this.state)}
                                            onChange={this.onMarketChange}
                                            value={this.state.selectedStock && this.state.selectedStock.marketId !== 0 ? this.state.selectedStock.marketId : ''}
                                            disabled={this.state.isLoading}
                                        />
                                    }
                                    {!this.state.isTemporary &&
                                        <CustomAutoSuggest
                                            initialValue={this.state.selectedStock && this.state.selectedStock.marketId !== 0 ? this.state.selectedStock.marketId : ''}
                                            hasInitialValue={!!(this.state.selectedStock && this.state.selectedStock.marketId)}
                                            className={'flx1'}
                                            field={'marketId'}
                                            label={'Target Market'}
                                            placeholder={'Select Target Market...'}
                                            options={this.getMarketOptions(this.props, this.state)}
                                            onChange={this.onMarketChange}
                                            validate={validationFunctions.required}
                                            validateOnMount
                                            validateOnChange
                                            validateOnBlur
                                            value={this.state.selectedStock && this.state.selectedStock.marketId !== 0 ? this.state.selectedStock.marketId : ''}
                                            disabled={this.state.isLoading}
                                        />
                                    }
                                </Typography>
                                <span className={'w10'} />
                                <Typography className={'flx1 pl10 pr10'} color='inherit' variant='subtitle1'>
                                    {(selectedMarket && selectedMarket.channel === 'E') &&
                                        <CustomAutoSuggest
                                            initialValue={countryId && countryId !== 0 ? countryId : ''}
                                            hasInitialValue={!!countryId}
                                            className={'flx1'}
                                            updateValue={this.state.newCountryValue}
                                            clearUpdateValue={() => this.setState({ newCountryValue: undefined })}
                                            field={'countryId'}
                                            label={'Country'}
                                            placeholder={'Select Country...'}
                                            options={this.getCountryOptions(this.props, this.state)}
                                            value={countryId && countryId !== 0 ? countryId : ''}
                                            validate={validationFunctions.required}
                                            validateOnMount
                                            validateOnChange
                                            validateOnBlur
                                            disabled={this.state.isLoading}
                                        />
                                    }
                                    {((selectedMarket && selectedMarket.channel !== 'E') || this.state.currentMarketId === undefined || this.state.currentMarketId === 0) &&
                                        <CustomAutoSuggest
                                            initialValue={countryId && countryId !== 0 ? countryId : ''}
                                            hasInitialValue={!!countryId}
                                            className={'flx1'}
                                            updateValue={this.state.newCountryValue}
                                            clearUpdateValue={() => this.setState({ newCountryValue: undefined })}
                                            field={'countryId'}
                                            label={'Country'}
                                            placeholder={'Select Country...'}
                                            options={this.getCountryOptions(this.props, this.state)}
                                            value={countryId && countryId !== 0 ? countryId : ''}
                                            disabled={this.state.isLoading}
                                        />
                                    }
                                </Typography>
                                <div className={'w50'}></div>
                            </div>
                            <div className={'fdr mb10'}>
                                <div className={'w50'}></div>
                                <Typography className={'flx1 pl10 pr10'} color='inherit' variant='subtitle1'>
                                    <CustomAutoSuggest
                                        initialValue={this.state.selectedStock && this.state.selectedStock.palletBaseTypeId !== 0 ? this.state.selectedStock.palletBaseTypeId : ''}
                                        hasInitialValue={!!(this.state.selectedStock && this.state.selectedStock.palletBaseTypeId)}
                                        className={'flx1'}
                                        field={'palletBaseTypeId'}
                                        label={'Pallet Base Type'}
                                        placeholder={'Select Pallet Base Type...'}
                                        options={this.getPalletBaseTypeOptions(this.props)}
                                        onChange={this.onPalletBaseTypeChange}
                                        validate={validationFunctions.required}
                                        validateOnMount
                                        validateOnChange
                                        validateOnBlur
                                        value={this.state.selectedStock && this.state.selectedStock.palletBaseTypeId !== 0 ? this.state.selectedStock.palletBaseTypeId : ''}
                                        disabled={this.state.isLoading}
                                    />
                                </Typography>
                                <span className={'w10'} />
                                <Typography className={'flx1 pl10 pr10'} color='inherit' variant='subtitle1'>
                                    <CustomAutoSuggest
                                        initialValue={this.state.selectedStock && this.state.selectedStock.markId !== 0 ? this.state.selectedStock.markId : ''}
                                        hasInitialValue={!!(this.state.selectedStock && this.state.selectedStock.markId)}
                                        className={'flx1'}
                                        field={'markId'}
                                        label={'Brand'}
                                        placeholder={'Select Brand...'}
                                        options={this.getMarkOptions(this.props)}
                                        validate={validationFunctions.required}
                                        validateOnMount
                                        validateOnChange
                                        validateOnBlur
                                        value={this.state.selectedStock && this.state.selectedStock.markId !== 0 ? this.state.selectedStock.markId : ''}
                                        disabled={this.state.isLoading}
                                    />
                                </Typography>
                                <Typography className={'flx1 pl10 pr10'} color='inherit' variant='subtitle1'>
                                    <CustomAutoSuggest
                                        initialValue={this.state.selectedStock && this.state.selectedStock.inventoryId !== 0 ? this.state.selectedStock.inventoryId : this.props.selectedInvetory ? this.props.selectedInvetory.id : this.props.inventoryData.length > 0 ? this.props.inventoryData[0].id : ''}
                                        hasInitialValue={true}
                                        className={'flx1'}
                                        field={'inventoryId'}
                                        label={'Inventory'}
                                        placeholder={'Select Inventory...'}
                                        options={this.getInventoryOptions(this.props)}
                                        onChange={this.onInventoryChange}
                                        disabled={this.state.isLoading}
                                        value={this.state.selectedStock && this.state.selectedStock.inventoryId !== 0 ? this.state.selectedStock.inventoryId : this.props.selectedInvetory ? this.props.selectedInvetory.id : this.props.inventoryData.length > 0 ? this.props.inventoryData[0].id : ''}
                                    />
                                </Typography>
                                <span className={'w10'} />
                                <Typography className={'flx1 pl10 pr10'} color='inherit' variant='subtitle1'>
                                    <TextField
                                        name={'userComment'}
                                        label={'Comment'}
                                        className={'flx1'}
                                        onChange={this.onCommentChange}
                                        disabled={this.state.isLoading}
                                        value={this.props.isEditing && this.state.selectedStock && this.state.selectedStock.userComment ? this.state.selectedStock.userComment : this.state.comment}/>
                                </Typography>
                                <span className={'w10'} />
                                <Typography className={'flx1 pl10 pr10'} color='inherit' variant='subtitle1'>
                                    <CustomDatePicker
                                        className={'flx1'}
                                        type='date'
                                        field='packDate'
                                        label={'Pack Date'}
                                        disabled={this.state.isLoading}
                                        initialValue={this.props.isEditing && this.props.selectedStockView ? formatDateTimeToDatePicker(this.props.selectedStockView.packDate) : formatMomentToDatePicker(moment().utc().startOf('day'))}
                                    />
                                </Typography>
                                <span className={'w10'} />
                                <Typography className={'flx1 pl10 pr10'} color='inherit' variant='subtitle1'>
                                    <TextField
                                        className={'flx1'}
                                        value={this.getOrderNumber(this.state.selectedOrder) ? this.getOrderNumber(this.state.selectedOrder) : ''}
                                        onClick={this.openOrderViewer}
                                        onChange={() => null}
                                        label={'Order'}
                                        disabled={this.state.isLoading}
                                    />
                                    <CustomTextField
                                        className={'dn'}
                                        type='text'
                                        field='orderId'
                                        initialValue={this.state.selectedOrder ? this.state.selectedOrder : ''}
                                        disabled={this.state.isLoading}
                                    />
                                </Typography>
                                <div className={'w50'}></div>
                            </div>
                            <div className={'fdr mb10'}>
                                <div className={'w50'}></div>
                                { !this.state.isTemporary &&
                                    <div className={this.props.isAdding ? 'dn' : 'flx1 pt10 pl10 pr10 mnw100'}>
                                        <CustomToggleButton
                                            field={'isActive'}
                                            initialValue={this.props.selectedStockView && !this.props.selectedStockView.isActive ? false : true}
                                            label={'Active?'}
                                            value={this.state.selectedStock && !this.state.selectedStock.isActive ? false : true}
                                            disabled={this.state.isLoading}
                                        />
                                    </div>
                                }
                                <div className={'flx2 pt10 pl10 pr10 mnw100'}>
                                    <CustomToggleButton
                                        field={'isTemporary'}
                                        initialValue={!!this.state.selectedStock?.isTemporary}
                                        disabled={!this.hasStockSuperAdminRight(this.props)}
                                        value={this.state.isTemporary}
                                        label={'Temporary?'}
                                        onChange={() => this.setState({ isTemporary: this.formApi?.getState().values.isTemporary })}
                                    />
                                </div>
                                <span className={this.props.isEditing ? 'dn' : 'w10'} />
                                <div className={this.props.isEditing ? 'dn' : 'flx1 pl10 pr10'}></div>
                                <Typography className={'flx1 mxw280 mnw100'} color='inherit' variant='subtitle1'>
                                    <TextField
                                        name={'estimatedNettWeight'}
                                        label={'Estimated Gross Weight'}
                                        className={'flx1 mxw280 mnw100'}
                                        disabled
                                        value={this.props.isEditing && this.state.selectedStock && this.state.selectedStock.estimatedGrossWeight ? this.state.selectedStock.estimatedGrossWeight.toFixed(3) : this.totalEstGrossWeight().toFixed(3)}/>
                                </Typography>
                                <span className={'w10'} />
                                <Typography className={'flx1 mxw280 mnw100'} color='inherit' variant='subtitle1'>
                                    <CustomTextInput
                                        className={'flx1 mxw280 mnw100'}
                                        type='number'
                                        field='grossWeight'
                                        label={'Gross Weight'}
                                        validate={validationFunctions.required}
                                        validateOnMount
                                        validateOnChange
                                        validateOnBlur
                                        initialValue={this.props.isEditing && this.props.selectedStockView && this.props.selectedStockView.grossWeight ? this.props.selectedStockView.grossWeight.toFixed(3) : ''}
                                        disabled={this.state.isLoading}
                                    />
                                </Typography>
                                <div className={'w10'}></div>
                                <Typography className={'flx1 pl10 pr10'} color='inherit' variant='subtitle1'>
                                    <TextField
                                        style={{ minWidth: 150 }}
                                        name={'reportFile'}
                                        label={'Report File'}
                                        className={'flx1'}
                                        onChange={this.onReportFileChange}
                                        disabled={this.state.isLoading}
                                        value={this.props.isEditing && this.state.selectedStock && this.state.selectedStock.reportFile ? this.state.selectedStock.reportFile : this.props.isAdding ? this.state.reportFile : ''}
                                    />
                                </Typography>
                                <div style={{ minWidth: 50 }} className={'w50'}></div>
                            </div>
                        </div>
                    </Form>
                    <div className={'fdc pl20 pr20 hfill jcsb'}>
                        <div className={'fdc'}>
                            <Accordion expanded={this.state.stockLinesCaptureExpanded} onChange={(event, isExpanded) => this.setState({ stockLinesCaptureExpanded: isExpanded })} disabled={this.isStockLineAccordianDisabled()}>
                                <AccordionSummary className={'h50'} expandIcon={<Icon className={'cw'}>expand_more</Icon>}>
                                    <Typography className={'fdr wfill hfill fw500 aic cw'} variant='h6'>
                                        STOCKLINES
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <div className={'wfill'}>
                                        {this.state.showStockLineForm &&
                                            <StockLineForm
                                                isAdding={this.state.isEditingStockline ? false : true}
                                                isEditing={this.state.isEditingStockline}
                                                selectedOrganization={selectedOrganization}
                                                selectedSite={selectedSite}
                                                selectedStockLine={this.state.editingStockLine}
                                                currentStockLines={this.state.currentStockLines}
                                                addStockLine={this.addStockLine}
                                                currentMarketId={this.state.currentMarketId}
                                                isClearingStockLines={this.state.isClearingStockLines}
                                                stockLinesCleared={() => this.setState({ isClearingStockLines: false })}
                                                selectedStock={this.state.selectedStock ? this.state.selectedStock.id : 0}
                                                clearEditStockLine={this.clearSelectStockLine}
                                                selectedCommodityId={this.state.selectedCommodityId}
                                                setSelectedCommodityId={commodityId => this.setSelectedCommodityId(commodityId)}
                                                onClose={() => null}/>
                                        }
                                    </div>
                                </AccordionDetails>
                            </Accordion>
                            <div className={'fdr mnh200 pt20 pb20'}>
                                <CustomTable<IStockLine>
                                    id={'stockLine'}
                                    columns={this.getColumns()}
                                    rows={this.stockLineRows(this.props, this.state)}
                                    fitWidthToPage
                                    editFunction={this.onStockLineEdit}
                                    enableEditing={() => true}
                                    enableDeleting={(row : IStockLine) => !row.id}
                                    deleteFunction={(row : IStockLine) => this.removeStockLineAtGuid(row.guid)}
                                    enableSorting
                                    warning={(row : IStockLine) => this.checkOrchardComplianceCode(row)}
                                    initialSortOrder={[{ columnName: 'sequence_Sequence', direction : 'asc' }]}
                                    isActive={(row : IStockLine) => row.isActive}
                                />
                            </div>
                        </div>
                        <div className={'fdr flx1 pb10 pr20 pt5'}>
                            <div className={'flx1'}/>
                            {this.getAddMaterialsButton(this.props, this.state)}
                            <Button
                                className={'fwb h35'}
                                variant='text'
                                onClick={this.toggleFormCancelDialog}>
                                Cancel
                            </Button>
                            <PillButton
                                text={'Apply'}
                                className={'ml15 pl20 pr20 h35'}
                                onClick={this.preSubmitCheckForOrchards}
                                color={'secondary'}
                                size={'small'}
                                disabled={this.state.isLoading}
                            >
                            </PillButton>
                        </div>
                    </div>
                </div>
                {this.formApi && <OrderViewer
                    open={this.state.openOrderViewer}
                    currentStockValues={this.formApi.getState().values}
                    onClose={this.onOrderViewerClose}
                    onSubmit={this.onOrderViewerSubmit}
                />

                }
                <ConfirmationPrompt
                    open={this.state.showConfirmationPrompt}
                    message={'Would you like to add another pallet?'}
                    onOkClicked={this.resetForm}
                    onCancelClicked={this.close}
                />
                <ConfirmationPrompt
                    open={this.state.showClosingStockFormDialog}
                    message={'You are about to close this form. Any changes made will be lost.'}
                    onOkClicked={this.close}
                    onCancelClicked={this.toggleFormCancelDialog}
                />
                <PackmanDialog
                    title={'Warning!'}
                    isInfo={true}
                    isOpen={this.state.showOrchardComplianceCodeWarningDialog}
                    onClose={this.onOrchardComplianceCodeWarningClose}>
                    <div className={'p20 fdc aic jcc'}>
                        <div className={'mb10 fdc aic jcc'}>
                            <div className={'mb5'}>{'Not all orchards on the stocklines have compliance codes.'}</div>
                            <div className={'mb5'}>{'This is an export pallet, therefore all orchards must have compliance codes.'}</div>
                            <div className={'mb5'}>{'Do you still want to continue without them having compliance codes?'}</div>
                        </div>
                        <div className={'fdr aife jcfe wfill '}>
                            <Button
                                className={'fwb h35'}
                                variant='text'
                                onClick={this.onOrchardComplianceCodeWarningClose}>
                                Cancel
                            </Button>
                            <PillButton
                                color={'secondary'}
                                className={'ml15 pl20 pr20 h35'}
                                type={'submit'}
                                text={'Continue'}
                                onClick={this.submit}/>
                        </div>
                    </div>
                </PackmanDialog>
                <PackmanDialog
                    title={'Stock Created!'}
                    isInfo={true}
                    isOpen={this.state.showStockCreatedDialog}
                    onClose={this.close}>
                    <div className={'p20 fdc aic jcc'}>
                        <div className={'mb10 fdr aic jcc'}>
                            {`Stock Barcode: ${this.state.createdStockBarcode}`}
                        </div>
                        <div className={'fdr aife jcfe wfill '}>
                            <PillButton
                                color={'secondary'}
                                className={'ml15 pl20 pr20 h35 w200'}
                                text={'Create Another?'}
                                onClick={this.onCreateAnotherStock}/>
                            <PillButton
                                color={'secondary'}
                                className={'ml15 pl20 pr20 h35 w200'}
                                text={'Ok'}
                                onClick={this.close}/>
                        </div>
                    </div>
                </PackmanDialog>
                <PackmanDialog
                    title={'Allocate Material To Stock'}
                    maxWidth={'lg'}
                    isInfo={true}
                    isLoading={this.state.isLoading}
                    isOpen={this.state.showAllocateMaterialToStockDialog}
                    onClose={this.closeMaterialAllocationToStockDialog}>
                    <div className={'p20 fdc aic jcc'}>
                        {updatedAllocatedMaterialsToStock.length >= 1 ?
                            updatedAllocatedMaterialsToStock.map((x) => {
                                return (
                                    <div key={`updated_material_stock_#${x.stockId}`} className={'mb10 fdr aic jcc'}>
                                        <AutocompleteSelect
                                            className={'w300'}
                                            name={'materialId'}
                                            label={'Material'}
                                            options={this.getMaterialOptions(this.props)}
                                            onChange={this.onMaterialStockChange}
                                            value={this.getMaterialName(Number(x.materialStock.value))}
                                            disabled={true}
                                        />
                                        <AutocompleteSelect
                                            className={'w250'}
                                            name={'materialStockId'}
                                            label={'Material Stock'}
                                            options={this.getAllocatedMaterialStockOptions(this.props)}
                                            onChange={(e : CustomChangeEvent, v : any) => this.onExistingMaterialStockChange(Number(x.materialStock.value), e, v)}
                                            value={x.materialStock}
                                            disabled={true}
                                        />
                                        <TextField
                                            style={{ width: 150 }}
                                            value={x.amount}
                                            label={'Amount'}
                                            type={'number'}
                                            onChange={(event : React.ChangeEvent<HTMLInputElement>) => this.onMaterialStockAmountChange(event, Number(x.materialStock.value))}
                                        />
                                        <div className={'pl10'}>
                                            <IconButton className={'p0 m0'} onClick={() => {
                                                return this.removeAllocatedMaterialStock(x.materialStock);
                                            }}>
                                                <Icon fontSize={'large'}>delete</Icon>
                                            </IconButton>
                                        </div>
                                    </div>
                                );
                            })
                            :
                            <div/>
                        }
                        {materialsToBeAllocatedToStock.length >= 1 ?
                            materialsToBeAllocatedToStock.map((x) => {
                                return (
                                    <div key={`material_stock_#${x.stockId}`} className={'mb10 fdr aic jcc'}>
                                        <AutocompleteSelect
                                            className={'w300'}
                                            name={'materialId'}
                                            label={'Material'}
                                            options={this.getMaterialOptions(this.props)}
                                            onChange={this.onMaterialStockChange}
                                            value={this.getMaterialName(Number(x.materialStock.value))}
                                            disabled={true}
                                        />
                                        <AutocompleteSelect
                                            className={'w250'}
                                            name={'materialStockId'}
                                            label={'Material Stock'}
                                            options={this.getAllocatedMaterialStockOptions(this.props)}
                                            onChange={(e : CustomChangeEvent, v : any) => this.onNewMaterialStockChange(Number(x.materialStock.value), e, v)}
                                            value={x.materialStock}
                                            disabled={true}
                                        />
                                        <TextField
                                            style={{ width: 150 }}
                                            value={x.amount}
                                            label={'Amount'}
                                            type={'number'}
                                            onChange={(e : React.ChangeEvent<HTMLInputElement>) => this.onNewMaterialStockAmountChange(e, Number(x.materialStock.value))}
                                        />
                                        <div className={'pl10'}>
                                            <IconButton className={'p0 m0'} onClick={() => {
                                                return this.removeMaterialToBeAllocatedToStock(x.materialStock);
                                            }}>
                                                <Icon fontSize={'large'}>delete</Icon>
                                            </IconButton>
                                        </div>
                                    </div>
                                );
                            })
                            :
                            <div/>
                        }
                        <div className={'mb10 fdr aic jcc'}>
                            <AutocompleteSelect
                                className={'w300'}
                                name={'materialId'}
                                label={'Material'}
                                options={this.getMaterialOptions(this.props)}
                                onChange={this.onMaterialChange}
                                value={this.state.selectedMaterial}
                            />
                            <AutocompleteSelect
                                className={'w250'}
                                name={'materialStockId'}
                                label={'Material Stock'}
                                options={this.getMaterialStockOptions(this.props, this.state)}
                                onChange={this.onMaterialStockChange}
                                value={this.state.selectedMaterialStock}
                            />
                            <TextField
                                style={{ width: 150 }}
                                value={this.state.materialStockAmount}
                                label={'Amount'}
                                type={'number'}
                                onChange={(e : React.ChangeEvent<HTMLInputElement>) => this.setState({ materialStockAmount: Number(e.target.value) })}
                            />
                            <div className={'pl10'}>
                                <PillButton
                                    text={''}
                                    icon={<Icon className={'cpd p0 m0'} fontSize={'large'}>
                                            add_circle_outline
                                    </Icon>}
                                    color={'secondary'}
                                    size={'small'}
                                    className={'p0'}
                                    disabled={(!this.state.selectedMaterialStock && !!(this.state.materialStockAmount === undefined || this.state.materialStockAmount < 1))}
                                    onClick={() => this.addMaterialStock()}/>
                            </div>
                        </div>
                        <div className={'fdr aife jcfe wfill '}>
                            <Button
                                className={'fwb h35'}
                                variant='text'
                                onClick={this.closeMaterialAllocationToStockDialog}>
                                Cancel
                            </Button>
                            <PillButton
                                disabled={!this.hasExistingMaterialsChanged(this.props, this.state) && this.state.materialsToBeAllocatedToStock.length < 1}
                                color={'secondary'}
                                className={'ml15 pl20 pr20 h35'}
                                text={'Submit'}
                                onClick={this.onAllocateMaterialStockToStockSubmit}/>
                        </div>
                    </div>
                </PackmanDialog >
            </Dialog>
        </div>;
    }
}

const mapDispatchToProps = (dispatcher : Dispatch<RootAction>) => bindActionCreators(
    {
        dataSetMaterialStocks,
        dataSetBatches,
        dataSetMaterials,
    },
    dispatcher,
);

const mapStateToProps = (state : IRootState) => {
    return {
        stocks: state.data.stocks,
        organizations: state.masterData.organizations,
        sites: state.masterData.sites,
        farms: state.masterData.farms,
        markets: state.masterData.markets,
        marks: state.masterData.marks,
        regions: state.masterData.regions,
        countries: state.masterData.countries,
        commodities: state.masterData.commodities,
        varieties: state.masterData.varieties,
        packs: state.masterData.packs,
        sizes: state.masterData.sizes,
        grades: state.masterData.grades,
        colours: state.masterData.colours,
        orchards: state.masterData.orchards,
        batches: state.data.batches,
        palletBaseTypes: state.masterData.palletBaseTypes,
        orderHeaders: state.data.orderHeaders,
        auth : state.auth,
        selectedOrganizationIds: state.data.selectedOrganizationIds,
        selectedSiteIds: state.data.selectedSiteIds,
        materialStocks: state.data.materialStocks,
        inventoryData: state.masterData.inventories,
        selectedInvetory: state.data.selectedInventory,
        materials: state.masterData.materials,
        projects: state.masterData.projects,
    };
};

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps,
)(StockForm));
