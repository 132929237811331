import * as React from 'react';

import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import Icon from '@mui/material/Icon';
import Toolbar from '@mui/material/Toolbar';
import { authSignOutGoogle } from '../../store/auth/Functions';
import { connect } from 'react-redux';
import { IRootState, IAuthState } from '../../@types/redux';
import { ListItem, ListItemIcon, ListItemText, Typography, AppBar } from '@mui/material';
import SideMenuItem from '../../components/menu/SideMenuItem';
import { IRight } from '../../@types/model/user/right';
import { lowercase } from '../../services/appFunctionsService';
import { getHomeUrlStorage } from '../../services/localStorageService';
import SetHomeButton from '../../components/input/SetHomeButton';
import { withRouter, RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';
import { createSelector } from 'reselect';
import TicketCreationPopup from '../../components/TicketCreationPopup';
import { navPath } from '../../store/nav/Actions';
import materialTheme from '../../styles/materialTheme';
import { getIconLocation } from '../../services/iconHelperService';

const iconsLocation = getIconLocation();

interface INavDrawerProps extends RouteComponentProps {
    open : boolean;
    toggleDrawer : () => void;
    closeDrawer : () => void;
    side ?: 'left' | 'right' | 'top' | 'bottom';
    auth : IAuthState;
}

interface INavDrawerState {
    isHelpOpen : boolean;
}

class NavDrawer extends React.Component<INavDrawerProps, INavDrawerState> {
    constructor(props : INavDrawerProps) {
        super(props);

        this.state = {
            isHelpOpen: false,
        };
    }

    private signOutGoogle = () => {
        authSignOutGoogle();
        this.props.toggleDrawer();
    };

    private isSelected = (url : string, pathname ?: string) => pathname === url;

    private currentPageRight = (rights ?: Array<IRight>, pathname ?: string) => rights?.find(x => x.url === pathname);

    private isParentOfCurrentPage = (parentId : number, rights ?: Array<IRight>, pathname ?: string) : boolean => {
        const currentRight = this.currentPageRight(rights, pathname);
        const parentRight = rights?.find(x => x.id === parentId);

        if (currentRight && currentRight.parentId) {
            return (currentRight.parentId === parentId) || (!!parentRight?.parentId && this.isParentOfCurrentPage(parentRight.parentId, rights, pathname));
        }
        return false;
    };

    private isSectionSelected = (rightId : number, rights ?: Array<IRight>, pathname ?: string) => this.isParentOfCurrentPage(rightId, rights, pathname);

    private renderListSection = (right : IRight, children : Array<IRight>, rights ?: Array<IRight>, pathname ?: string) => <SideMenuItem
        paddingLeft={0}
        icon={right.name === 'Development'
            ? <Icon className={'w30 h30'} >construction</Icon>
            : (right.name === 'Sales'
                ? <Icon className={'w30 h30'} >receipt</Icon>
                : <img className={'h30 w30'} src={`${iconsLocation}/${lowercase(right.name)?.replace(/ /g, '_')}.svg`}/>)}
        title={right.name}
        key={'section_' + right.id}
        boldTitle={this.isSectionSelected(right.id, rights, pathname)}>
        {children.map(x => this.renderListItem(x, false, pathname))}
    </SideMenuItem>;

    private renderListItem = (right : IRight, hasIcon : boolean, pathname ?: string) => <Link key={'link' + right.url} to={right.url} onClick={this.props.closeDrawer} style={{ textDecoration: 'unset', color: 'unset' }}>
        <ListItem button key={right.guid}>
            {
                hasIcon ?
                    <ListItemIcon>
                        {right.name === 'Import Sales'
                            ? <Icon className={'cpd w30 h30'} >receipt</Icon>
                            : <img className={'h30 w30'} src={`${iconsLocation}/${lowercase(right.name)?.replace(/ /g, '_')}.svg`} />

                        }
                    </ListItemIcon>
                    :
                    <div className={'h30 w30'} />
            }
            <ListItemText disableTypography className={`${this.isSelected(right.url, pathname) ? 'fw550' : ''} pl0`} inset primary={right.name} />
            {
                right.url === '/' ? <SetHomeButton /> : <div/>
            }
        </ListItem>
    </Link>;

    private getRights = (props : INavDrawerProps) => props.auth.session?.user?.rights;
    private getPathName = (props : INavDrawerProps) => props.location?.pathname;

    private toggleIsHelpOpen = () => {
        this.setState({ isHelpOpen: !this.state.isHelpOpen });
    };

    private navToFAQPage = () => {
        navPath('/faq');
        this.props.toggleDrawer();
    };

    private getSections = createSelector(
        [this.getRights, this.getPathName],
        (rights ?: Array<IRight>, pathname ?: string) => {
            return rights?.filter(x => x.isOnNavDrawer && x.isActive && !x.parentId)
                .filter(x => !!x.sectionOrder).sort((x, y) => (x.sectionOrder || 0) - (y.sectionOrder || 0))
                .map((x) => {
                    return {
                        section: x,
                        children: rights.filter(y => y.isOnNavDrawer && y.isActive && y.parentId === x.id)
                            .filter(y => !!y.pageOrder).sort((z, y) => (z.pageOrder || 0) - (y.pageOrder || 0)),
                    };
                }).map((x) => {
                    return x.section.isPage ?
                        this.renderListItem(x.section, true, pathname)
                        :
                        (!!x.children ? this.renderListSection(x.section, x.children, rights, pathname) : null);
                }) ?? [];
        },
    );

    public render() {
        const auth = this.props.auth;
        const { isHelpOpen } = this.state;
        return (
            <div>
                <TicketCreationPopup isOpen={isHelpOpen} onClose={this.toggleIsHelpOpen}  />
                <Drawer variant='temporary' onBackdropClick={this.props.closeDrawer} anchor={this.props.side} open={this.props.open} className={'disableSelect w350'}>
                    <div style={{ backgroundColor: materialTheme.custom.navDrawer.background }} className={'w350 flx1 fdc oh'}>
                        <AppBar position='static' className={'appBar disableSelect noWrap'} elevation={0}>
                            {this.props.side === 'left' &&
                                <Toolbar variant='navbar' className={'pr0'}>
                                    <div className={'flx1 aic fdr'}>
                                        {
                                            auth.isLoggedIn && auth.session &&
                                                <IconButton className={'mr20 cw'} disabled={!!this.props.open} onClick={this.props.toggleDrawer} >
                                                    <Icon>menu</Icon>
                                                </IconButton>
                                        }
                                        <Link to={getHomeUrlStorage()} className={'fdr aic curp mt5 mb5'} onClick={this.props.toggleDrawer} style={{ textDecoration: 'unset', color: 'unset' }}>
                                            {<img height={42} src={`${ASSET_BASE}/assets/images/ZZ2_Pallets.png`} className={'mr10 curp disableImageDrag'}/>}
                                            <Typography color='inherit' variant='h6' className={'noWrap'}>
                                                PACKMAN QA
                                            </Typography>
                                        </Link>
                                    </div>
                                </Toolbar>
                            }
                        </AppBar>
                        { this.props.side === 'left' &&
                            <div className={'fdc oa hidescroll w300 ml25'} style={{ marginBottom: 145 }}>
                                <div className={'flx1 fdc'}>{this.getSections(this.props)}</div>
                                <div className={'posf posb0 fdc'} >
                                    <ListItem button key={'Help'} onClick={this.toggleIsHelpOpen} className={'w300'}>
                                        <ListItemIcon>
                                            <Icon fontSize={'large'} className={'h35 w35'} >help_outline</Icon>
                                        </ListItemIcon>
                                        <ListItemText disableTypography className={'pl0'} inset primary={'Help'} />
                                    </ListItem>
                                    <Link to={'/faq'} onClick={this.props.closeDrawer} style={{ textDecoration: 'unset', color: 'unset' }}>
                                        <ListItem button key={'FAQ'} className={'w300 pt10'}>
                                            <ListItemIcon>
                                                <Icon fontSize={'large'} className={'h35 w35'} >live_help</Icon>
                                            </ListItemIcon>
                                            <ListItemText disableTypography className={'pl0'} inset primary={'FAQ'} />
                                        </ListItem>
                                    </Link>
                                    <ListItem id={'logoutButton'} button key={'logout'} onClick={this.signOutGoogle} className={'w300'}>
                                        <ListItemIcon>
                                            <img className={'h30 w30'} src={`${iconsLocation}/logout.svg`} />
                                        </ListItemIcon>
                                        <ListItemText disableTypography className={'pl0 fw500'} inset primary={'LOGOUT'} />
                                    </ListItem>
                                </div>
                            </div>
                        }
                    </div>
                </Drawer>
            </div>
        );
    }
}

const mapStateToProps = (state : IRootState) => {
    return {
        auth: state.auth,
        organizations: state.masterData.organizations,
        sites: state.masterData.sites,
        selectedOrganizationIds: state.data.selectedOrganizationIds,
        selectedSiteIds: state.data.selectedSiteIds,
    };
};

export default withRouter(connect(
    mapStateToProps,
)(NavDrawer));
