import { ISite } from './site';
import * as Yup from 'yup';
import { IOptionType } from '../../../helper';
import { IInspectionPoint } from '../inspectionPoint/inspectionPoint';
import { IOrganization } from '../organization/organization';
import { IContactInfo } from '../contactInfo/contactInfo';
import { IFtpDetail } from '../ftpDetail/ftpDetail';

export interface ISiteFormValues {
    id : number;
    guid : string;
    isActive : boolean;

    organizations : Array<IOptionType>;
    contactInfos : Array<IOptionType>;
    ftpDetails : Array<IOptionType>;
    code : string;
    description : string;
    shortDescription : string;
    isPackhouse : boolean;
    isDeliveryPoint : boolean;
    isExternal ?: boolean;
    handlesExport : boolean;
    isBRCCompliant ?: boolean;
    isSimple ?: boolean;
    canMixSalesPoint : boolean;
    inspectionPoint ?: IOptionType;
    latitude ?: number | string;
    longitude ?: number | string;
    exporterName ?: string;
    exporterEmail ?: string;
    globalGapNumber ?: string;
    address ?: string;
    telNumber ?: string;
    faxNumber ?: string;
    dispatchHeaderLogo ?: string;
    packhouseDateCode ?: string;
}

export class SiteFormValues implements ISiteFormValues {
    public id : number;
    public guid : string;
    public isActive : boolean;

    public organizations : Array<IOptionType>;
    public contactInfos : Array<IOptionType>;
    public ftpDetails : Array<IOptionType>;

    public code : string;
    public description : string;
    public shortDescription : string;
    public isPackhouse : boolean;
    public isDeliveryPoint : boolean;
    public isExternal ?: boolean;
    public handlesExport : boolean;
    public isBRCCompliant ?: boolean;
    public isSimple ?: boolean;
    public canMixSalesPoint : boolean;
    public inspectionPoint ?: IOptionType;
    public latitude ?: number | string;
    public longitude ?: number | string;
    public exporterName ?: string;
    public exporterEmail ?: string;
    public globalGapNumber ?: string;
    public address ?: string;
    public telNumber ?: string;
    public faxNumber ?: string;
    public dispatchHeaderLogo ?: string;
    public packhouseDateCode ?: string;

    public constructor(
        site ?: ISite,
        inspectionPoints ?: Array<IInspectionPoint>,
        organizations ?: Array<IOrganization>,
        contactInfos ?: Array<IContactInfo>,
        ftpDetails ?: Array<IFtpDetail>,
    ) {
        this.initializeDefaultValues();
        if (site) {
            this.id = site.id;
            this.guid = site.guid;
            this.isActive = site.isActive;

            this.organizations = organizations?.filter(x => site.organizationIds.some(y => y === x.id)).map(x => ({
                label: x.name,
                value: x.id,
            })) ?? [];

            this.contactInfos = contactInfos?.filter(x => site.contactInfoIds.some(y => y === x.id)).map(x => ({
                label: x.name,
                value: x.id,
            })) ?? [];

            this.ftpDetails = ftpDetails?.filter(x => site.ftpDetailIds?.some(y => y === x.id)).map(x => ({
                label: x.name,
                value: x.id,
            })) ?? [];

            this.code = site.code;
            this.description = site.description;
            this.shortDescription = site.shortDescription;
            this.isPackhouse = site.isPackhouse;
            this.isDeliveryPoint = site.isDeliveryPoint;
            this.isExternal = site.isExternal;
            this.handlesExport = site.handlesExport;
            this.isBRCCompliant = site.isBRCCompliant;
            this.isSimple = site.isSimple;
            this.canMixSalesPoint = site.canMixSalesPoint;

            this.inspectionPoint = inspectionPoints
                ?
                ((site.inspectionPointId)
                    ?
                    {
                        label: inspectionPoints.find(x => x.id === site.inspectionPointId)?.name ?? '',
                        value: site.inspectionPointId ?? undefined,
                    }
                    : undefined)
                : undefined;

            this.latitude = site.latitude;
            this.longitude = site.longitude;
            this.exporterName = site.exporterName;
            this.exporterEmail = site.exporterEmail;
            this.globalGapNumber = site.globalGapNumber;
            this.address = site.address;
            this.telNumber = site.telNumber;
            this.faxNumber = site.faxNumber;
            this.dispatchHeaderLogo = site.dispatchHeaderLogo;
            this.packhouseDateCode = site.packhouseDateCode;
        }
    }

    public initializeDefaultValues = () => {
        this.id = 0;
        this.guid = '';
        this.code = '';
        this.isActive = true;

        this.organizations = [];
        this.contactInfos = [];
        this.ftpDetails = [];
        this.code = '';
        this.description = '';
        this.shortDescription = '';
        this.isPackhouse = false;
        this.isDeliveryPoint = false;
        this.isExternal = false;
        this.handlesExport = false;
        this.canMixSalesPoint = false;
        this.isBRCCompliant = undefined;
        this.isSimple = undefined;
        this.inspectionPoint = undefined;
        this.latitude = '';
        this.longitude = '';
        this.exporterName = '';
        this.exporterEmail = '';
        this.globalGapNumber = '';
        this.address = '';
        this.telNumber = '';
        this.faxNumber = '';
        this.dispatchHeaderLogo = '';
        this.packhouseDateCode = '';
    };

    // Validation schema used by formik. https://github.com/jquense/yup
    public static formSchema = Yup.object().shape({
        code: Yup.string().required('Required'),
        shortDescription: Yup.string().max(7, 'Can not be longer then 7 characters').required('Required'),
        description: Yup.string().required('Required'),
        latitude: Yup.number().required('Required'),
        longitude: Yup.number().required('Required'),
        organizations: Yup.array().of(Yup.object()).min(1, 'Required'),
        isActive: Yup.boolean().required('Required'),
    });
}
