export const CLOUD_FUNCTIONS_URL = 'https://us-central1-zz2-packman-224508.cloudfunctions.net/';
export const firebaseConfig = {
    apiKey: 'AIzaSyB2V2dSaqOXLrtuTfEx56Rw7yetj6Kfa6M',
    authDomain: 'zz2-packman-224508.firebaseapp.com',
    databaseURL: 'https://zz2-packman-224508.firebaseio.com',
    projectId: 'zz2-packman-224508',
    storageBucket: 'zz2-packman-224508.appspot.com',
    messagingSenderId: '962522652800',
};

export const freshServiceConfig = {
    apiKey: 'IiAbY68ljMT2JXz7rDO',
    Address: 'https://zz2.freshservice.com/api/v2/tickets',
};

export const PACKMAN_API_URL = 'https://packman-qa-api.zz2cloudflare.co.za/api/v1/';

/* Dev */
export const PPECB_API_URL = 'https://uatapigateway.ppecb.com/';
export const PPECB_USER_ID = '7070';
export const PPECB_USER_SECRET = 'uoeXwnbC3BKf]iIbfKeW].Q5T{';

export const TUR_API_URL = 'https://qa.tur.ecert.co.za/api/';
export const TUM_API_URL = 'https://qa.tum.ecert.co.za/api/';

/* Prod */
// export const PPECB_API_URL = 'https://apigateway.ppecb.com/';
// export const PPECB_USER_ID = '7070';
// export const PPECB_USER_SECRET = '56V17QmLyIWLQIumA^P+N,|2a9';

/* Themes */
export const APP_THEMES = [
    'Default',
    'Dark',
];

/* Default rights (Pages accessable to all users) */
export const DEFAULT_RIGHTS = ['/faq'];

export const DATE_FORMAT_DEFAULT = 'DD/MM/YYYY HH:mm:ss';
export const DATE_FORMAT_DEFAULT_NO_TIME = 'DD/MM/YYYY';
export const TIME_FORMAT_DEFAULT = 'HH:mm:ss';
export const DATEPICKER_FORMAT_DEFAULT = 'YYYY-MM-DD';
export const DATE_FORMAT_CSV = 'YYYYMMDD';
export const TIME_FORMAT_CSV = 'HH:mm';
export const DATE_FORMAT_TIMESTAMP_FROM_API = 'YYYY-MM-DDTHH:mm:ss';
export const DISPATCH_CSV_DATE_FORMAT = 'DD/MM/YYYY hh:mm:ss A';
export const DATEONLY_FINDING_SHEET_FILE = 'DD.MM.YYYY';

export const DATEONLY_PALTRACK_FILE = 'YYYYMMDD';
export const DATETIME_PALTRACK_FILE = 'YYYYMMDDHH:mm';
export const DATETIME_SECONDS_PALTRACK_FILE = 'YYYYMMDDHH:mm:ss';

export const ADMIN_PIN = '1234';

/* Dashboard filters */
export const CATEGORY_EXPANDED_HEIGHT_STOCK = 370;
export const CATEGORY_EXPANDED_HEIGHT_COMPLIANCE = 400;
export const CATEGORY_COLLAPSED_HEIGHT = 40;
export const WHITE_SPACE_BASE_LEFT_STOCK = 144;
export const WHITE_SPACE_BASE_LEFT_COMPLIANCE = 124;

export const getMapIcon = (name : string) => {
    const iconsLocation = `${ASSET_BASE}/assets/icons/mapMarkers`;
    return `${iconsLocation}/${name}.svg`;
};

export const DISPATCH_INSTRUCTION_STATUSSES = [
    'Draft',
    'Planned',
    'Confirmed',
    'Instructed',
    'Staged',
    'Dispatched',
    'Received',
];

export const DISPATCH_INSTRUCTION_CAN_DOWNLOAD_PO_FILE_STATUSSES = [
    'Dispatched',
    'Received',
];

export const COMPLIANCE_STATUSSES = [
    'Draft',
    'Planned',
    'Inspecting',
];

export const COMPLIANCE_CSV_HEADINGS = [
    'EDI_PARTNER_ID',
    'EDI_FILE_VERSION',
    'FILE_CREATION_DATE',
    'FILE_CREATION_TIME',
    'REVISION',
    'TRAN_TYPE',
    'CONS_NO',
    'BOOKING_REFERENCE',
    'BILLING_PARTY',
    'EXPORTER',
    'UPN',
    'PHC',
    'SSCC',
    'SEQ_NO_SSCC',
    'SSCC_REV',
    'CARTON_ID',
    'SSCC_REF',
    'PUC',
    'ORCHARD',
    'COMMODITY',
    'VARIETY',
    'CLASS',
    'SIZE_COUNT',
    'PACK',
    'PACK_DATE',
    'PRE_PACK_TREATMENT',
    'INSP_SAMPLE_WEIGHT',
    'NETT_WEIGHT_PACK',
    'GROSS_WEIGHT_PACK',
    'UNIT_TYPE',
    'CARTONS',
    'PLT_QTY',
    'TARG_REGION',
    'TARG_COUNTRY',
    'PHYTO_IND',
    'PROTOCOL_EXCEPTION_IND',
    'INSP_STATUS',
    'INSP_RESULT',
    'REJECT_REASON1',
    'REJECT_REASON2',
    'REJECT_REASON3',
    'INSP_POINT',
    'INSPECTOR',
    'INSP_DATE',
    'INSP_TIME',
    'PACK_CATEGORY',
];

export const DISPATCH_CSV_HEADINGS = [
    'Dispatch ID',
    'From Depot',
    'To Depot',
    'Date',
    'Barcode',
    'No Cartons',
    'Commodity Code',
    'Variety Code',
    'Pack Code',
    'Grade Code',
    'Count Code',
    'Inventory Code',
    'Weight',
    'Sequence Number',
    'Farm Number',
    'Pallet Base Type',
];

export const VALID_DISPATCH_STATUS_DESTINATIONS : { [key : string] : Array<string> } = {
    Draft: [
        'Planned',
        'Confirmed',
        'Instructed',
    ],
    Planned: [
        'Draft',
        'Confirmed',
        'Instructed',
    ],
    Confirmed: [
        'Instructed',
    ],
    Instructed: [
    ],
    Staged: [
        'Dispatched',
    ],
    Dispatched: [
    ],
    Received: [],
};

export const ADMIN_OVERRIDE_STATUSSES : { [key : string] : Array<string> } = {
    Draft: [
    ],
    Planned: [
    ],
    Confirmed: [
        'Draft',
        'Planned',
        'Dispatched',
    ],
    Instructed: [
        'Draft',
        'Planned',
        'Dispatched',
        'Confirmed',
    ],
    Staged: [
    ],
    Dispatched: [
        'Received',
    ],
    Received: [],
};

/* Lots */

export const CONSTANT_LOT_TYPE_GUIDS = [
    'CONST_LOT_TYPE_BATCH',
    'CONST_LOT_TYPE_WASTE',
    'CONST_LOT_TYPE_INTAKE',
    'CONST_LOT_TYPE_ADJUSTMENT',
    'CONST_LOT_TYPE_STORAGE',
];

export const POWER_BI_URL_SIGN_IN = 'https://app.powerbi.com/reportEmbed?reportId=9a700e87-84b2-4b05-9b15-15f79249d44b&autoAuth=true&ctid=9dc49bb3-0e15-45b2-9b1d-cf4401f577d5&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly93YWJpLWV1cm9wZS1ub3J0aC1iLXJlZGlyZWN0LmFuYWx5c2lzLndpbmRvd3MubmV0LyJ9';
export const POWER_BI_URL_EMBEDDED = 'https://app.powerbi.com/view?r=eyJrIjoiYWQ1NDQ2MWItMGNiYS00YmRkLTg0ZjQtNmJhNTA0NzBlYmE1IiwidCI6IjlkYzQ5YmIzLTBlMTUtNDViMi05YjFkLWNmNDQwMWY1NzdkNSIsImMiOjh9';

export const RIGHT_GRANTS : {[key : number] : string} = {
    0: 'VIEW',
    1: 'ADD',
    2: 'EDIT',
    4: 'DELETE',
    8: 'ADMIN',
    16: 'SUPER_ADMIN',
};
